import { dateRangeFilter } from "../modules/helpers";
import { DropdownApi, PrefixApi } from "../services/index";

export const defaultDropDownState = {
  states: [],
  sexes: [
    {
      id: 0,
      userId: null,
      displayName: "Male",
      settingType: null,
      officeName: null,
      isDeleted: null,
      isPrimary: null,
      defaultBlockNumber: 0,
      expiryOn: null,
      effectiveOn: null
    },
    {
      id: 1,
      userId: null,
      displayName: "Female",
      settingType: null,
      officeName: null,
      isDeleted: null,
      isPrimary: null,
      defaultBlockNumber: 0,
      expiryOn: null,
      effectiveOn: null
    },
    {
      id: 2,
      userId: null,
      displayName: "Other",
      settingType: null,
      officeName: null,
      isDeleted: null,
      isPrimary: null,
      defaultBlockNumber: 0,
      expiryOn: null,
      effectiveOn: null
    },
    {
      id: 3,
      userId: null,
      displayName: "Unknown",
      settingType: null,
      officeName: null,
      isDeleted: null,
      isPrimary: null,
      defaultBlockNumber: 0,
      expiryOn: null,
      effectiveOn: null
    }
  ],
  races: [],
  prefixes: [],
  prefixTags: [],
  pathologists: [],
  priorities: [],
  pathOptions: [],
  macroTypes: [],
  protocols: [],
  bodyParts: [],
  procedures: [],
  diagnosisSummaries: [],
  transactionCodeTypes: [],
  billingRates: [],
  rateTypes: [],
  printModeOptions: [],
  diagnosisOptions: [],
  titles: [],
  suffixes: [],
  casePhoneTypes: [],
  locationPhoneTypes: [],
  billingTypes: [],
  pathReportPreviewLocations: [],
  timeZones: [],
  reportGroups: [],
  interfaceDiagnoses: []
};

export const dropdowns = {
  namespaced: true,
  state: () => ({
    states: [],
    sexes: [
      {
        id: 0,
        userId: null,
        displayName: "Male",
        settingType: null,
        officeName: null,
        isDeleted: null,
        isPrimary: null,
        defaultBlockNumber: 0,
        expiryOn: null,
        effectiveOn: null
      },
      {
        id: 1,
        userId: null,
        displayName: "Female",
        settingType: null,
        officeName: null,
        isDeleted: null,
        isPrimary: null,
        defaultBlockNumber: 0,
        expiryOn: null,
        effectiveOn: null
      },
      {
        id: 2,
        userId: null,
        displayName: "Other",
        settingType: null,
        officeName: null,
        isDeleted: null,
        isPrimary: null,
        defaultBlockNumber: 0,
        expiryOn: null,
        effectiveOn: null
      },
      {
        id: 3,
        userId: null,
        displayName: "Unknown",
        settingType: null,
        officeName: null,
        isDeleted: null,
        isPrimary: null,
        defaultBlockNumber: 0,
        expiryOn: null,
        effectiveOn: null
      }
    ],
    races: [],
    prefixes: [],
    prefixTags: [],
    pathologists: [],
    priorities: [],
    pathOptions: [],
    macroTypes: [],
    protocols: [],
    bodyParts: [],
    procedures: [],
    diagnosisSummaries: [],
    transactionCodeTypes: [],
    billingRates: [],
    rateTypes: [],
    printModeOptions: [],
    diagnosisOptions: [],
    titles: [],
    suffixes: [],
    casePhoneTypes: [],
    locationPhoneTypes: [],
    billingTypes: [],
    pathReportPreviewLocations: [],
    timeZones: [],
    reportGroups: [],
    interfaceDiagnoses: []
  }),

  mutations: {
    setDiagnosisSummaries(state, payload) {
      state.diagnosisSummaries = payload;
    },
    setStates(state, payload) {
      state.states = payload;
    },
    setSexes(state, payload) {
      state.sexes = payload;
    },
    setBillingRates(state, payload) {
      state.billingRates = payload;
    },
    setRateTypes(state, payload) {
      state.rateTypes = payload;
    },
    setRaces(state, payload) {
      state.races = payload;
    },
    setPrefixes(state, payload) {
      state.prefixes = payload;
    },
    setPrefixTags(state, payload) {
      state.prefixTags = payload;
    },
    setPriorities(state, payload) {
      state.priorities = payload;
    },
    setPathologists(state, payload) {
      state.pathologists = payload;
    },
    setPathOptions(state, payload) {
      state.pathOptions = payload;
    },
    setMacroTypes(state, payload) {
      state.macroTypes = payload;
    },
    setProtocols(state, payload) {
      state.protocols = payload;
    },
    setBodyParts(state, payload) {
      state.bodyParts = payload;
    },
    setProcedures(state, payload) {
      state.procedures = payload;
    },
    setTransactionCodeTypes(state, payload) {
      state.transactionCodeTypes = payload;
    },
    setDiagnosisOptions(state, payload) {
      state.diagnosisOptions = payload;
    },
    setPrintModes(state, payload) {
      state.printModes = payload;
    },
    setTitles(state, payload) {
      state.titles = payload;
    },
    setSuffixes(state, payload) {
      state.suffixes = payload;
    },
    setCasePhoneTypes(state, payload) {
      state.casePhoneTypes = payload;
    },
    setLocationPhoneTypes(state, payload) {
      state.locationPhoneTypes = payload;
    },
    setBillingTypes(state, payload) {
      state.billingTypes = payload;
    },
    setPathReportPreviewLocations(state, payload) {
      state.pathReportPreviewLocations = payload;
    },
    setTimeZones(state, payload) {
      state.timeZones = payload;
    },
    setReportGroups(state, payload) {
      state.reportGroups = payload;
    },
    setInterfaceDiagnoses(state, payload) {
      state.interfaceDiagnoses = payload;
    }
  },
  actions: {
    getStates({ commit }) {
      if (!this.state.dropdowns.states.length) {
        DropdownApi.getStates().then(res => {
          commit("setStates", res || []);
        });
      }
    },
    getPrintModes({ commit }) {
      DropdownApi.getPrintModes().then(res => {
        commit("setPrintModes", res || []);
      });
    },
    getDiagnosisOptions({ commit }) {
      DropdownApi.getDiagnosisOptions().then(res => {
        commit("setDiagnosisOptions", res || []);
      });
    },
    getSexes({ commit }) {
      if (!this.state.dropdowns.sexes.length) {
        DropdownApi.getSex().then(res => {
          commit("setSexes", res || []);
        });
      }
    },
    getRateTypes({ commit }) {
      DropdownApi.getRateTypes().then(res => {
        commit("setRateTypes", res || []);
      });
    },
    getBillingRates({ commit }) {
      DropdownApi.getBillingRates().then(res => {
        commit("setBillingRates", res || []);
      });
    },
    getRaces({ commit }) {
      if (!this.state.dropdowns.races.length) {
        DropdownApi.getRace().then(res => {
          commit("setRaces", res || []);
        });
      }
    },
    getPrefixes({ commit }) {
      if (!this.state.dropdowns.prefixes.length) {
        PrefixApi.searchStore.load({ filter: dateRangeFilter(), sort: ["code"] }).then(res => {
          commit("setPrefixes", res || []);
        });
      }
    },
    getPrefixTags({ commit }) {
      if (!this.state.dropdowns.prefixTags.length) {
        DropdownApi.getPrefixTags().then(res => {
          commit("setPrefixTags", res || []);
        });
      }
    },
    getProcedures({ commit }) {
      DropdownApi.searchProcedures.load().then(res => {
        commit("setProcedures", res || []);
      });
    },
    getDiagnosisSummaries({ commit, rootState }) {
      DropdownApi.getDiagnosisSummaries(rootState.currentLab).then(res => {
        commit("setDiagnosisSummaries", res || []);
      });
    },
    getPriorities({ commit }) {
      if (!this.state.dropdowns.priorities.length) {
        DropdownApi.getPriorities().then(res => {
          commit("setPriorities", res || []);
        });
      }
    },
    getMacroTypes({ commit }) {
      DropdownApi.getMacroTypes.load().then(res => {
        commit("setMacroTypes", res || []);
      });
    },
    getTransactionCodeTypes({ commit }) {
      DropdownApi.getTransactionCodeTypes().then(res => {
        commit("setTransactionCodeTypes", res || []);
      });
    },
    getProtocols({ commit }) {
      DropdownApi.getProtocols().then(res => {
        commit("setProtocols", res || []);
      });
    },
    getBodyParts({ commit }) {
      if (!this.state.dropdowns.bodyParts.length) {
        DropdownApi.getBodyParts().then(res => {
          commit("setBodyParts", res || []);
        });
      }
    },
    getPathologists({ commit }) {
      DropdownApi.searchPathologists.load().then(res => {
        commit("setPathologists", res || []);
      });
    },
    getPathOptions({ commit }) {
      DropdownApi.getPathReportPreviewLocations.load().then(res => {
        commit("setPathOptions", res || []);
      });
    },
    getTitles({ commit }) {
      if (!this.state.dropdowns.titles.length) {
        DropdownApi.getTitles().then(res => {
          commit("setTitles", res || []);
        });
      }
    },
    getSuffixes({ commit }) {
      if (!this.state.dropdowns.suffixes.length) {
        DropdownApi.getSuffix().then(res => {
          commit("setSuffixes", res || []);
        });
      }
    },
    getCasePhoneTypes({ commit }) {
      if (!this.state.dropdowns.casePhoneTypes.length) {
        DropdownApi.getCasePhoneTypes().then(res => {
          commit("setCasePhoneTypes", res || []);
        });
      }
    },
    getLocationPhoneTypes({ commit }) {
      if (!this.state.dropdowns.locationPhoneTypes.length) {
        DropdownApi.getLocationPhoneTypes().then(res => {
          commit("setLocationPhoneTypes", res || []);
        });
      }
    },
    getBillingTypes({ commit }) {
      if (!this.state.dropdowns.billingTypes.length) {
        DropdownApi.getBillingTypes().then(res => {
          commit("setBillingTypes", res || []);
        });
      }
    },
    getPathReportPreviewLocations({ commit }) {
      if (!this.state.dropdowns.pathReportPreviewLocations.length) {
        DropdownApi.getPathReportPreviewLocations().then(res => {
          commit("setPathReportPreviewLocations", res || []);
        });
      }
    },
    getTimeZones({ commit }) {
      if (!this.state.dropdowns.timeZones.length) {
        DropdownApi.getTimeZones().then(res => {
          commit("setTimeZones", res || []);
        });
      }
    },
    getReportGroups({ commit }) {
      if (!this.state.dropdowns.reportGroups.length) {
        DropdownApi.getReportGroups().then(res => {
          commit("setReportGroups", res || []);
        });
      }
    },
    getInterfaceDiagnoses({ commit }) {
      if (!this.state.dropdowns.interfaceDiagnoses.length) {
        DropdownApi.getInterfaceDiagnoses().then(res => {
          res = res.data.map((e, idx) => {
            return { id: idx + 1, displayName: e };
          });
          commit("setInterfaceDiagnoses", res);
        });
      }
    }
  }
};
