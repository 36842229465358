<template>
  <div class="sidebar-section">
    <button
      @click="handleAccessionFilter(CasesModeEnum.Open)"
      v-shortkey="shortkeyAlt('1')"
      @shortkey="handleAccessionFilter(CasesModeEnum.Open)"
      v-if="permissions.CaseView"
      class="btn w-100"
      :class="{ active: accessionFilter === CasesModeEnum.Open && mode === DashboardModes.Cases }"
    >
      <icon icon="circle-notch" />
      <div>Open Cases</div>
    </button>
    <button
      v-if="permissions.CaseView"
      class="btn w-100"
      @click="handleAccessionFilter(CasesModeEnum.All)"
      v-shortkey="shortkeyAlt('2')"
      @shortkey="handleAccessionFilter(CasesModeEnum.All)"
      :class="{ active: accessionFilter === CasesModeEnum.All && mode === DashboardModes.Cases }"
    >
      <icon icon="circle" />
      <div>All Cases</div>
    </button>

    <button
      v-if="permissions.OrdersView"
      class="btn w-100"
      @click="handleModeChange(DashboardModes.Orders)"
      v-shortkey="shortkeyAlt('3')"
      @shortkey="handleModeChange(DashboardModes.Orders)"
      :class="{ active: mode === DashboardModes.Orders }"
      v-tooltip="mode === DashboardModes.Orders ? 'Show all grids.' : 'Show the orders grid.'"
    >
      <span v-show="proceduresCount" :data-testid="`procedures-count`" :class="`count_icon m-0`">{{
        proceduresCount
      }}</span>
      <icon icon="file-alt" />
      <div>Orders</div>
    </button>
    <button
      v-if="permissions.TaskView"
      class="btn w-100"
      v-shortkey="shortkeyAlt('4')"
      @click="handleModeChange(DashboardModes.Tasks)"
      @shortkey="handleModeChange(DashboardModes.Tasks)"
      :class="{ active: mode === DashboardModes.Tasks }"
      v-tooltip="mode === DashboardModes.Tasks ? 'Show all grids.' : 'Show the tasks grid.'"
    >
      <span
        v-show="taskCount"
        :data-testid="`task-count`"
        :class="`count_icon ${hasHighPriorityTask ? 'high-priority-task' : ''} m-0`"
        >{{ taskCount }}</span
      >
      <icon icon="server" />
      <div>Tasks</div>
    </button>

    <button
      v-tooltip="'Click to add new accession.'"
      v-if="permissions.CaseCreateEdit"
      class="btn w-100"
      role="link"
      data-cy="addAccession"
      @click="handleAccessionNav"
      v-shortkey="shortkeyAlt('5')"
      @shortkey="handleAccessionNav"
    >
      <icon icon="user-plus" />
      <div>Accessioning</div>
    </button>

    <button
      v-tooltip="'Click enter free text search mode.'"
      v-if="permissions.CaseView"
      class="btn w-100"
      role="link"
      data-cy="searchMode"
      v-shortkey="shortkeyAlt('6')"
      @click="handleModeChange(DashboardModes.Search)"
      @shortkey="handleModeChange(DashboardModes.Search)"
      :class="{ active: mode === DashboardModes.Search }"
    >
      <icon icon="search" />
      <div>Search</div>
    </button>

    <button
      v-shortkey="shortkeyAlt('7')"
      @shortkey="togglePrintingModal"
      v-tooltip="'Click to print cassettes & slides.'"
      class="btn w-100"
      @click="togglePrintingModal"
      v-if="permissions.CassetteSlidePrint"
    >
      <icon icon="print" />
      <div>Cassette & Slide Printing</div>
    </button>
    <button
      v-shortkey="shortkeyAlt('8')"
      @shortkey="toggleReqLabelModal"
      v-tooltip="'Click to print cassettes & slides.'"
      class="btn w-100"
      @click="toggleReqLabelModal"
      v-if="permissions.CaseCreateEdit"
    >
      <icon icon="print" />
      <div>Labels</div>
    </button>
    <button
      v-shortkey="shortkeyAlt('9')"
      @shortkey="toggleBatchPathologists"
      v-tooltip="'Click to batch assign pathologists.'"
      class="btn w-100"
      @click="toggleBatchPathologists"
      v-if="permissions.CaseCreateEdit"
    >
      <icon icon="user-md" />
      <div>Assign Pathologist</div>
    </button>
    <button
      v-shortkey="shortkeyAlt('0')"
      @shortkey="handleUploadReqNav"
      v-tooltip="'Click to batch upload req forms.'"
      class="btn w-100"
      @click="handleUploadReqNav"
      v-if="permissions.CaseHoldCreateEdit"
    >
      <icon icon="barcode" />
      <div>Upload Reqs</div>
    </button>

    <modal :status="reqLabelIsOpen" @close="reqLabelIsOpen = false">
      <PrintLabelsPopup />
    </modal>
    <Modal :status="isSlidePrintingOpen" @close="isSlidePrintingOpen = false">
      <slides-popup />
    </Modal>
    <Modal :status="isBatchPahologistsOpen" @close="toggleBatchPathologists">
      <BatchPathologists @close="toggleBatchPathologists" />
    </Modal>
  </div>
</template>

<script>
import { ProceduresApi, TaskApi } from "@/services";
import { mapState, mapGetters } from "vuex";
import Icon from "../common/Icon.vue";
import Modal from "../common/Modal.vue";
import PrintLabelsPopup from "../PrintLabelsPopup.vue";
import SlidesPopup from "../SlidesPopup.vue";
import { CasesModeEnum, DashboardModes } from "@/modules/enums";
import { altKey } from "@/modules/helpers";
import BatchPathologists from "../BatchPathologists.vue";
import eventBus, { UPDATE_TASK_COUNT } from "@/modules/eventBus";

export default {
  components: { Modal, PrintLabelsPopup, SlidesPopup, Icon, BatchPathologists },
  name: "Home-Sidebar",
  data() {
    return {
      reqLabelIsOpen: false,
      isSlidePrintingOpen: false,
      taskCount: 0,
      proceduresCount: 0,
      DashboardModes,
      CasesModeEnum,
      isBatchPahologistsOpen: false,
      hasHighPriorityTask: false
    };
  },
  created() {
    eventBus.$on(UPDATE_TASK_COUNT, () => this.getTaskCount());
  },
  beforeDestroy() {
    eventBus.$off(UPDATE_TASK_COUNT);
  },
  activated() {
    this.getPendingProcedureCount();
    this.getTaskCount();
  },
  watch: {
    currentLab: {
      immediate: true,
      handler() {
        this.getPendingProcedureCount();
        this.getTaskCount();
      }
    }
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      currentUser: state => state.currentUser,
      homeGridMode: state => state.applicationSettings.homeGridMode,
      applicationSettings: state => state.applicationSettings,
      showOrdersCount: state => state.applicationSettings.showOrdersCount
    }),
    ...mapGetters(["permissions"]),

    mode: {
      get() {
        return this.homeGridMode;
      },
      set(value) {
        this.$store.commit("applicationSettings/setGridMode", value);
        return value;
      }
    },
    accessionFilter: {
      get() {
        return this.applicationSettings.accessionFilter;
      },
      set(value) {
        this.$store.commit("applicationSettings/setAccessionFilter", value);

        return value;
      }
    }
  },
  methods: {
    togglePrintingModal() {
      this.isSlidePrintingOpen = !this.isSlidePrintingOpen;
    },
    toggleReqLabelModal() {
      this.reqLabelIsOpen = !this.reqLabelIsOpen;
    },
    handleModeChange(mode) {
      if (mode === this.mode) {
        this.mode = 0;
        return;
      }
      this.mode = mode;
    },
    handleAccessionFilter(mode) {
      this.mode = 0;
      this.$store.commit("applicationSettings/setAccessionFilter", mode);
    },
    handleAccessionNav() {
      if (this.$route.name === "Home") {
        this.$router.push({ name: "Accession" });
      }
    },
    shortkeyAlt(key) {
      return altKey(key);
    },
    toggleBatchPathologists() {
      this.isBatchPahologistsOpen = !this.isBatchPahologistsOpen;
    },
    handleUploadReqNav() {
      if (this.$route.name === "Home") {
        this.$router.push({ name: "Upload Reqs" });
      }
    },
    getTaskCount() {
      TaskApi.getTaskCounts().then(res => {
        if (typeof res === "number") {
          // to prevent things from breaking while transitioning payload
          this.taskCount = res;
        } else {
          this.taskCount = res?.count || 0;
          this.hasHighPriorityTask = res?.hasHighPriority || false;
        }
      });
    },
    getPendingProcedureCount() {
      if (this.showOrdersCount) {
        ProceduresApi.getLabPendingProceduresCount(this.currentLab).then(res => {
          this.proceduresCount = res || 0;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1023px) {
  .sidebar-section {
    display: flex;
    overflow-y: hidden;
    margin-bottom: -1px;
    & > button {
      border-radius: initial;
      background: white;
      color: black;
      margin-bottom: 0px;
      padding: 0.75rem;
      &:not(:last-child) {
        margin-right: 2px;
      }
      opacity: 0.5;
      svg {
        display: none;
      }
      &.active {
        opacity: 1;
      }
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(6) {
        margin-top: initial;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .sidebar-section {
    width: 100px;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 3px;
    & > button {
      position: relative;
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(6) {
        margin-top: 8px;
      }
      & > div {
        font-weight: 500;
        font-size: 0.85rem;
      }
      &.active {
        opacity: 0.5;
      }
      transition: opacity 150ms;
      background: $primary-light;
      border-radius: 3px;
      padding: 5px 0px;
      margin-bottom: 2.5px;
      text-align: center;
      font-size: 12px;
      & > svg {
        color: $primary;
        font-size: 24px;
      }
    }
  }
}

.count_icon {
  position: absolute;
  top: -8px;
  right: -1px;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 50%;
  background: $primary-light;
  font-weight: 600;
  font-size: 0.75rem;
  border: 1px solid $secondary;
  &.history {
    animation: pulse-red 1s infinite;
  }
}
.high-priority-task {
  color: $white;
  background: $danger;
  animation: pulse-red 1s infinite;
}
</style>
