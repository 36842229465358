import CustomStore from "devextreme/data/custom_store";
import api from "./api.js";

class Cases {
  constructor() {
    this.url = "/api/Cases";
    this.freeTextStore = [];
  }
  get searchStore() {
    return api.createSearch(this.url + "/search", "caseId");
  }
  get patientMatch() {
    return api.createSearch(this.url + "/patient/match");
  }
  createFreeTextStore(payload) {
    return new CustomStore({
      key: "caseId",
      load: async loadOptions =>
        await api.post("/api/Cases/FreeTextSearch", { ...payload, loadOptions: loadOptions ?? {} })
    });
  }
  getCytologyResponses(caseId) {
    return api.get(`/api/Cases/${caseId}/CytQuestionResponses`);
  }
  updateCytologyResponses(caseId, payload) {
    return api.put(`/api/Cases/${caseId}/CytQuestionResponses`, payload);
  }
  insertCytologyResponses(caseId, payload) {
    return api.post(`/api/Cases/${caseId}/CytQuestionResponses`, payload);
  }
  createClientFreeTextStore(payload) {
    return new CustomStore({
      key: "caseId",
      load: async loadOptions =>
        await api.post("/api/Cases/FreeTextSearch_V1", {
          ...payload,
          loadOptions: loadOptions ?? {}
        })
    });
  }
  createStore(labId) {
    return api.createSearch(this.url + "/search", "caseId", { labId });
  }
  getPatientHistoryStore(caseId) {
    return api.createSearch(this.url + `/${caseId}/PatientHistory/search`, "caseId");
  }
  getCaseStatus(caseId) {
    return api.get(this.url + `/${caseId}/status`);
  }
  getHistoryCount(caseId) {
    return api.get(this.url + `/${caseId}/HistoryCount`);
  }
  getCaseImages(caseId) {
    return api.get(this.url + `/${caseId}/Images`);
  }
  getCaseEdits(caseId) {
    return api.get(this.url + `/${caseId}/edits`);
  }
  getCaseHistory(caseId) {
    return api.get(this.url + `/${caseId}/History`);
  }
  getMultiCasePdf(caseIds, fmt = "pdf") {
    return api.getFileWithPost(this.url + `/MultiPathReports`, { caseIds, fmt }, "blob");
  }
  getCaseCounts(caseId) {
    return api.get(this.url + `/${caseId}/CaseCounts`);
  }
  getNewestPathReport(caseId) {
    return api.getFile(this.url + `/${caseId}/NewestPathReport`, {}, "blob");
  }
  getPathReportHistory(caseId) {
    return api.getFile(this.url + `/${caseId}/PathReportHistory`, {}, "blob");
  }
  getCases() {
    return api.get(this.url);
  }
  deleteCase(caseId) {
    return api.delete(this.url, caseId);
  }
  getCasesByLab() {
    return api.get(this.url + `/lab`);
  }
  getCaseById(id) {
    return api.get(this.url + `/${id}`);
  }
  getCaseHeader(id) {
    return api.get(this.url + `/Header/${id}`);
  }
  getPatientById(id) {
    return api.get(this.url + `/PatientDetail/${id}`);
  }
  searchCaseTx(data, caseId) {
    return api.post(this.url + `/${caseId}/Transactions/search`, data).then(response => {
      return response;
    });
  }
  searchPatientHistory(data, caseId) {
    return api.get(this.url + `/${caseId}/PatientHistory/search`, data);
  }
  updateCase(caseDetails) {
    return api.put(this.url, caseDetails);
  }
  updateUserMeta(caseDetails) {
    return api.post(this.url + "/usermeta", caseDetails);
  }
  insertCase(caseDetails, labLocationId) {
    return api.post(`${this.url}/?LabLocId=${labLocationId}`, caseDetails);
  }
  /**
   * @deprecated
   * @param {number} caseId
   * @param {object} payload
   * @returns
   */
  distributeReport(caseId, payload) {
    return api.post(this.url + `/${caseId}/PathReportMessage`, payload);
  }
  /**
   * @param {Object} payload
   * @param {Array} payload.caseIds
   * @param {number} payload.targetId
   * @param {number} payload.method
   * @param {Array} payload.recipients
   * @param {string} payload.subject
   * @param {string} payload.body
   * @returns
   */
  distributeReports(payload) {
    return api.post(`/api/PathReportMessages`, payload);
  }
  /**
   *
   * @param {number} caseId
   */
  signCase(caseId, labLocationId) {
    let locationQuery = "";
    if (labLocationId) {
      locationQuery = "/?LabLocId=" + labLocationId;
    }
    return api.post(this.url + `/sign/${caseId + locationQuery}`);
  }
  multiSignCase(details, labLocationId) {
    return api.put(this.url + "/MultiSign/?LabLocId=" + labLocationId, details);
  }
  validate(details) {
    return api.post(this.url + "/validate", details);
  }
  getAlerts(labId) {
    return api.get(this.url + "/Alerts", { labId });
  }
  getSlideImages(caseId) {
    return api.get(this.url + "/" + caseId + "/SlideImageUrls");
  }

  /**
   *
   * @param {Number} payload.caseId
   * @param {Number} payload.prefixId
   * @param {Number} payload.numberYear
   * @param {Number} payload.numberSequence
   * @param {String} payload.caseNumber
   * @returns {Boolean} // Is number used
   */
  validateCaseNumber(payload) {
    return api.post(this.url + "/ValidateCaseNumber", payload);
  }
  /**
   *
   * @param {Number} payload.caseId
   * @param {Number} payload.prefixId
   * @param {Number} payload.numberYear
   * @param {Number} payload.numberSequence
   * @param {String} payload.caseNumber
   * @returns
   */
  changeCaseNumber(payload) {
    return api.put(this.url + "/CaseNumber", payload);
  }

  /**
   *
   * @param {Object} params //Params to assert the patient matching query.
   */
  matchPatient(payload) {
    return api.post(`${this.url}/patient/match`, payload);
  }
  getNextCaseNumber(caseId) {
    return api.get(`${this.url}/${caseId}/NextNumber`);
  }
  getFileDropPrintInfo(caseIds) {
    return api.post(`${this.url}/GetFileDropPrintInfo`, caseIds);
  }
  exportSearch(payload) {
    return api.post(this.url + "/Exports", payload);
  }
  getPatientCaseHistory(patientId) {
    return api.get(`${this.url}/${patientId}/PatientCaseHistory`);
  }
  getPatientPathRepHistory(patientId) {
    return api.getFile(this.url + `/${patientId}/PatientPathRepHistory`, {}, "blob");
  }
  autoPrintProcedures(caseId) {
    return api.post(`${this.url}/${caseId}/ProcedureAutoPrint`);
  }
  getWorkRate(tzo) {
    return api.get(`${this.url}/CurrentUserWorkRate/?tzo=${tzo}`);
  }
  deleteEditReason(payload) {
    return api.delete("/api/CaseEditReasons", payload);
  }
  editEditReason(payload) {
    return api.put("/api/CaseEditReasons", payload);
  }
  presignCase(caseId) {
    return api.post(`${this.url}/presign/${caseId}`);
  }
  getFreeTextSearchCaseCount(payload) {
    return api.post(this.url + "/FreeTextSearchSummary", payload);
  }
  getPreviousCases(labId) {
    return api.get(this.url + "/GetPreviousCases?labId=" + labId);
  }
  batchAssignPathologists(payload) {
    return api.put(this.url + "/PathologistAssignment", payload);
  }
  getCaseIdsByCaseNumber(payload) {
    return api.post(this.url + "/GetcaseInformationByCaseNumber", payload);
  }
  checkHistory(caseId) {
    return api.get(this.url + `/${caseId}/CheckHistory`);
  }
}
export default new Cases();
