const permissionsEnum = {
  ActivityLogCreateEdit: 250,
  ActivityLogExport: 255,
  AuditLogView: 500,
  AuditLogSuperUserView: 550,
  CaseAccession: 900,
  CaseCreateEdit: 1000,
  CaseDelete: 1005,
  CaseEditReasonDelete: 1007,
  CaseEditReasonEdit: 1008,
  CaseFieldEditCaseNotes: 1010,
  CaseFieldEditClinical: 1020,
  CaseFieldEditDiagnosis: 1030,
  CaseFieldEditFrozen: 1035,
  CaseFieldEditGross: 1040,
  CaseFieldEditMicroscopic: 1050,
  CaseFieldEditSpecimenNotes: 1060,
  CaseFieldEditSynoptic: 1065,
  CaseFinalizeSlidePrep: 1071,
  CaseHistoryView: 1080,
  CaseInsuranceCreateEdit: 1090,
  CaseInsuranceDelete: 1095,
  CaseInsuranceView: 1100,

  CaseResultsCreateEdit: 1110,
  CaseResultsView: 1120,

  /* See "CaseSearch..." permissions in Section 2, below */

  CaseSignout: 1130,
  CaseSignoutByProxy: 1135,
  CaseSpecimenCreateEdit: 1140,
  CaseSpecimenDelete: 1145,
  CaseSpecimenView: 1150,
  CaseTransactionCreateEdit: 1160,
  CaseTransactionDelete: 1165,
  CaseTransactionView: 1170,

  CaseView: 1180,

  // NETPRO-294
  CaseImageCreateEdit: 1185,
  CaseImageView: 1190,
  CaseHoldCreateEdit: 1195,
  CaseHoldView: 1200,
  CaseTagNoteCreateEdit: 1205,
  CaseTagNoteEditAny: 1206,
  CaseTagNoteView: 1210,

  CassetteSlidePrint: 1400,
  CassetteProcedureCreateEdit: 1500,
  CassetteProcedureView: 1510,
  ContactCreateEdit: 2000,
  ContactFieldEditEffectiveOn: 2010,
  ContactFieldEditExpiryOn: 2020,
  ContactFieldEditPrintEndOn: 2030,
  ContactFieldEditPrintStartOn: 2040,
  ContactFieldEditReportFormat: 2050,
  ContactView: 2060,
  CytDiagnosticCodeCreateEdit: 2100,
  CytDiagnosticCodeView: 2105,
  CytDiagnosticMacroCreateEdit: 2110,
  CytDiagnosticMacroView: 2120,
  CytQuestionCreateEdit: 2125,
  CytQuestionView: 2130,
  CytMolecTestCreateEdit: 2135,
  CytMolecTestView: 2140,
  CytTriggerCreateEdit: 2145,
  CytTriggerDelete: 2150,
  CytTriggerView: 2155,
  DailyReportView: 2200,
  IcdDataCreateEdit: 2500,
  IcdRuleCreateEdit: 2550,
  IcdRuleDelete: 2555,
  IcdRuleView: 2560,

  InsuranceCodeCreateEdit: 2600,
  LabCreateEdit: 3000,
  LabView: 3010,
  LocationCreateEdit: 3200,
  MacroDelete: 3900,
  MacroDiagnosisCreateEdit: 4000,
  MacroExtractWords: 4005,
  MacroGeneralCreateEdit: 4010,

  MacroGrossCreateEdit: 4020,

  MacroMicroscopicCreateEdit: 4030,

  MacroPanelCreateEdit: 4040,

  MacroProtocolCreateEdit: 4050,

  MacroResultsCreateEdit: 4060,
  MacroMassAddToUser: 4065,
  MacroSpecimenNoteCreateEdit: 4070,
  MacroView: 4080,
  MacroViewAll: 4090,
  OrdersView: 4500,
  PathReportSendByEmail: 5000,
  PathReportSendByFax: 5010,
  PathReportSendByFileDrop: 5020,
  PathReportSendByHL7: 5030,
  PathReportSendByPrint: 5040,
  PathReportView: 5050,
  PopupCreateEdit: 5070,
  PopupDelete: 5072,
  PopupView: 5074,
  PrefixCreateEdit: 5100,
  PrefixFieldEditLastNumberUsed: 5102,
  PrefixView: 5110,
  PrinterCreateEdit: 5150,
  PrinterView: 5160,
  ProviderCreateEdit: 5200,
  ProxyAdmin: 5250,
  ProxyCreateEdit: 5260,
  ProxyView: 5270,
  ProcedureCreateEdit: 5300,
  ProcedureView: 5310,
  ReportCreateEdit: 5600,
  RoleCreateEdit: 6000,
  RoleDelete: 6010,
  SettingAdmin: 6500,
  SettingCreateEdit: 6510,
  SettingDelete: 6520,
  SettingView: 6530,
  SettingViewPrivate: 6540,
  SlideImageViewClient: 6600,
  SocialSecurityNumberView: 7000,
  TaskChangeStatus: 8000,
  TaskCreate: 8010,
  TaskDelete: 8020,
  TaskEdit: 8030,
  TaskEditAllUsers: 8035,
  TaskView: 8040,
  TaskViewAllUsers: 8050,
  TestRunCreateEdit: 8200,
  TestRunDelete: 8210,
  TestRunQueue: 8220,
  TestRunView: 8230,
  TransactionCodeCreateEdit: 9000,
  TransactionCodeView: 9010,
  TypeCodeAdmin: 9080,
  TypeCodeCreateEditAny: 9085,
  TypeCodeCreateEditCustomFieldNames: 9087,
  TypeCodeDelete: 9090,
  UserCreateEdit: 10000,
  UserView: 10010,
  UserAdminValidateMfaPin: 10020,

  /*--- End of Alphabetical Section #1 -- Start of Alphabetical Section #2 ---*/

  CaseEditAfterReportedDiagnostic: 90000,
  CaseEditAfterReportedNondiagnostic: 90001,
  CaseHistoryChange: 93000,
  CaseSearchByCaseAccessionedOn: 100000,
  CaseSearchByCaseCollectedOn: 100001,
  CaseSearchByCaseFirstSignedOn: 100002,
  CaseSearchByCaseNotes: 100003,
  CaseSearchByCaseOrderNumber: 100004,
  CaseSearchByCaseReceivedOn: 100005,
  CaseSearchByPathologistName: 100006,
  CaseSearchByPatientAccountNumber: 100007,
  CaseSearchByPatientDateOfBirth: 100008,
  CaseSearchByPatientMRN: 100009,
  CaseSearchByPatientName: 100010,
  CaseSearchByPatientSSN: 100011,
  CaseSearchByProviderName: 100012,
  CaseSearchBySpecimenClinical: 100013,
  CaseSearchBySpecimenDiagnosis: 100014,
  CaseSearchBySpecimenGross: 100015,
  CaseSearchBySpecimenIcdCode: 100016,
  CaseSearchBySpecimenMicroscopic: 100017,
  CaseSearchBySpecimenNote: 100018,
  CaseSearchBySpecimenResultMacroName: 100019,
  CaseSearchBySpecimenSite: 100020,
  CaseSearchBySite: 100021,
  CaseSearchByProviderLocationId: 100022, // renamed
  CaseSearchByProviderLocationCity: 100023,
  CaseSearchByProviderLocationZip: 100024,
  CaseSearchByProviderLocationState: 100025,
  CaseSearchByCaseNumber: 100026,
  CaseSearchByPrefix: 100027,
  CaseSearchByPrefixTags: 100028,
  CaseSearchByCPT: 100029,
  CaseSearchByHoldCodes: 100030,
  CaseSearchByAccessionedBy: 100031,
  CaseSearchByGrossedBy: 100032,
  CaseSearchByModifiedBy: 100033,
  CaseSearchByModifiedOn: 100034,
  CaseSearchBySignedOn: 100035, // renamed
  CaseSearchByNotSignedStatus: 100036,
  CaseSearchByReadyToRead: 100037,
  CaseSearchByCaseTags: 100038,
  CaseSearchByPatientAge: 100039,
  CaseSearchByPatientGender: 100040,
  CaseSearchByGrossedOn: 100041,
  CaseSearchByResultedOn: 100042,
  CaseSearchByResultedBy: 100043,
  CaseSearchByLabLocation: 100046,
  CaseSearchByInterfaceDiagnosis: 100047,
  CaseSearchByCaseStage: 100048,
  CaseSearchByProtocol: 100049,
  CaseSearchByBillingTypes: 100051,
  CaseSearchByBillingCycles: 100052
  // Cytology Related Permissions
};

export function getUserPermissions(distinctPermissions) {
  if (process.env?.VUE_APP_STAGE && process.env.VUE_APP_STAGE !== "Staging") {
    let missingPermissions = [];
    for (const enumValue of distinctPermissions) {
      if (!Object.values(permissionsEnum).includes(enumValue)) {
        missingPermissions.push(enumValue);
      }
    }
    if (missingPermissions.length) {
      window.alert(
        `Front End is missing implementation for the following permissions. Please inform the Front End Development team:<br><br>${missingPermissions.join(
          "<br>"
        )}`
      );
    }
  }
  const permissions = Object.keys(permissionsEnum).reduce((acc, curr) => {
    return { ...acc, [curr]: distinctPermissions.includes(permissionsEnum[curr]) };
  }, {});
  return permissions;
}

export default permissionsEnum;
