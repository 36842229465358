import { startCase } from "lodash";

export const userTypes = [
  // removed for now
  // {
  //   displayName: "Patient",
  //   id: 10
  // },
  {
    displayName: "Lab Client User",
    id: 20
  },
  {
    displayName: "Lab User",
    id: 30
  },
  {
    displayName: "Super User",
    id: 40
  }
];

export const UserTypesEnum = {
  Unspecified: 0,
  Patient: 10,
  LabClientUser: 20,
  LabUser: 30,
  SuperUser: 40
};
export const CaseCompletionModes = {
  None: 0,
  Accession: 1,
  Specimen: 2
};

export const TWAIN_STATUS = {
  closed: 0,
  opened: 1,
  enabled: 2,
  busy: 3
};

export const DashboardModes = {
  Cases: 0,
  Search: 1,
  Orders: 2,
  Tasks: 3,
  Reports: 4
};

export const FileFormatEnum = {
  PDF: 0,
  XML: 1,
  CSV: 2,
  MHTML: 3,
  EXCELOPENXML: 4,
  IMAGE: 5,
  EXCEL: 6,
  WORD: 7,
  HTML: 8,
  NULL: 9
};
export const DistributionModes = {
  Fax: "fax",
  Email: "email",
  HL7: "hl7",
  PDF: "pdf",
  Print: "print"
};
export const DistributionMethodsEnum = {
  Fax: 1,
  Email: 2,
  Print: 3,
  HL7: 4,
  FileDrop: 5
};

export const fontWhiteList = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
  "Segoe UI",
  "Helvetica"
];

export const fontSizes = [
  "7pt",
  "8pt",
  "9pt",
  "10pt",
  "11pt",
  "12pt",
  "14pt",
  "18pt",
  "24pt",
  "36pt"
];

export const AuditLogItems = {
  CreateAccession: 0,
  ChangeAccession: 1,
  DeleteAccession: 2,
  ViewAccession: 3,
  CreateAdmin: 4,
  ChangeAdmin: 5,
  DeleteAdmin: 6,
  ViewAdmin: 7,
  Excel: 8,
  Download: 9,
  Print: 10,
  Fax: 11,
  Email: 12,
  FileDrop: 13,
  HL7: 14,
  Reports: 15,
  Other: 16,
  Manual: 17
};

export const DateFormats = ["YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD", "MM/dd/yyyy", "MM/DD/yyyy"];
export const PathReportLocationsEnum = {
  Inline: 0,
  Window: 1,
  None: 2
};
export const PopupTypesEnum = {
  Text: 1,
  Image: 2
};

export const CasesModeEnum = {
  Open: "open",
  All: "all"
};

export const MacroTypeEnum = {
  Results: 0,
  Panel: 2,
  Protocol: 3,
  General: 4,
  Diagnosis: 5,
  Gross: 6,
  Microscopic: 7,
  SpecimenNote: 8,
  CaseNote: 9
};
export const MacroWindowClinicalEnum = {
  CurrentSpecimen: 0,
  FirstSpecimen: 1
};

export const CaseStatusEnum = {
  Acc: 1,
  Demo: 2,
  Spec: 3,
  Gross: 4,
  Orders: 5,
  ResultedOnHold: 6,
  SignedOnHold: 7,
  ReportedPrelim: 8,
  Resulted: 9,
  Signed: 10,
  Reported: 11,
  ResultedAgain: 12,
  SignedAgain: 13,
  ReReleased: 14
};

export const CaseEditTypeEnum = {
  Amended: 1,
  Addendum: 2,
  Corrected: 3,
  NonDiagnostic: 4,
  Other: 5,
  Billing: 6
};

export const LabNumberingMethodsEnum = {
  Departamental: 0,
  Pathology: 1
};

export const SpecimenNumbersEnum = {
  Numbers: 1,
  Letters: 2,
  UseLabSettings: 3
};

export const ResultsFocusBehaviorEnum = {
  ShowResultsMacros: 0,
  ShowDiagnosisMacros: 1,
  DoNothing: 2
};

export const AccessionNumberingTypeEnum = {
  Prefix: 0,
  Pathology: 1
};

export { default as PermissionsEnum } from "./permissionsEnum.js";
export function enumToDropDown(enumerable) {
  return Object.keys(enumerable).map(e => {
    return {
      displayName: startCase(e),
      id: enumerable[e]
    };
  });
}

export const TzDbCodesEnum = {
  //currently only North American codes included
  "America/St_Johns": 73,
  "America/Puerto_Rico": 75,
  "America/Cuiaba": 76,
  "America/Halifax": 78,
  "America/Grand_Turk": 80,
  "America/Indiana/Indianapolis": 81,
  "America/Havana": 82,
  "America/Port-au-Prince": 83,
  "America/New_York": 84,
  "America/Detroit": 84,
  "America/Cancun": 85,
  "America/Regina": 87,
  "America/Mexico_City": 88,
  "America/Adak": 90,
  "Pacific/Honolulu": 91,
  "America/Anchorage": 93,
  "Etc/GMT+9": 94,
  "Mexico/BajaNorte": 95,
  "Etc/GMT+8": 97,
  "America/Phoenix": 98,
  "America/Ojinaga": 99,
  "America/Denver": 100,
  "America/Boise": 100,
  "Canada/Yukon": 101,
  "America/Chicago": 103,
  "America/Los_Angeles": 104
};

export const AccessioningArrangementEnum = {
  Quick: 0,
  Grouped: 1
};

export const CaseCreationMethodEnum = {
  Manual: 1,
  HL7: 2,
  Barcode: 3,
  Converted: 4
};
export const DefaultDashboardModeEnum = {
  OpenCases: 0,
  AllCases: 1,
  Orders: 2,
  Tasks: 3,
  Search: 4
};

export const TypeCodeGroupEnum = {
  BillingCycle: 3,
  CasePhoneType: 9,
  ImageType: 19,
  LocationPhoneType: 23,
  Race: 37,
  Prefix: 52,
  ReportGroup: 100,
  BodyPart: 101,
  HoldCode: 102,
  Tag: 103,
  AccessionPriority: 104,
  TaskPriority: 105,
  InsuranceCategory: 106,
  BillingRate: 107,
  DiagnosisSummary: 110,
  OrderDiagnosis: 111,
  CptCodePair: 112,
  CopyName: 114,
  PrefixTag: 115,
  ContactCustomFieldName: 116,
  ProviderCustomFieldName: 117,
  LocationCustomFieldName: 118,
  InsuranceCustomFieldName: 119,
  PrefixCustomFieldName: 120,
  ProcedureTag: 121,
  SpecimenType: 123
};

export const CaseOrdersFieldsEnum = {
  Default: 0,
  Order: 1,
  Panel: 2
};

export const SpecimenIcdOptionalEnum = {
  Off: 0,
  Warning: 1,
  On: 2
};

export const MacroDateTimeEnum = {
  D1: "M/D/YY",
  D01: "MM/DD/YY",
  D2: "M/D/YYYY",
  D3: "MMM D, YYYY",
  D4: "MMMM D, YYYY",
  D5: "D/M/YY",
  D6: "D/M/YYYY",
  D7: "D MMM YY",
  D8: "D MMM YYYY",
  D9: "YY/M/D",
  D10: "YYYY/M/D",
  D11: "YYMMDD",
  D12: "YYYYMMDD",
  D13: "M/YY",
  D14: "M/YYYY",
  D15: "YY/M",
  D16: "YYYY/M",
  T1: "k:mm",
  T2: "kkmm",
  T3: "h:mmA",
  T03: "hh:mmA",
  T4: "k:mm:ss",
  T5: "kkmmss",
  T6: "h:mm:ssA"
};

export const AutoNextCaseEnum = {
  Never: 0,
  AfterSave: 1,
  AfterSign: 2
};

export const MRNMatchingEnum = {
  Never: 1,
  Prefix: 2,
  Always: 3
};

export const SpecimenFocusEnum = {
  Protocol: 0,
  Site: 1,
  Measurement: 2
};

export const ImageFileTypeEnum = {
  PNG: 0,
  PDF: 1,
  TIF: 2
};

export const MacroWindowPrimaryTextEnum = {
  None: 0,
  Clinical: 1,
  Gross: 2
};

export const BillingTypeEnum = {
  Patient: 0,
  Doctor: 1,
  CashPay: 2,
  // Also include UCSF's display names just in case
  Insurance: 0,
  ClientBill: 1,
  SelfPay: 2
};

export const SpecimenTypes = {
  Surgical: 1,
  Cytology: 2
};

export const CytologyCodeGroup = {
  Adequacy: 1,
  AdequacyFreeText: 2,
  AdequacyModifier: 3,
  Comment: 4,
  CommentFreeText: 5,
  Diagnosis: 6,
  DiagnosisModifier: 7,
  GeneralCategorization: 8,
  Hormonal: 9,
  HormonalFreeText: 10,
  Recommendation: 11,
  RecommendationFreeText: 12
};

export const CytTypeEnum = {
  GynecologicalCytology: 1,
  "Non-Gynecological Cytology": 2,
  "Hybrid Gynecological/Non-Gynecological Cytology": 3,
  "Thin-Prep": 4,
  Surgical: 5
};
export const CytReviewTypeEnum = {
  None: 10,
  Screener: 20,
  Reviewer: 30,
  Pathologist: 40
};

export const CytCodeGroupEnum = {
  Adequacy: 1,
  AdequacyFreeText: 2,
  AdequacyModifier: 3,
  Comment: 4,
  CommentFreeText: 5,
  Diagnosis: 6,
  DiagnosisModifier: 7,
  GeneralCategorization: 8,
  Hormonal: 9,
  HormonalFreeText: 10,
  Recommendation: 11,
  RecommendationFreeText: 12
};

export const CytQuestionTypeEnum = {
  Text: 1,
  Date: 2,
  List: 3
};
export const CytMolecTestStatusEnum = {
  Requested: 1,
  Sent: 2,
  Received: 3,
  Resulted: 4
};
