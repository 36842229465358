<template>
  <aside id="quick_links_sidebar" class="quick_links accordion rounded">
    <div class="bg-white rounded px-2 pb-1 quick-links-header">
      <h4>
        Quick Links
        <icon-button
          class="btn text-primary pointer p-0 px-1 mr-1"
          :icon="isUnlocked ? 'lock-open' : 'lock'"
          @click="toggleLock"
        />
      </h4>
    </div>
    <div class="bg-white relative">
      <div class="w-full d-flex">
        <DxLoadIndicator :element-attr="indicatorAttr" :visible="isLoading" />
      </div>
      <draggable
        v-model="draggableCards"
        :disabled="!isUnlocked"
        @start="dragging = true"
        @end="dragging = false"
        :class="{ unlocked: isUnlocked }"
      >
        <div v-for="element in draggableCards" :key="element.name">
          <div class="card" v-if="element.name === 'holds'">
            <div
              class="card-header d-flex align-items-center"
              role="tabpanel"
              v-shortkey="removeHoldShortkey"
              @shortkey="removeHolds"
            >
              <h6 class="text-danger order-header flex-grow-1 mr-auto">
                <button
                  v-shortkey="shortkeyAltF(11)"
                  @shortkey="togglePopup('holdsPopup')"
                  @click="togglePopup('holdsPopup')"
                  class="btn px-0 holds-text btn-link btn-block text-left"
                  :class="{ 'text-danger': holdsCount }"
                >
                  <span v-if="holdsCount" class="circle"></span>
                  Holds{{ holdsCount ? `(${holdsCount})` : "" }}
                </button>
              </h6>

              <icon-button
                @click.stop="expand('holds')"
                data-testid="expandbtn"
                type="button"
                :icon="quickLinksOrder.openCard !== 'holds' ? 'chevron-down' : 'chevron-up'"
                v-if="permissions.CaseHoldCreateEdit"
              />
            </div>
            <QuickLinkHold
              v-if="quickLinksOrder.openCard === 'holds' && permissions.CaseHoldCreateEdit"
              :caseStatus="caseStatus"
            />
          </div>
          <div class="card" v-if="element.name === 'orders'">
            <div
              role="tabpanel"
              data-testid="quickLinks-orders"
              class="card-header d-flex align-items-center"
            >
              <h6 class="mb-0 flex-grow-1 mr-auto">
                <button
                  v-shortkey="ordersShortkey"
                  @shortkey="togglePopup('caseProceduresPopup')"
                  @click="togglePopup('caseProceduresPopup')"
                  role="button"
                  :aria-expanded="quickLinksOrder.openCard === 'holds'"
                  class="btn btn-link px-0 btn-block text-left"
                >
                  Orders {{ caseOrderAmount ? `(${caseOrderAmount})` : "" }}
                </button>
              </h6>

              <icon-button
                data-testid="expandbtn"
                type="button"
                :icon="quickLinksOrder.openCard !== 'orders' ? 'chevron-down' : 'chevron-up'"
                @click.stop="expand('orders')"
                v-if="permissions.CassetteProcedureCreateEdit"
              />
            </div>

            <form
              v-if="
                quickLinksOrder.openCard === 'orders' && permissions.CassetteProcedureCreateEdit
              "
              @submit.prevent="handleSubmit"
              class="side_info orders my-1 py-1 rounded container px-0"
            >
              <div class="d-flex justify-content-start">
                <div class="side_info_data d-flex flex-column">
                  <div class="boxes">
                    <checkbox
                      v-for="link in quickLinks"
                      :key="link.macroId ? link.macroId : link.id"
                      type="button"
                      :id="link.macroId ? link.macroId : link.id + Math.random()"
                      :value="
                        link.macroId
                          ? selectedPanelIds.includes(link.macroId)
                          : selectedProcIds.includes(link.id)
                      "
                      @input="addItem(link)"
                      class="text-primary text-left col align-items-center"
                    >
                      <template v-slot:label>
                        <span class="">
                          {{ link.macroId ? link.macroName : link.description }}
                        </span>
                      </template>
                    </checkbox>
                  </div>
                  <button
                    @click="procedurePopup = true"
                    type="button"
                    class="btn text-primary text-left"
                  >
                    More..
                  </button>
                </div>
                <fieldset class="blocks">
                  <div class="border pl-1 mr-1">
                    <checkbox
                      id="selectAllBlocks"
                      @input="selectAllBlocks(caseBlocks)"
                      ref="selectAll"
                      label="All"
                    />
                    <checkbox
                      :key="i"
                      v-for="(block, i) in caseBlocks"
                      ref="blocks"
                      :id="block.blockLabel + Math.random() + i"
                      :value="selectedIds.includes(block.id)"
                      @input="addBlock(block)"
                      :label="displayBlockLabel(block)"
                      :disabled="
                        block.blockNum > 0 && noBlocksSpecimens.includes(block.specimenOrder)
                      "
                    />
                  </div>
                </fieldset>
              </div>
              <div class="px-2">
                <label for="note">
                  <b> Notes to Histology </b>
                </label>
                <text-area-input
                  name="note"
                  ref="note"
                  :resize="false"
                  :noLabel="true"
                  class="note"
                  v-model="note"
                  :generalMacrosEnabled="true"
                  :validator="$v.note"
                  startHeight="10rem"
                />
              </div>
              <div class="d-flex justify-content-end">
                <button :disabled="$v.$invalid" type="submit" class="btn btn-primary">Send</button>
              </div>
            </form>
          </div>
          <div class="card" v-if="element.name === 'tags'">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h6 class="d-flex align-items-start tags-notes-header flex-grow-1 mr-auto">
                <button class="btn p-0">
                  <span
                    @click="togglePopup('tagsPopup', true)"
                    class="pointer text-primary px-0 btn btn-link text-left"
                    v-shortkey="shortkeyAltF(10)"
                    @shortkey="togglePopup('tagsPopup', true)"
                  >
                    Tags{{ tagsCount ? `(${tagsCount}) ` : "" }}
                  </span>
                  <span> | </span>
                  <span :class="{ 'notes-warning': notesCount }">
                    <span v-if="notesCount" class="circle text-warning"></span>
                    <span
                      @click="togglePopup('tagsPopup', false)"
                      class="pointer text-primary px-0 btn btn-link text-left"
                      :class="{ 'text-warning notes-warning': notesCount }"
                      v-shortkey="shortkeyAltF(7)"
                      @shortkey="togglePopup('tagsPopup', false)"
                    >
                      Notes{{ notesCount ? `(${notesCount})` : "" }}
                    </span>
                  </span>
                </button>
              </h6>

              <icon-button
                type="button"
                class="btn"
                @click.stop="expand('tags')"
                :icon="quickLinksOrder.openCard !== 'tags' ? 'chevron-down' : 'chevron-up'"
                v-if="permissions.CaseTagNoteCreateEdit"
              />
            </div>
            <!-- Quick Links Tag\Notes -->
            <QuickLinkTags
              v-if="quickLinksOrder.openCard === 'tags' && permissions.CaseTagNoteCreateEdit"
            />
          </div>
          <div class="card" v-if="element.name === 'images'">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h6 class="d-flex align-items-start tags-notes-header flex-grow-1 mr-auto">
                <button
                  class="btn p-0"
                  v-shortkey="shortkeyAltF(9)"
                  @shortkey="togglePopup('imagesPopup')"
                  @click="togglePopup('imagesPopup')"
                >
                  <span class="pointer text-primary px-0 btn btn-link text-left">
                    Images{{ imageCount ? ` (${imageCount})` : "" }}
                  </span>
                </button>
              </h6>

              <icon-button
                type="button"
                class="btn"
                @click.stop="expand('images')"
                :icon="quickLinksOrder.openCard !== 'images' ? 'chevron-down' : 'chevron-up'"
                v-if="permissions.CaseImageCreateEdit"
              />
            </div>
            <!-- Quick Links Tag\Notes -->
            <case-image-form
              v-if="quickLinksOrder.openCard === 'images' && permissions.CaseImageCreateEdit"
              class="case-image-form-ql"
              :caseId="caseDetails.caseId"
              :specimenId="currentSpecimen.id"
              @submit="handleImageSubmit"
              :isQuickLinks="true"
            />
          </div>
        </div>
      </draggable>
    </div>
    <Modal :status="caseProceduresPopup" @close="handleCloseCaseProcedures">
      <procedure-popup @close="handleCloseCaseProcedures" :caseId="caseDetails.caseId" />
    </Modal>
    <Modal :status="procedurePopup" @close="handleCloseProcedure">
      <MoreOrdersList @appliedOrders="handleAppliedOrders" />
    </Modal>
    <Modal
      :status="procedureFormPopup"
      @close="handleCloseEdit"
      v-if="permissions.CassetteProcedureCreateEdit"
    >
      <edit-procedure-popup
        @submit="handleEditProcedure"
        :procedureId="editProcedure"
        :panelId="editPanelId"
        class="order-list"
        @close="handleCloseEdit"
      />
    </Modal>
    <Modal :status="tagsPopup" @close="tagsPopup = !tagsPopup">
      <quick-links-tag-popup :isTag="isTag" />
    </Modal>
    <Modal :status="imagesPopup" @close="imagesPopup = !imagesPopup">
      <quick-links-image-popup />
    </Modal>
    <Modal :status="holdsPopup" @close="holdsPopup = !holdsPopup">
      <quick-links-hold-popup @close="holdsPopup = !holdsPopup" />
    </Modal>
  </aside>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import api from "../../services/api";
import Checkbox from "../common/Checkbox.vue";
import { maxLength, required } from "vuelidate/lib/validators";
import { tap } from "rxjs/operators";
import TextAreaInput from "../TextAreaInput.vue";
import Modal from "../common/Modal.vue";
import ProcedurePopup from "@/components/ProcedurePopup.vue";
import EditProcedurePopup from "../EditProcedurePopup.vue";
import { addLoadFilter, altKey, toLetters } from "../../modules/helpers";
import QuickLinkTags from "./QuickLinksTags.vue";
import QuickLinkHold from "./QuickLinksHold.vue";
import QuickLinksTagPopup from "../QuickLinksTagPopup.vue";
import QuickLinksHoldPopup from "../QuickLinksHoldPopup.vue";
import { sortBy } from "lodash";
import { MacrosApi } from "@/services/index";
import IconButton from "../common/IconButton.vue";
import CaseImageForm from "../CaseImageForm.vue";
import QuickLinksImagePopup from "../QuickLinksImagePopup.vue";
import MoreOrdersList from "../MoreOrdersList.vue";
import { SpecimenNumbersEnum } from "@/modules/enums";
import draggable from "vuedraggable";
import getOS from "@/modules/getOs";
import { handleErrors } from "@/modules/handleErrors";
import DxLoadIndicator from "devextreme-vue/load-indicator";
export default {
  components: {
    Checkbox,
    TextAreaInput,
    Modal,
    ProcedurePopup,
    EditProcedurePopup,
    QuickLinkTags,
    QuickLinkHold,
    QuickLinksTagPopup,
    QuickLinksHoldPopup,
    IconButton,
    CaseImageForm,
    QuickLinksImagePopup,
    MoreOrdersList,
    draggable,
    DxLoadIndicator
  },
  name: "Quick_Links",
  provide() {
    return {
      labProcedures: () => this.labProcedures,
      labPanels: () => this.panels,
      editedProcedures: () => this.editedProcedures,
      editedPanels: () => this.editedPanels
    };
  },
  created() {
    MacrosApi.searchStore
      .load({
        filter: ["macroType", "=", 2],
        sort: [{ selector: "macroName", desc: false }]
      })
      .then(res => {
        this.panels = res || [];
      });
    this.loadUserProcedures();
    this.$store.dispatch("sessionDetails/getRoleQuickLinks");
  },
  domStreams: ["search$", "blurSearch$"],
  subscriptions() {
    //Adds a listener to the reload event in the accession page. We are pluggin into this to keep our check boxes from changing.
    return {
      display$: this.$watchAsObservable("selectedIds", { immediate: false }).pipe(
        tap(() => this.$nextTick(this.updateDisplay))
      )
    };
  },
  validations() {
    return {
      selectedBlocks: {
        required
      },
      ordersForSelectedBlocks: {
        required
      },
      note: {
        maxLength: maxLength(255)
      }
    };
  },
  methods: {
    ...mapActions(["getUserProcedures"]),
    addItem(item) {
      if (item.macroId) {
        return this.addPanel(item);
      }
      return this.addProc(item);
    },
    togglePopup(popup, isTag) {
      this.isTag = isTag;
      this[popup] = !this[popup];
    },
    expand(prop) {
      if (this.quickLinksOrder.openCard === prop) {
        prop = null;
      }
      this.$store.commit("applicationSettings/setQuickLinksOrder", {
        ...this.quickLinksOrder,
        openCard: prop
      });
    },
    handleCloseCaseProcedures() {
      this.caseProceduresPopup = false;
    },

    handleCloseEdit() {
      this.editPanelId = null;
      this.editProcedure = null;
      this.procedureFormPopup = false;
    },
    handleCloseProcedure() {
      this.procedurePopup = false;
    },
    handleAppliedOrders() {
      this.procedurePopup = false;
      if (this.showOrdersGridAfterAdd) {
        this.caseProceduresPopup = true;
      }
      this.getCaseProcedures();
    },
    getCaseProcedures() {
      const { caseId } = this.$route.params;
      this.$store.dispatch("accessionStore/getCaseOrders", caseId);
    },
    async loadUserProcedures() {
      this.isLoading = true;
      try {
        this.labProcedures = await this.procedureStore.load({
          sort: [{ selector: "description", desc: false }]
        });
        await this.getUserProcedures({ labId: this.currentLab });
      } catch (error) {
        console.log("Error loading the user procedures");
      } finally {
        this.isLoading = false;
      }
    },
    displayBlockLabel(block) {
      if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
        if (block.blockNum === 0) {
          return block.specimenOrder + " 0";
        }
        return `${block.specimenOrder} ${block.blockNum ? toLetters(block.blockNum) : ""}`;
      }
      return `${block.specimenOrder} ${block.blockNum}`;
    },
    editPanel(panel) {
      this.editPanelId = panel.macroId;
      this.procedureFormPopup = true;
    },
    editProc(procedure) {
      this.editProcedure = procedure.id;
      this.procedureFormPopup = true;
    },
    handleEditProcedure(procedure) {
      if (procedure.panelId) {
        const target = this.panels.findIndex(e => e.macroId === procedure.panelId);
        this.panels[target].isEdited = true;
        this.editedPanels = [
          ...this.editedPanels.filter(e => e.macroId !== procedure.panelId),
          procedure
        ];
        this.selectedPanels = [
          ...this.selectedPanels.filter(e => e.macroId !== procedure.panelId),
          procedure
        ];
        this.editPanelId = null;
      } else {
        const target = this.labProcedures.findIndex(e => e.id === procedure.id);
        this.labProcedures[target].isEdited = true;
        this.editedProcedures = [
          ...this.editedProcedures.filter(e => e.id !== procedure.id),
          procedure
        ];
        this.selectedProcedures = [
          ...this.selectedProcedures.filter(e => e.id !== procedure.id),
          procedure
        ];
        this.editProcedure = null;
      }
      this.procedureFormPopup = false;
      //Payload for adding order to a case.
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");
        return;
      }
      const noProcedurePanel = this.selectedPanels.find(panel => !panel.procedures?.length);
      if (noProcedurePanel) {
        window.alert(
          `The following panel doesn't have any orders please verify your input and try again. <br> <h3>${noProcedurePanel?.macroName}</h3>`
        );
        return;
      }
      const currentOrders = this.caseProcedures.reduce((acc, curr) => {
        if (!acc[curr.cassetteId]) {
          acc[curr.cassetteId] = [curr.procedureId];
        } else {
          acc[curr.cassetteId] = [...acc[curr.cassetteId], curr.procedureId];
        }
        return acc;
      }, {});
      const blockMessages = {};
      const payload = { note: this.note, items: [] };
      const selectedOrders = [
        ...this.selectedProcedures,
        ...this.selectedPanels
          .map(panel => {
            return panel.procedures.map(order => {
              return {
                ...order,
                panelId: panel.macroId,
                detail: this.note || panel.detail
              };
            });
          })
          .flat()
      ];
      for (let block of this.selectedBlocks) {
        let message = [];
        if (block.blockNum === 0) {
          block = { ...block, blockNum: 1, noBlocks: true };
        }
        for (const order of selectedOrders) {
          if (currentOrders[block.id] && currentOrders[block.id].includes(order.id)) {
            message.push(`<li>${order.code} - ${order.description}</li>`);
          } else {
            if (this.labSettings.DefaultToZeroSlides && order?.noBlocks) {
              order.numberOfSlides = 0;
            }
            payload.items.push({
              ...order,
              specimenId: block.specimenId,
              procedureId: order.id,
              panelId: order.macroId,
              procedureDescription: order.description,
              billingTransactionCodeId: order.billingTransactionCodeId,
              holdCode: order?.holdCodes || order?.holdCode,
              holdCodeId: order?.holdCodeId || order?.holdCode || order?.holdCodes,
              blockNum: block.blockNum || order.defaultBlockNum,
              defaultBlockNum: block.blockNum || order.defaultBlockNum,
              detail: this.note || order.detail,
              noBlocks: block?.noBlocks || false
            });
          }
        }
        if (message.length) {
          const targetBlock = this.caseBlocks.find(e => e.id === block.id);
          blockMessages[block.id] = {
            message,
            label: `${targetBlock.specimenOrder}-${targetBlock.blockNum}`
          };
        }
      }
      if (Object.keys(blockMessages).length) {
        await window.alert(`
        <p>The following orders will not be added because they already exist.</p>
          ${Object.keys(blockMessages)
            .map(block => {
              return `
                  <p>${blockMessages[block].label}</p>
                  <ul>${blockMessages[block].message.join("")}</ul>
              `;
            })
            .join("")}
      `);
      }
      if (payload.items.length) {
        const response = await this.$store.dispatch("accessionStore/addCaseOrders", payload);
        if (response.validationErrors?.length) {
          window.notify("Sorry please check your input.", "error");
        } else {
          window.notify(
            `Successfully applied ${this.ordersForSelectedBlocks.length} orders(s) to ${this.selectedBlocks.length} block(s).`
          );
          this.selectedBlocks = [];
          this.selectedProcedures = [];
          this.selectedPanels = [];
          this.note = "";
          this.updateDisplay();
        }
        this.$nextTick(() => {
          this.procedurePopup = false;
          if (this.showOrdersGridAfterAdd) {
            this.caseProceduresPopup = true;
          }
        });
      }
    },
    handleImageSubmit() {
      this.isImagesExpanded = false;
      this.$nextTick(() => {
        this.isImagesExpanded = true;
      });
    },
    addProc(procedure) {
      if (this.selectedProcIds.includes(procedure.id)) {
        return (this.selectedProcedures = this.selectedProcedures.filter(
          e => e.id !== procedure.id
        ));
      }
      return (this.selectedProcedures = [...this.selectedProcedures, procedure]);
    },
    addPanel(panel) {
      if (this.selectedPanelIds.includes(panel.macroId)) {
        return (this.selectedPanels = this.selectedPanels.filter(e => e.macroId !== panel.macroId));
      }
      return (this.selectedPanels = [...this.selectedPanels, panel]);
    },
    addBlock(block) {
      if (this.selectedIds.includes(block.id)) {
        return (this.selectedBlocks = this.selectedBlocks.filter(e => e.id !== block.id));
      }
      if (block.blockNum === 0) {
        return (this.selectedBlocks = [...this.selectedBlocks, block].filter(
          e => e.specimenOrder !== block.specimenOrder || e.blockNum === 0
        ));
      }
      return (this.selectedBlocks = [...this.selectedBlocks, block]);
    },
    selectAllBlocks(blocks) {
      const noBlocksSelected = this.selectedBlocks.filter(e => e.blockNum === 0);
      const blocksWithoutNoBlocks = blocks.filter(e => e.blockNum > 0);
      const selectedIdsWithBlocks = this.selectedIds.filter(e => !/NB-/.test(e));
      if (selectedIdsWithBlocks.length < blocksWithoutNoBlocks.length) {
        return (this.selectedBlocks = blocksWithoutNoBlocks);
      }
      if (selectedIdsWithBlocks.length === blocksWithoutNoBlocks.length) {
        return (this.selectedBlocks = noBlocksSelected);
      }
      return blocks.forEach(block => this.addBlock(block));
    },
    updateDisplay() {
      const $el = this.procedurePopup ? this.$refs?.selectAllModal?.$el : this.$refs.selectAll?.$el;
      if ($el) {
        const overall = $el.querySelector('input[type="checkbox"]');
        var checkedCount = this.selectedIds.length;
        if (checkedCount === 0) {
          overall.checked = false;
          overall.indeterminate = false;
        } else if (checkedCount === this.caseBlocks.length) {
          overall.checked = true;
          overall.indeterminate = false;
        } else {
          overall.checked = false;
          overall.indeterminate = true;
        }
      }
    },
    findNodeByCoordinates(target, grid) {
      // Index = (Row * max_columns) + Col - max_columns - 1
      for (let x = 0; x < grid.x; x++) {
        for (let y = 0; y < grid.y; y++) {
          if (target.x === x && target.y === y) {
            return Math.abs(y * grid.x + x - grid.y - 1);
          }
        }
      }
    },
    getGridElementsPosition(index, grid) {
      //Get the css attribute grid-template-columns from the css of class grid
      //split on whitespace and get the length, this will give you how many columns
      const { gridTemplateColumns, gridTemplateRows } = window.getComputedStyle(grid);

      const colCount = gridTemplateColumns.split(" ").length;
      const rowCount = gridTemplateRows.split(" ").length;
      const colPosition = index % colCount;
      const rowPosition = Math.floor(index / colCount);
      return {
        x: colPosition,
        y: rowPosition,
        cols: colCount,
        rows: rowCount
      };
    },
    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    toggleLock() {
      if (!this.isUnlocked) {
        this.lastOpenCard = this.quickLinksOrder.openCard;
        this.$store.commit("applicationSettings/setQuickLinksOrder", {
          ...this.quickLinksOrder,
          openCard: null
        });
      } else {
        this.$store.dispatch("applicationSettings/setUserSettings", {
          quickLinksOrder: { ...this.quickLinksOrder, openCard: this.lastOpenCard }
        });
        this.lastOpenCard = null;
      }
      this.isUnlocked = !this.isUnlocked;
    },
    shortkeyAltF(key) {
      return altKey("f" + key);
    },
    async removeHolds() {
      if (!this.caseHolds?.length) {
        return;
      }
      const confirm = this.confirmRemoveHold
        ? await window.confirm(`Are you sure you want to remove all holds on this case?`)
        : true;
      if (!confirm) {
        return;
      }
      this.isLoading = true;
      try {
        const { caseId } = this.$route.params;
        await this.$store.dispatch("accessionStore/removeQuickLink", {
          type: "H",
          caseId,
          ids: this.caseHolds.map(e => e.id)
        });
        window.notify("Removed all holds from case.");
        this.$store.dispatch("accessionStore/getCaseHeader", caseId);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    clearSelectedProcedures() {
      this.selectedPanels = [];
      this.selectedProcedures = [];
      this.selectedBlocks = [];
    }
  },
  data() {
    return {
      tagsPopup: false,
      holdsPopup: false,
      imagesPopup: false,
      isUnlocked: false,
      lastOpenCard: null,
      indicatorAttr: { class: "mx-auto" },
      panels: [],
      selectedBlocks: [],
      selectedProcedures: [],
      selectedPanels: [],
      labProcedures: [],
      editedProcedures: [],
      editedPanels: [],
      editPanelId: null,
      note: "",
      isLoading: false,
      procedureFormPopup: false,
      editProcedure: null,
      procedurePopup: false,
      caseProceduresPopup: false,
      isFiltered: false,
      isListLoading: false,
      filteredLabProcedures: [],
      filteredLabPanels: [],
      isProcedureExpanded: true,
      isTagNotesExpanded: false,
      isImagesExpanded: false,
      isHoldsExpanded: false,
      links: [
        {
          name: "Orders",
          data: [
            "Immunoperox. or in-situ hyb",
            "cd 20",
            "colloidal iron",
            "prame melan-a multiplex"
          ]
        },
        {
          name: "Tags",
          data: ["interesting case", "hma study"]
        },
        {
          name: "Hold",
          data: ["consult", "imuno"]
        }
      ],
      isTag: false,
      ordersShortkey: getOS().includes("Mac") ? altKey("v") : this.shortkeyAltF(8),
      removeHoldShortkey: altKey("q")
    };
  },
  watch: {
    procedurePopup(nv) {
      if (!nv) {
        this.selectedPanels = [];
      }
    },
    caseBlocks(nv, ov) {
      if (nv?.length !== ov?.length) {
        this.selectedBlocks = [];
      }
    },
    currentSpecimen(nv) {
      if (nv?.id) {
        this.selectedBlocks = this.caseBlocks.filter(
          block => block.specimenId === nv.id && !/^NB/i.test(block.id)
        );
      }
    },
    "caseDetails.caseId": {
      immediate: true,
      handler(nv, ov) {
        if (nv !== ov) {
          this.clearSelectedProcedures();
        }
      }
    },
    "$route.params.caseId"(nv, ov) {
      if (parseInt(nv) !== ov) {
        this.clearSelectedProcedures();
      }
    }
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      currentUser: state => state.currentUser,
      labSettings: state => state.labSettings,
      sessionDetails: state => state.sessionDetails,
      userProcedures: state => state.userProcedures,
      specimens: state => state.accessionStore.specimens,
      caseDetails: state => state.accessionStore.caseDetails,
      caseProcedures: state => state.accessionStore.caseOrders,
      caseHolds: state => state.accessionStore.caseHolds,
      caseTags: state => state.accessionStore.caseTags,
      caseNotes: state => state.accessionStore.caseNotes,
      caseImages: state => state.accessionStore.caseImages,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      userQuickLinks: state => state.sessionDetails.quickLinks,
      quickLinksOrder: state => state.applicationSettings.quickLinksOrder,
      confirmRemoveHold: state => state.applicationSettings.confirmRemoveHold,
      showOrdersGridAfterAdd: state => state.applicationSettings.showOrdersGridAfterAdd,
      quickLinksNoBlocks: state => state.applicationSettings.quickLinksNoBlocks,
      quickLinksCustomOrder: state => state.applicationSettings.quickLinksCustomOrder
    }),
    ...mapGetters("accessionStore", [
      "caseStatus",
      "specimenNumbering",
      "isReported",
      "isCaseEditable",
      "caseStatus"
    ]),
    ...mapGetters(["permissions"]),
    holdsCount() {
      return this.caseHolds.length;
    },
    notesCount() {
      return this.caseNotes.length;
    },
    tagsCount() {
      return this.caseTags.length;
    },
    ordersCount() {
      return this.caseProcedures.length;
    },
    imageCount() {
      return this.caseImages.length;
    },
    caseOrderAmount() {
      return this.caseProcedures.length;
    },
    quickLinks() {
      if (this.quickLinksCustomOrder?.length) {
        let returnList = [];
        for (const procedure of this.quickLinksCustomOrder) {
          const procedureToAdd = this.userProcedures.find(
            e => e.id === procedure.id || (e.macroId && e.macroId === procedure.macroId)
          );
          if (procedureToAdd) {
            returnList.push(procedureToAdd);
          }
        }
        for (const procedure of this.userProcedures) {
          if (
            !this.quickLinksCustomOrder.find(
              e => e.id === procedure.id || (e?.macroId && e.macroId === procedure?.macroId)
            )
          ) {
            returnList.push(procedure);
          }
        }
        return returnList;
      }
      return this.userProcedures;
    },

    procedureStore() {
      const lab = this.currentLab;
      return api.createSearch(`/api/Labs/${lab}/Procedures`, "id", undefined, addLoadFilter);
    },
    selectedIds() {
      return this.selectedBlocks.map(e => e.id);
    },
    selectedProcIds() {
      return this.selectedProcedures.map(e => e.id);
    },
    selectedPanelIds() {
      return this.selectedPanels.map(e => e.macroId);
    },
    caseBlocks() {
      return sortBy(
        this.specimens
          .map(specimen => {
            let specimenBlocks = [];
            if (specimen.cassettes?.length) {
              specimenBlocks = specimen.cassettes.map(block => {
                return { ...block, specimenOrder: specimen.specimenOrder };
              });
            }
            if (this.quickLinksNoBlocks) {
              specimenBlocks.push({
                blockNum: 0,
                specimenOrder: specimen.specimenOrder,
                id: "NB-" + specimen.specimenOrder,
                blockLabel: "NoBlocks" + specimen.specimenOrder,
                specimenId: specimen.id
              });
            }
            return specimenBlocks;
          })
          .flat() || [],
        ["specimenOrder", "blockNum"]
      );
    },
    ordersForSelectedBlocks() {
      return [...this.selectedProcedures, ...this.selectedPanels];
    },
    draggableCards: {
      get() {
        return this.quickLinksOrder.items;
      },
      set(value) {
        this.$store.commit("applicationSettings/setQuickLinksOrder", {
          ...this.quickLinksOrder,
          items: value
        });
      }
    },
    noBlocksSpecimens() {
      return this.selectedBlocks.filter(e => e.blockNum === 0).map(e => e.specimenOrder);
    }
  }
};
</script>

<style lang="scss" scoped>
.holds-text.text-danger {
  font-weight: 800;
  position: relative;
  .circle {
    display: block;
  }
}

.notes-warning {
  padding-left: 12px;
  font-weight: 800;
  position: relative;
  .circle {
    left: 0px;
    background: $warning;
    display: block;
  }
}

.circle {
  display: none;
  height: 10px;
  width: 10px;
  background-color: $red;
  position: absolute;
  top: 5px;
  left: -12px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 50%;
  font-weight: 600;
  font-size: 0.75rem;
  animation: pulse-red 2s infinite;
}
.header {
  background-color: $gray;
}
.quick-links-header {
  margin-bottom: -5px;
  border-bottom: 0;
}
.text-black {
  color: black;
}
.tags-notes-header {
  font-size: 1rem;
  margin-bottom: 0;
}
.font-weight-medium {
  font-weight: 500;
}
.panel-procedure {
  &::before {
    width: 4px;
    color: black;
  }
}
.list-group-item {
  &:focus {
    border: 2px solid black;
  }
}
.text-xss {
  font-size: 0.5rem;
}
.text-sm {
  font-size: 0.7rem;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.quick_links {
  width: 250px;
  overflow: hidden;
  overflow-y: auto;
  .blocks {
    height: 20vh;
    width: 30%;
    overflow-y: auto;
    &-selected {
      max-height: 40vh;
      overflow-y: auto;
    }
  }
}

.order-header {
  margin-bottom: 0;
  &:hover {
    text-decoration: underline;
  }
}

#searchInput {
  width: 300px;
  &::after {
    top: 12%;
    right: 10%;
    position: absolute;
    content: "ALT+K";
    font-weight: 500;
    border: 0.5px solid gray;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    color: gray;
  }
  &:focus-within {
    &::after {
      display: none;
    }
  }
}

.grid {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-template-rows: repeat(10, 1.5rem);
  grid-auto-flow: column;
  grid-auto-columns: 300px;
  column-gap: 1rem;
  row-gap: 0.5rem;
  &.panels {
    grid-template-rows: repeat(5, 1.5rem);
  }
}

.procedure-modal {
  width: 70vw;
  font-size: 0.75rem;
  .row {
    height: 100%;
  }
  .blocks {
    width: 100%;
    max-height: 15vh;
    font-size: 1rem;
    overflow-y: auto;
    &-selected {
      max-height: 40vh;
      overflow-y: auto;
    }
  }

  .procedures {
    max-width: 100%;
    padding: 1rem;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .panels {
    overflow-x: auto;
    height: 20vh;
  }
}
.side_info {
  font-size: 1rem;
  h6 {
    color: $primary;
  }
  .side_info_data {
    width: 70%;
    max-height: 25vh;
    text-transform: uppercase;
    .boxes {
      overflow: hidden;
      overflow-y: auto;
    }
    button:hover {
      text-decoration: underline;
    }
  }
}
.panels-list {
  .panels-list-group {
    display: none;
  }
  &:focus-within {
    .panels-list-group {
      display: block;
    }
  }
}
.panel-items {
  padding-left: 1rem;
  .panel-item {
    position: relative;
    margin-right: 1px;
    &::before {
      display: inline-flex;
      align-items: center;
      content: "\25CF";
      padding: 10px 0.5rem 1rem 0;
      padding-bottom: 0;
      color: black;
      position: absolute;
      top: 8px;
      left: -10%;
      margin: -0.875rem 0 1.5rem 5px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}
.selected-panel .specimen_select {
  ::v-deep custom-control {
    font-size: 0.7rem;
    padding: 0;
    min-height: 0.75rem;
  }
}

::v-deep .case-image-form-ql {
  .tags-input,
  .keywords-input,
  .comments-input {
    display: none;
  }
  &.image-form {
    min-width: initial;
  }
  & > div.d-flex {
    flex-direction: column;
  }
  .image-preview {
    width: 100%;
  }
}

.unlocked {
  &,
  *,
  * > * {
    cursor: move !important;
  }
}
.card {
  border-radius: initial;
}
@keyframes pulse-red {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.side_info_data {
  b {
    font-weight: 500;
  }
}
.text-lg {
  font-size: 1.25rem;
}
</style>
