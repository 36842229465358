const regex = /(<[A-Za-z]+\s*[^>]*>)(.+)(<\/[A-Za-z]+>)/m;
export default function upperCaseHtmlText(str) {
  str = str.replace(regex, matcherCb);
  return str;
}

function matcherCb(match, groupOne, groupTwo, groupThree) {
  if (groupTwo.match(regex)) {
    return `${groupOne}${groupTwo.replace(regex, matcherCb)}${groupThree}`;
  }
  return `${groupOne}${groupTwo.toUpperCase()}${groupThree}`;
}

export function upperCaseInHtmlTree(html) {
  const htmlTree = new DOMParser().parseFromString(html, "text/html");
  crawlNode(htmlTree.body.childNodes);
  return htmlTree.body.innerHTML;
}

function crawlNode(nodes) {
  for (const node of nodes) {
    if (node.childNodes?.length) {
      crawlNode(node.childNodes);
    } else if (node.nodeType === Node.TEXT_NODE) {
      const transform = node?.parentElement?.style?.textTransform;
      if (transform === "lowercase") {
        node.nodeValue = node.nodeValue.toLowerCase();
      } else {
        node.nodeValue = node.nodeValue.toUpperCase();
      }
    }
  }
}
