<template>
  <container class="container px-0">
    <form
      @submit.prevent="handleSubmit"
      class="p-3"
      v-shortkey="saveShortkey"
      @shortkey="handleSubmit"
    >
      <div class="d-flex">
        <h3>Location</h3>
      </div>
      <div class="d-flex">
        <div class="col pr-5 border-right">
          <text-input
            v-focus
            v-model="location.name"
            label="Name"
            name="Name"
            :validator="$v.location.name"
            maxLength="101"
            :validatorMsgMap="validatorMsgMap"
          />
          <embedded-provider
            v-model="selectedProviders"
            @submit="handleAddNewProvider"
            :validator="$v.selectedProviders"
            :validatorMsgMap="validatorMsgMap"
            :newProviderNpis="newProviderNpis"
            :locationContacts="locationContacts"
          />
          <select-input
            label="Timezone"
            v-model="location.properties.timeZoneId"
            :items="timeZones"
            displayExpr="displayName"
            name="timeZone"
            :validator="$v.location.properties.timeZoneId"
            :validatorMsgMap="validatorMsgMap"
          />
          <text-area-input
            v-model="location.comments"
            label="Comments"
            name="comments"
            maxLength="40001"
            rows="5"
            cols="20"
            :resize="false"
            :validator="$v.location.comments"
            :validatorMsgMap="validatorMsgMap"
          />
        </div>
        <div class="col pl-5">
          <text-input
            v-model="location.address1"
            label="Address"
            name="Address"
            maxLength="101"
            :validator="$v.location.address1"
            :validatorMsgMap="validatorMsgMap"
          />
          <text-input
            v-model="location.address2"
            label="Line 2"
            name="line2"
            maxLength="101"
            :validator="$v.location.address2"
            :validatorMsgMap="validatorMsgMap"
          />
          <address-lookup v-model="location" @zipCodeSelected="handleZipCode" />
          <text-input
            v-model="location.international"
            label="International"
            maxLength="101"
            name="international"
            :validator="$v.location.international"
            :validatorMsgMap="validatorMsgMap"
          />
        </div>
      </div>
      <div class="d-flex border-top border-bottom">
        <div class="border-right py-4 w-50">
          <div class="d-flex justify-content-between">
            <text-input
              name="reportFormat"
              label="Report Format"
              maxLength="1001"
              class="col"
              v-model="location.properties.reportFormat"
              :validator="$v.location.properties.reportFormat"
            />
            <select-input
              name="reportId"
              label="Path Report Template"
              :dataSource="reportsSearch"
              v-model="location.properties.reportId"
              :multiple="false"
              displayExpr="name"
              class="col"
              valueExpr="id"
              :template="false"
            />
            <select-input
              v-if="cytologyModuleEnabled"
              name="cytPathReportId"
              label="Cytology Path Report Template"
              :dataSource="reportsSearch"
              v-model="location.properties.cytPathReportId"
              :multiple="false"
              displayExpr="name"
              class="col"
              valueExpr="id"
              :template="false"
            />
          </div>
          <div class="d-flex justify-content-between">
            <select-input
              :items="[
                {
                  id: 1,
                  displayName: 'First, Last'
                },
                {
                  id: 2,
                  displayName: 'Last, First'
                }
              ]"
              class="col"
              name="patientNameOrder"
              label="Patient Name Order"
              v-model="location.properties.patientNameOrder"
            />

            <select-input
              class="col"
              :items="boolDropDown"
              name="patientHistory"
              id="patientHistory"
              label="Patient History on Report"
              v-model="location.properties.includePatientHistory"
            />
          </div>
          <div class="d-flex justify-content-between">
            <select-input
              class="col"
              name="includePatientAddress"
              :items="boolDropDown"
              id="patientAddress"
              label="Patient Address on Report"
              v-model="location.properties.includePatientAddress"
            />
            <select-input
              class="col"
              :items="boolDropDown"
              id="includICD"
              name="includeICD"
              label="ICD on Report"
              v-model="location.properties.includeICD"
            />
          </div>
        </div>
        <div class="py-4 w-50">
          <select-input
            class="col"
            :items="specimenNumberingOptions"
            name="specimenNumbering"
            label="Specimen Numbering"
            v-model="location.properties.specimenNumbering"
          />
          <div class="d-flex justify-content-between">
            <select-input
              name="billingRateNumber"
              :items="billingRates"
              class="col"
              label="Billing Rate"
              displayExpr="displayName"
              v-model="location.properties.billingRateNumber"
            />
            <select-input
              name="defaultBillingType"
              class="col"
              label="Default Billing Type"
              v-model="location.properties.defaultBillingType"
              :items="billingTypes"
            >
            </select-input>
          </div>
          <div class="d-flex justify-content-between">
            <number-input
              name="extraSlides"
              class="col"
              type="number"
              label="Extra Slides"
              pattern="[0-9]"
              keydown.native.69.prevent
              v-model="location.properties.extraSlides"
              :validator="$v.location.properties.extraSlides"
              :validatorMsgMap="validatorMsgMap"
              minValue="0"
            />
            <text-input
              class="col"
              v-model="location.legacyId"
              label="Legacy Location ID"
              name="legacyId"
            />
          </div>
          <div class="d-flex justify-content-between">
            <select-input
              class="col"
              name="cytMultiplePrelims"
              label="Cytology Multiple Prelims"
              v-model="location.properties.cytMultiplePrelims"
              :items="boolDropDown"
            />
            <select-input
              class="col"
              name="cytPrelimAfterPapDiagnosis"
              label="Cytology Prelim After Pap Diagnosis"
              v-model="location.properties.cytPrelimAfterPapDiagnosis"
              :items="boolDropDown"
            />
          </div>
        </div>
      </div>
      <div class="d-flex align-items-end pt-4">
        <div class="col d-flex align-items-end">
          <text-input
            name="printNodeApiKey"
            label="Print Node Api Key"
            class="flex-grow-1"
            v-model="location.properties.printNodeKey"
          />
          <icon-button
            @click="reloadPrinters"
            v-tooltip="'Sync location printers.'"
            icon="sync"
            class="btn text-primary"
            type="button"
            v-if="!refreshing && locationId"
          >
          </icon-button>
          <loader class="align-self-center" size="small" v-else />
        </div>
        <date-picker
          class="col"
          label="Print Start"
          name="printStart"
          v-model="location.properties.printStart"
        />
        <date-picker
          label="Print End"
          name="printEnd"
          class="col"
          v-model="location.properties.printEnd"
          :validator="$v.location.properties.printEnd"
        />
      </div>
      <div class="d-flex justify-content-between align-items-start border-bottom pb-4">
        <number-input
          type="number"
          class="col"
          label="Extra Copies"
          :validator="$v.location.properties.copies"
          v-model="location.properties.copies"
        />
        <number-input
          type="number"
          class="col"
          v-if="cytologyModuleEnabled"
          label="Extra Cytology Copies"
          v-model="location.properties.extraCtytCopies"
        />
        <select-input
          :items="boolDropDown"
          class="col"
          id="printPrelim"
          label="Print Prelim"
          name="printPrelim"
          v-model="location.properties.printPrelim"
        />
        <select-input
          :items="boolDropDown"
          class="col"
          name="printFinal"
          id="printFinal"
          label="Print Final"
          v-model="location.properties.printFinal"
        />
      </div>

      <div class="d-flex justify-content-start" v-if="printerSearch">
        <printers-table
          ref="PrintersTable"
          v-model="location.properties.printerIds"
          :printerSource="printerSearch"
          class="col"
        />
      </div>
      <div class="mt-3 ml-3">
        <Checkbox
          id="isAutoReport"
          name="isAutoReport"
          label="Auto Report"
          :disabled="distributionSelected"
          v-model="location.properties.isAutoReport"
        />
      </div>
      <div class="d-flex mt-1">
        <HL7Table class="col" v-model="location.properties.hL7Configs" />
      </div>
      <div class="d-flex">
        <Emails class="col" v-model="location.properties.emails" />
      </div>
      <div class="d-flex">
        <PDFConfig class="col" v-model="location.properties.pdfFileDrops" />
      </div>
      <div class="d-flex">
        <Phones class="col" v-model="location.properties.phoneNumbers" type="locations" />
      </div>
      <div class="d-flex justify-content-start">
        <Fax class="col" v-model="location.properties.faxMachines" />
      </div>
      <div class="d-flex justify-content-start">
        <CustomFields
          class="col"
          v-model="location.locationCustomFields"
          customFieldType="location"
        />
      </div>
      <div class="d-flex justify-content-end my-2">
        <loader size="small" v-show="isLoading" />
        <button @click="cancel" :disabled="isLoading" type="button" class="mx-2 btn btn-danger">
          Cancel
        </button>
        <button :disabled="isLoading" type="submit" class="btn btn-primary">Save</button>
      </div>
      <Modal :status="isModalLocationAddress" @close="isModalLocationAddress = false">
        <div>
          <div>
            <h3>City</h3>
          </div>
          <DxDataGrid
            class="col-6"
            :data-source="dataSource"
            :columns="columns"
            :selection="{ mode: 'single' }"
            :show-borders="true"
            :hover-state-enabled="true"
            @initialized="initialize"
            key-expr="ID"
            @selection-changed="onSelectionChanged"
            :cacheEnabled="false"
          >
          </DxDataGrid>
        </div>
      </Modal>
    </form>
  </container>
</template>

<script>
import Emails from "@/components/forms/CodeMaintenance/EmailTable";
import Phones from "@/components/forms/CodeMaintenance/PhonesTable";
import CustomFields from "@/components/forms/CodeMaintenance/CustomFieldsTable";
import HL7Table from "@/components/forms/CodeMaintenance/HL7Table.vue";
import Fax from "@/components/forms/CodeMaintenance/FaxTable";
import PDFConfig from "@/components/forms/CodeMaintenance/PDFDropTable";
import { altKey, validatorMsgMapBase } from "@/modules/helpers";
import { maxLength, required, maxValue, minValue } from "vuelidate/lib/validators";
import { DropdownApi, ProvidersApi, PrintersApi } from "@/services";
import shorcutKeys from "@/mixins/shortcutKeys";
import { isAfter, parseISO } from "date-fns";
import { mapState } from "vuex";
import Modal from "@/components/common/Modal";
import ReportsService from "../../../../services/Reports";
import PrintersTable from "../PrintersTable.vue";
import NumberInput from "../../../common/NumberInput.vue";
import { cloneDeep, unset } from "lodash";
import { tap } from "rxjs/operators";
import { createLogComment, createLogItem } from "../../../../modules/helpers";
import auditLog from "../../../../services/AuditLog";
import { DxDataGrid } from "devextreme-vue";
import AddressLookup from "@/components/common/AddressLookup.vue";
import TextInput from "@/components/common/TextInput.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import { isEmpty, uniqBy } from "lodash";
import Container from "@/components/common/Container.vue";
import IconButton from "@/components/common/IconButton.vue";
import Loader from "@/components/common/Loader.vue";
import EmbeddedProvider from "./EmbeddedProvider.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import { handleErrors } from "@/modules/handleErrors";
import Checkbox from "@/components/common/Checkbox.vue";
import { AuditLogItems } from "@/modules/enums";
export default {
  name: "Locations",
  components: {
    Emails,
    Phones,
    Fax,
    CustomFields,
    HL7Table,
    PDFConfig,
    Modal,
    NumberInput,
    PrintersTable,
    DxDataGrid,
    AddressLookup,
    TextInput,
    SelectInput,
    TextAreaInput: () => import("@/components/TextAreaInput.vue"),
    Container,
    IconButton,
    Loader,
    EmbeddedProvider,
    DatePicker,
    Checkbox
  },
  props: {
    locationId: {
      default: null
    },
    isModal: {
      default: false
    }
  },
  subscriptions() {
    const selectedProviders$ = this.$watchAsObservable("selectedProviders").pipe(
      tap(({ newValue, oldValue }) => {
        newValue.forEach(id => {
          const provider = this.location.providers.find(prov => prov.id === id);
          if (!provider) {
            const newId = newValue.find(id => !oldValue.includes(id));
            this.location.providers = [...this.location.providers, { id: newId }];
          } else if (provider && provider.delete) {
            provider.delete = false;
          }
        });
        const providerId = oldValue.find(id => !newValue.includes(id));
        if (providerId) {
          const provider = this.location.providers.find(e => e.id === providerId);
          this.removeProvider(provider);
        }
      })
    );
    return {
      selectedProviders$
    };
  },
  mixins: [shorcutKeys({ keyCode: 65, modifier: "alt" }, "addCustomField")],
  created() {
    this.$store.dispatch("dropdowns/getTimeZones");
    if (this.locationId != null) {
      ProvidersApi.getContactsByLocation(this.locationId).then(res => {
        this.locationContacts = res || [];
      });
      this.isEditing = true;
      ProvidersApi.getLocationById(this.locationId).then(response => {
        response.providers = response.providers.sort((a, b) => (a.name - b.name > 1 ? -1 : 1));
        this.location = {
          ...this.defaultLocation,
          ...response
        };
        this.originalLocation = cloneDeep({
          ...this.defaultLocation,
          ...response
        });
        this.selectedProviders = response.providers.map(e => e.id);
        if (this.location.report) {
          this.selectedPathReport = this.location.report;
        }
        if (response?.properties.printNodeKey) {
          this.printerSearch = ProvidersApi.createPrinterSearch(this.locationId);
        }
      });
    } else {
      this.location.properties.copies = this.DefaultContactExtraCopies || 0;
    }
    DropdownApi.getBillingRates().then(res => {
      this.billingRates = res || [];
    });
  },
  data() {
    return {
      billingRates: [],
      printerSearch: null,
      availablePrinters: [],
      originalLocation: {},
      isModalLocationAddress: false,
      grid: {},
      dataSource: [],
      columns: [
        {
          dataField: "city",
          dataType: "string"
        },
        {
          dataField: "state",
          dataType: "string"
        }
      ],
      boolDropDown: [
        { id: true, displayName: "Yes" },
        { id: false, displayName: "No" }
      ],
      billingTypes: [
        {
          id: 0,
          displayName: "Patient/Insurance"
        },
        {
          id: 1,
          displayName: "Doctor/Client"
        }
      ],
      specimenNumberingOptions: [
        { id: 3, displayName: "Use Lab Setting" },
        { id: 1, displayName: "Numbers" },
        { id: 2, displayName: "Letters" }
      ],
      refreshing: false,
      providers: [],
      selectedPathReport: null,
      selectedProviders: [],
      isEditing: false,
      isLoading: false,
      printers: [],
      defaultLocation: {
        name: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        international: "",
        providers: [],
        locationCustomFields: [],
        pathReportTemplateId: "",
        properties: {
          phoneNumbers: [],
          emails: [],
          faxMachines: [],
          hL7Configs: [],
          reportFormat: "",
          includeICD: false,
          includePatientAddress: false,
          includePatientHistory: false,
          patientNameOrder: 0,
          printStart: new Date(),
          printEnd: "",
          printPrelim: false,
          printFinal: false,
          printers: [],
          printNodeKey: "",
          printerIds: [],
          pdfFileDrops: [],
          billingRateNumber: "",
          defaultBillingType: 0,
          cytMultiplePrelims: null,
          cytPrelimAfterPapDiagnosis: null,
          copies: 0,
          extraSlides: 0,
          routeNumber: 1,
          specimenNumbering: 3,
          comments: "",
          isAutoReport: false
        },
        legacyId: null
      },
      location: {
        name: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        international: "",
        providers: [],
        locationCustomFields: [],
        properties: {
          phoneNumbers: [],
          defaultBillingType: 0,
          pdfFileDrops: [],
          hl7Configs: [],
          emails: [],
          faxMachines: [],
          printerIds: [],
          printNodeKey: "",
          reportFormat: "",
          includeICD: false,
          includePatientAddress: false,
          includePatientHistory: false,
          patientNameOrder: 0,
          printStart: new Date(),
          printEnd: "",
          printPrelim: false,
          printFinal: false,
          printers: [],
          billingRateNumber: "",
          copies: 0,
          extraSlides: 0,
          routeNumber: 1,
          specimenNumbering: 3,
          comments: "",
          timeZoneId: "",
          isAutoReport: false
        },
        legacyId: null
      },
      locationContacts: [],
      saveShortkey: altKey("s")
    };
  },
  watch: {
    selectedPathReport(nv) {
      if (nv) {
        if (nv?.id !== undefined && nv.id !== this.location.pathReportTemplateId) {
          this.location.pathReportTemplateId = nv.id;
        }
      }
    },
    distributionSelected: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.location.properties.isAutoReport = false;
        }
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      currentLab: state => state.currentLab,
      states: state => state.dropdowns.states,
      timeZones: state => state.dropdowns.timeZones,
      cytologyModuleEnabled: state => state.labSettings.CytologyModuleEnabled,
      DefaultContactExtraCopies: state => state.labSettings.DefaultContactExtraCopies
    }),
    validatorMsgMap() {
      return {
        ...validatorMsgMapBase,
        minValue: "Must not be a negative number.",
        notPlaceholder: "This field is required."
      };
    },
    fixedFilters() {
      return ["labId", "=", this.currentLab];
    },
    reportsSearch() {
      return ReportsService.searchStore;
    },
    newProviderNpis() {
      return this.location.providers.filter(e => e.isNew).map(e => e.npi);
    },
    distributionSelected() {
      return (
        this.location.properties.hl7Configs?.length ||
        this.location.properties.emails?.length ||
        this.location.properties.pdfFileDrops?.length ||
        this.location.properties.faxes?.length ||
        this.location.properties.printPrelim ||
        this.location.properties.printFinal
      );
    }
  },
  validations: {
    selectedProviders: {
      required
    },
    location: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      reportFormat: {
        maxLength: maxLength(1000)
      },
      pathReportTemplateId: {
        // required
      },
      address1: {
        required,
        maxLength: maxLength(100)
      },
      address2: {
        maxLength: maxLength(100)
      },
      city: {
        required,
        maxLength: maxLength(100)
      },
      state: {
        required
      },
      zipCode: {
        required,
        maxLength: maxLength(10)
      },
      international: {
        maxLength: maxLength(100)
      },
      providers: {
        required
      },
      properties: {
        timeZoneId: {
          required,
          notPlaceholder: value => value !== 0
        },
        extraSlides: {
          maxValue: maxValue(999),
          minValue: minValue(0)
        },
        reportFormat: {
          maxLength: maxLength(1000)
        },
        copies: {
          maxValue: maxValue(999)
        },
        printEnd: {
          minValue: function (value, vm) {
            if (value && value != undefined && value != null && vm.printStart) {
              return isAfter(parseISO(value), parseISO(vm.printStart));
            }
            return true;
          }
        },
        printers: {
          maxLength: maxLength(1000)
        },
        comments: {
          maxLength: maxLength(1000)
        }
      }
    }
  },
  methods: {
    async reloadPrinters() {
      try {
        this.refreshing = true;
        if (this.location.properties.printNodeKey) {
          await PrintersApi.refreshLocationPrinters({
            locationId: this.locationId,
            apiKey: this.location.properties.printNodeKey
          });
        }
        this.printerSearch = ProvidersApi.createPrinterSearch(this.locationId);
        if (this.$refs.PrintersTable) {
          this.$refs.PrintersTable?.grid?.refresh(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.refreshing = false;
      }
    },
    initialize(event) {
      this.grid = event.component;
    },
    onSelectionChanged({ selectedRowsData }) {
      const data = selectedRowsData[0];
      this.location.city = data.city;
      this.location.state = this.states.filter(e => {
        return e.displayName === data.state;
      })[0].id;
      this.isModalLocationAddress = false;
    },
    async removeProvider(provider) {
      if (provider.isNew || !provider.hasCases) {
        this.location.providers = this.location.providers.filter(e => e.id != provider.id);
      } else {
        window.notify(
          "This provider is linked to one or more Accessions and can not be removed.",
          "error"
        );
      }
      this.selectedProviders = this.location.providers.map(e => e.id);
    },
    handleAddNewProvider(data) {
      this.location.providers = uniqBy([...(this.location.providers || []), data], "id");
      this.selectedProviders = this.location.providers.map(provider => provider.id);
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    cancel() {
      this.location = { ...this.defaultLocation };
      if (this.isModal) {
        this.$emit("cancel");
      } else {
        this.$router.push("/admin/contacts");
      }
    },
    async createNewProviders(providers, location, currentLab) {
      for (const provider of providers) {
        unset(provider, "id");
        if (!isEmpty(provider)) {
          try {
            const response = await ProvidersApi.addProvider(
              {
                ...provider,
                locations: [{ id: location.id, isNew: true }]
              },
              currentLab
            );
            provider.id = response.id;
            provider.isNew = false;
          } catch (error) {
            window.alert(
              `Error creating provider ${provider.lastName}, ${provider.firstName}
              ${error?.response?.data ? error.response.data : ""}`
            );
          }
        }
      }
      return !providers.filter(e => e.isNew)?.length;
    },
    async handleSubmit() {
      this.$v.location.$touch();
      if (this.$v.location.$invalid) {
        window.notify("Please verify your input and try again.", "warning");
        return;
      }
      //Check if any printers are in the array before sending.
      const { properties } = this.location;
      if (properties.printers && !properties.printers.length) {
        this.location.properties.printers = null;
      }
      const providers = this.location.providers.filter(provider => !provider.isNew);
      const newProviders = this.location.providers.filter(provider => provider.isNew);
      let allProvidersCreated = true;
      try {
        this.isLoading = true;
        if (this.isEditing) {
          const changes = createLogComment(
            { ...this.orginalLocation, properties: null },
            { ...this.location, properties: null }
          );
          const propChanges = createLogComment(
            this.originalLocation.properties,
            this.location.properties
          );
          const payload = {
            ...this.location,
            providers: this.location.providers.filter(provider => !provider.isNew),
            labId: this.currentLab
          };
          await ProvidersApi.updateLocation(payload);
          if (newProviders.length) {
            allProvidersCreated = await this.createNewProviders(
              newProviders,
              { ...this.location, providers },
              this.currentLab
            );
          }
          const logItem = createLogItem({}, AuditLogItems.ChangeAdmin);
          logItem.comments = `${this.location.name}:{${changes} \n ${propChanges}}`;
          await auditLog.insertLogMessage(logItem);
        } else {
          const response = await ProvidersApi.addLocation({
            ...this.location,
            providers,
            labId: this.currentLab
          });
          this.location.id = response.id;
          this.isEditing = true;
          this.$v.$reset();
          if (newProviders.length && this.location.id) {
            allProvidersCreated = await this.createNewProviders(
              newProviders,
              { ...this.location, providers },
              this.currentLab
            );
          }
          const logItem = createLogItem({}, 4);
          logItem.comments = `Location added, \n ${response.name}`;
          await auditLog.insertLogMessage(logItem);
        }
        if (allProvidersCreated) {
          if (this.isModal) {
            this.$emit("close");
          } else {
            this.$router.push("/admin/contacts");
          }
        }
      } catch (error) {
        if (error.status === 409) {
          return window.alert(
            "Error deleting a provider, the provider must have an existing case."
          );
        }
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleZipCode(timeZoneId) {
      if (timeZoneId) {
        this.location.properties.timeZoneId = timeZoneId;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
