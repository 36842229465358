<template>
  <div>
    <form v-shortkey="saveShortkey" @shortkey="handleShortkey">
      <div id="main_row" class="form-row">
        <text-input
          v-focus
          v-model="macro.macroName"
          class="col"
          label="Macro Name"
          name="macroName"
          id="macroName"
          :validator="$v.macro.macroName"
          :validatorMsgMap="validatorMsgMap"
          maxLength="101"
          required
        />
        <select-input
          :items="macroTypes"
          v-model="macro.macroType"
          name="macroTypes"
          id="macroTypes"
          required
          :disabled="!!selectedMacroId"
          :validator="$v.macro.macroType"
          :validatorMsgMap="validatorMsgMap"
          class="col"
          label="Type"
        />
        <date-picker
          name="effectiveOn"
          id="effectiveOn"
          v-model="macro.effectiveOn"
          label="Effective On"
          class="col"
          :validator="$v.macro.effectiveOn"
          :validatorMsgMap="validatorMsgMap"
        />
        <DatePicker
          name="expiresOn"
          id="expiresOn"
          v-model="macro.expiresOn"
          label="Expires On"
          class="col"
          :validator="$v.macro.expiresOn"
          :validatorMsgMap="validatorMsgMap"
          :min="macro.effectiveOn"
        />
      </div>
      <div v-if="cytologyModuleEnabled" class="form-row">
        <select-input
          v-model="macro.isGynecological"
          label="Gynecological"
          class="col"
          name="isGynecological"
          id="isGynecological"
          :items="yesNoOptions"
        />
        <select-input
          v-model="macro.isNonGynecological"
          label="Non Gynecological"
          class="col"
          name="isNonGynecological"
          :items="yesNoOptions"
          id="isNonGynecological"
        />
        <select-input
          v-model="macro.isThinPrep"
          label="Thin-Prep"
          :items="yesNoOptions"
          class="col"
          name="isThinPrep"
          id="isThinPrep"
        />
      </div>
      <div class="form-row">
        <text-input v-model="macro.description" label="Description" class="col" />
      </div>
      <div
        class="form-row user-assignment"
        v-if="3 !== this.macro.macroType && [1, 5, 6, 0, 7, 4].includes(this.macro.macroType)"
      >
        <div class="d-flex col flex-column my-2 border container p-3">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="title">{{ userAssignment.label }}</h5>
          </div>
          <DxTagBox
            v-bind="userAssignment"
            :multiple="true"
            :noLabel="true"
            label=""
            :allow-empty="false"
            :searchEnabled="true"
            name="userAssignment"
            id="userAssignement"
            placeholder="Select Users"
            :hide-selected="true"
            v-model="users"
          />
        </div>
      </div>
      <div class="form-row user-assignment">
        <div class="d-flex col flex-column my-2 border container p-3">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="title">Roles</h5>
          </div>
          <DxTagBox
            :multiple="true"
            :noLabel="true"
            :allow-empty="false"
            name="macroRoles"
            displayExpr="displayName"
            valueExpr="id"
            :dataSource="roles"
            id="macroRoles"
            placeholder="Select Roles"
            :hide-selected="true"
            v-model="macro.macroRoles"
          />
        </div>
      </div>
      <div class="form-row align-items-start my-4">
        <CPTCodes
          v-model="macro.cptCodes"
          label="CPT Codes"
          name="cptCodes"
          id="cptCodes"
          class="col"
          trackBy="id"
          placeholder="Select Options"
          :close-on-select="false"
          :hide-selected="true"
          v-if="fieldMap.includes('cptCodes')"
        />
        <ICDCodes
          v-model="macro.icdCodes"
          class="col"
          trackBy="id"
          name="macroIcdCodes"
          id="macroIcdCodes"
          :internal-search="false"
          :hide-selected="true"
          placeholder="Search Options"
          v-if="fieldMap.includes('icdCodes')"
          :close-on-select="false"
          :checkModified="false"
        />
        <hold-codes
          name="holdCodes"
          id="holdCodes"
          v-model="macro.holdCodes"
          v-if="fieldMap.includes('holdCodes')"
          :required="fieldMap.includes('holdCodes')"
          class="col"
          placeholder="Select Options"
        />
        <SelectInput
          class="col"
          label="Diagnosis Summary"
          name="diagnosisSummaryId"
          id="diagnosisSummaryId"
          v-model="macro.diagnosisSummaryId"
          :items="diagnosisSummaries"
          v-if="fieldMap.includes('diagnosisSummaryId')"
        />
        <select-input
          class="col"
          label="Use Trailing Space"
          name="useTrailingSpace"
          id="useTrailingSpace"
          v-model="macro.useTrailingSpace"
          :items="booleanOptions"
          v-if="fieldMap.includes('useTrailingSpace')"
        />
        <select-input
          class="col"
          label="Insert With Formatting"
          name="insertWithFormatting"
          id="insertWithFormatting"
          v-model="macro.insertWithFormatting"
          :items="booleanOptions"
          v-if="fieldMap.includes('insertWithFormatting')"
        />
        <select-input
          class="col"
          label="Specimen Type"
          name="specimenTypeId"
          id="specimenTypeId"
          v-model="macro.specimenTypeId"
          :items="specimenTypeOptions"
          v-if="fieldMap.includes('specimenTypeId') && showSpecimenTypeSelector"
          :validator="$v.macro.specimenTypeId"
          :validatorMsgMap="validatorMsgMap"
        />
      </div>
      <general-macro-enabled-editor
        v-model="macro.generalText"
        v-if="fieldMap.includes('generalText')"
        name="GeneralText"
        label="General Text"
        :validator="$v.macro.generalText"
        :validatorMsgMap="validatorMsgMap"
        @toggleSpellChecker="toggleSpellChecker"
      />
      <general-macro-enabled-editor
        v-model="macro.diagnosis"
        v-if="fieldMap.includes('diagnosis')"
        name="Diagnosis"
        @toggleSpellChecker="toggleSpellChecker"
      />
      <general-macro-enabled-editor
        v-model="macro.specimenNotes"
        v-if="fieldMap.includes('specimenNotes')"
        name="SpecimenNotes"
        label="Specimen Notes"
        @toggleSpellChecker="toggleSpellChecker"
      />
      <general-macro-enabled-editor
        v-model="macro.microscopic"
        v-if="fieldMap.includes('microscopic')"
        name="Microscopic"
        @toggleSpellChecker="toggleSpellChecker"
      />
      <general-macro-enabled-editor
        v-if="fieldMap.includes('caseNotes')"
        v-model="macro.caseNotes"
        name="CaseNotes"
        label="Case Notes"
        @toggleSpellChecker="toggleSpellChecker"
      />
      <general-macro-enabled-editor
        v-model="macro.gross"
        v-if="fieldMap.includes('gross')"
        name="Gross"
        @toggleSpellChecker="toggleSpellChecker"
      />
      <Checkbox
        v-if="!UsesSpecimenTypes && fieldMap.includes('isProstate')"
        label="Prostate"
        v-model="macro.isProstate"
        id="isProstate"
        name="isProstate"
      />
      <div class="form-row">
        <prefix
          v-model="macro.prefixes"
          v-if="fieldMap.includes('prefixes')"
          :required="fieldMap.includes('prefixes')"
          class="col"
          :multiple="true"
        />
      </div>
      <div class="form-row">
        <orders
          :required="fieldMap.includes('procedures')"
          v-if="fieldMap.includes('procedures')"
          v-model="macro.procedures"
          class="col"
        />
        <div
          class="col d-flex mt-1"
          v-if="fieldMap.includes('slideLabelBin') && fieldMap.includes('cassetteLabelBin')"
        >
          <NumberInput
            class="col"
            v-if="fieldMap.includes('defaultBlockNumber')"
            name="defaultBlockNumber"
            id="defaultBlockNumber"
            v-model="macro.defaultBlockNumber"
            label="Default Block Number"
            :min="1"
          />
          <TextInput
            class="col"
            label="Slide Tray"
            v-model="macro.defaultSlideBin"
            name="defaultSlideBin"
            id="defaultSlideBin"
            maxLength="15"
          />
          <SelectInput
            class="col"
            label="Default Bin Map"
            v-model="macro.binMapId"
            name="binMapId"
            :items="labBinMaps"
            displayExpr="name"
            v-if="fieldMap.includes('binMapId')"
            id="binMapId"
            maxLength="15"
          />
        </div>
      </div>
      <div class="form-row">
        <ImageUpload
          v-if="fieldMap.includes('images')"
          v-model="macro.images"
          class="col"
          name="images"
          id="images"
          label="Images"
        >
          <template v-slot:images="{ images, remove }">
            <div v-for="(image, index) in images" :key="index">
              <div class="macro-image mx-2" v-if="!image.isDeleted">
                <icon-button
                  icon="trash-alt"
                  @click="remove(index)"
                  type="button"
                  class="removeImg btn text-danger"
                />
                <img
                  v-if="typeof image.image === 'string' || Boolean(image.data)"
                  width="100"
                  height="100"
                  :src="displayImage(image)"
                  alt
                  :key="image.data"
                />
                <SelectInput
                  :name="'imageTypeId' + index"
                  :id="'imageTypeId' + index"
                  :items="imageTypes"
                  v-model="macro.images[index].imageTypeId"
                />
              </div>
            </div>
          </template>
        </ImageUpload>
      </div>
      <div class="form-row">
        <tags
          :multiple="true"
          :required="fieldMap.includes('tags')"
          v-if="fieldMap.includes('tags')"
          v-model="macro.tags"
          class="col"
          label="Tags"
          trackBy="id"
        >
        </tags>
      </div>
      <div class="form-row">
        <div class="col" v-if="fieldMap.includes('interfaceDiagnosis')">
          <select-input
            name="interfaceDiagnosis"
            id="interfaceDiagnosis"
            :dataSource="interfaceDiagnosesDataSource"
            valueExpr="displayName"
            v-model="macro.interfaceDiagnosis"
            label="Interface Diagnosis"
            searchMode="startswith"
          />
          <p v-if="macro.macroType === 0 && nonexistentInterfaceDiagnosis" class="text-danger">
            Previous interface diagnosis: {{ nonexistentInterfaceDiagnosis }}
          </p>
        </div>
        <TextInput
          v-if="fieldMap.includes('legacyDiagnosisCode')"
          class="col"
          label="Legacy Diagnosis Code"
          v-model="macro.legacyDiagnosisCode"
        />
      </div>
      <div class="form-row justify-content-end my-2">
        <loader size="small" v-show="isLoading" />
        <button
          v-if="macro.macroId && permissions.MacroDelete"
          class="btn btn-danger"
          @click.prevent="deleteMacro"
        >
          Delete
        </button>
        <button
          @click.stop="clear"
          :disabled="isLoading"
          type="button"
          class="btn btn-outline-danger mx-2"
        >
          Cancel
        </button>
        <button :disabled="isLoading" class="btn btn-primary" v-stream:click.prevent="saveClick$">
          Save
        </button>
      </div>
    </form>
    <modal :status="isMacroPopupOpen" @close="closeMacroPopup">
      <general-macro-popup
        @close="isMacroPopupOpen = false"
        @macroSelected="macroDialogCallback"
        :dialogFromWysiwyg="macroFromWysiwyg"
      />
    </modal>
  </div>
</template>
<script>
import ImageUpload from "@/components/common/ImageUpload.vue";
import dropDownApi from "@/services/dropdown";
import {
  scrollToElement,
  altKey,
  booleanLookup,
  oneYearAgo,
  validatorMsgMapBase,
  sanitizeHTML
} from "@/modules/helpers";
import { required, maxLength, helpers } from "vuelidate/lib/validators";
import ImagesAPI from "@/services/images";
import { mapState, mapGetters } from "vuex";
import { ICDCodes, CPTCodes } from "../Selectors";
import { afterEffective } from "@/modules/helpers";
import Tags from "../Selectors/Tags.vue";
import Orders from "../Selectors/Orders.vue";
import Prefix from "../Selectors/Prefix.vue";
import HoldCodes from "../Selectors/HoldCodes.vue";
import DxTagBox from "devextreme-vue/tag-box";
import { uniqBy } from "lodash";
import { createLogComment, createLogItem, toLetters, fromLetters } from "../../../modules/helpers";
import { cloneDeep } from "lodash";
import { DropdownApi, UsersApi, AuditLogApi, MacrosApi, PrintersApi } from "@/services/index";
import TextInput from "@/components/common/TextInput.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import Loader from "@/components/common/Loader.vue";
import NumberInput from "@/components/common/NumberInput.vue";
import { MacroTypeEnum, SpecimenNumbersEnum } from "@/modules/enums";
import DataSource from "devextreme/data/data_source";
import {
  fromBusEvent,
  OPEN_GENERAL_MACRO_POPUP,
  RESTORE_GENERAL_EDITOR_POSITION,
  SPELL_CHECK_ACTION
} from "@/modules/eventBus";
import { switchMap, map, filter, exhaustMap, takeWhile, tap, take } from "rxjs/operators";
import elementObserver from "@/modules/elementObserver";
import { interval, merge } from "rxjs";
import { handleErrors } from "@/modules/handleErrors";
import GeneralMacroEnabledEditor from "@/components/common/GeneralMacroEnabledEditor.vue";
import Modal from "@/components/common/Modal.vue";
import GeneralMacroPopup from "@/components/GeneralMacroPopup.vue";
import ArrayStore from "devextreme/data/array_store";
import Checkbox from "@/components/common/Checkbox.vue";

function firstCharNotNumber(value) {
  // If lab settings segregate result macros, a result macro can begin with a number.
  if (
    this.SegregateResultsMacros &&
    ![MacroTypeEnum.General, MacroTypeEnum.Panel].includes(this.macro.macroType)
  ) {
    return true;
  }
  if (value && value !== undefined) {
    return !/[0-9]/i.test(value[0]);
  }
  return true;
}
export default {
  name: "MacroConfigForm",
  props: {
    selectedMacroId: {
      type: Number
    },
    isClone: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  components: {
    ImageUpload,
    ICDCodes,
    CPTCodes,
    Tags,
    Orders,
    Prefix,
    DxTagBox,
    HoldCodes,
    TextInput,
    SelectInput,
    DatePicker,
    Loader,
    NumberInput,
    GeneralMacroEnabledEditor,
    Modal,
    GeneralMacroPopup,
    Checkbox
  },
  validations() {
    return {
      macro: {
        macroName: {
          required,
          firstCharNotNumber,
          maxLength: maxLength(100),
          alphaNum: helpers.regex("alphaNum", /[a-z\-0-9*]/i),
          macroHasPeriod: value => !/\./.test(value)
        },
        macroType: {
          required
        },
        expiresOn: {
          afterEffective
        },
        effectiveOn: {
          required
        },
        specimenTypeId: {
          required: vm => {
            return this.showSpecimenTypeSelector ? helpers.req(vm) : true;
          }
        }
      }
    };
  },
  data() {
    return {
      isLoading: false,
      booleanOptions: booleanLookup.dataSource,
      yesNoOptions: [
        { id: true, displayName: "Yes" },
        { id: false, displayName: "No" }
      ],
      macro: {
        macroName: "",
        macroType: null,
        effectiveOn: oneYearAgo(),
        expiresOn: null,
        diagnosisSummaryId: null,
        binMapId: null,
        diagnosis: "",
        microscopic: "",
        specimenNotes: "",
        caseNotes: "",
        gross: "",
        cptCodes: [],
        icdCodes: [],
        holdCodes: [],
        useTrailingSpace: false,
        prefixes: [],
        procedures: [],
        insertWithFormatting: false,
        images: [],
        tags: [],
        users: [],
        macroRoles: [],
        interfaceDiagnosis: "",
        generalText: "",
        defaultBlockNumber: 1,
        defaultCassBin: "",
        defaultSlideBin: "",
        description: "",
        legacyDiagnosisCode: "",
        isProstate: false,
        specimenTypeId: null
      },
      currentValues: {},
      macroTypes: [],
      imageTypes: [],
      labBinMaps: [],
      diagnosisSummaries: [],
      originalMacro: {},
      isMacroPopupOpen: false,
      macroFromWysiwyg: false,
      saveShortkey: altKey("s"),
      isSpellChecking: false,
      nonexistentInterfaceDiagnosis: "",
      macroHasBeenUsed: false,
      specimenTypeOptions: [],
      hasCheckedInterfaceDiagnosis: false
    };
  },
  created() {
    ImagesAPI.getImageTypes().then(res => {
      this.imageTypes = res || [];
    });
    PrintersApi.getLabBinMaps().then(res => {
      this.labBinMaps = res || [];
    });
    dropDownApi.getMacroTypes().then(res => {
      this.macroTypes = res || [];
    });
    dropDownApi.getDiagnosisSummaries(this.currentLab).then(res => {
      this.diagnosisSummaries = res || [];
    });
    if (!this.interfaceDiagnoses?.length) {
      this.$store.dispatch("dropdowns/getInterfaceDiagnoses");
    }
    if (this.UsesSpecimenTypes) {
      dropDownApi.getSpecimenTypes().then(res => {
        this.specimenTypeOptions = res;
      });
    }
    if (this.preloadedData) {
      this.macro = { ...this.macro, ...this.preloadedData };
      if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
        this.macro.defaultBlockNum = toLetters(this.macro.defaultBlockNum);
      }
    } else if (this.selectedMacroId) {
      this.isLoading = true;
      MacrosApi.getMacroDetails(this.selectedMacroId).then(macro => {
        sanitizeHTML(macro, this.labSettings);
        if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
          macro.defaultBlockNum = toLetters(macro.defaultBlockNum);
        }
        if (this.isClone) {
          this.macro = { ...cloneDeep(macro), macroId: null, macroName: "" };
        } else {
          this.originalMacro = cloneDeep(macro);
          Object.keys(macro).forEach(key => {
            if (macro[key] != undefined || macro[key] != null) {
              this.macro[key] = macro[key];
              if (Array.isArray(macro[key])) {
                this.currentValues[key] = macro[key];
              }
            }
          });
        }
        this.isLoading = false;
      });
    }
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    ...mapState({
      SpellCheckOnSave: state => state.labSettings.SpellCheckOnSave,
      SegregateResultsMacros: state => state.labSettings.SegregateResultsMacros,
      UsesSpecimenTypes: state => state.labSettings.UsesSpecimenTypes,
      cytologyModuleEnabled: state => state.labSettings.CytologyModuleEnabled,
      labSettings: state => state.labSettings,
      interfaceDiagnoses: state => state.dropdowns.interfaceDiagnoses
    }),
    ...mapGetters("accessionStore", ["primaryPathologist", "specimenNumbering"]),
    ...mapGetters(["permissions"]),
    isEditing() {
      return Boolean(this.selectedMacroId) && !this.isClone;
    },
    macroRoles: {
      get() {
        return this.macro.macroRoles.map(role => role.id);
      },
      set(value) {
        this.macro.macroRoles = value.map(e => ({ id: e }));
        return value;
      }
    },
    validatorMsgMap() {
      return {
        ...validatorMsgMapBase,
        firstCharNotNumber: "First character must not be a number."
      };
    },
    userSource() {
      return UsersApi.searchStore;
    },
    pathologists() {
      return DropdownApi.searchPathologists;
    },
    users: {
      get() {
        return this.macro?.users?.map(user => {
          return user.id;
        });
      },
      set(value) {
        if ([1, 5, 6, 0, 7, 4].includes(this.macro.macroType)) {
          this.macro.users = value.map(userId => {
            return { id: userId };
          });
        }
        return value;
      }
    },
    userAssignment() {
      if ([5, 6, 0, 7].includes(this.macro.macroType)) {
        return {
          label: "Pathologist Assignment",
          dataSource: this.pathologists,
          "value-expr": "userId",
          "display-expr": "displayName",
          "search-expr": "displayName"
        };
      }
      return {
        label: "User Assignment",
        dataSource: this.userSource,
        "value-expr": "id",
        "display-expr"(user) {
          if (user) {
            if (user.firstName && user.lastName) {
              return `${user?.lastName}, ${user?.firstName}`;
            }
            return user.username;
          }
          return "";
        },
        "search-expr": ["username", "lastName", "firstName"]
      };
    },
    defaultMacro() {
      return {
        macroName: "",
        macroType: null,
        effectiveOn: oneYearAgo(),
        expiresOn: null,
        users: [],
        diagnosisSummaryId: null,
        diagnosis: "",
        interfaceDiagnosis: "",
        microscopic: "",
        specimenNotes: "",
        gross: "",
        generalText: "",
        caseNotes: "",
        cptCodes: [],
        icdCodes: [],
        holdCodes: [],
        prefixes: [],
        procedures: [],
        images: [],
        tags: [],

        defaultCassBin: "",
        defaultSlideBin: "",
        defaultBlockNum: 0,
        binMapId: null,
        legacyDiagnosisCode: "",
        isProstate: false,
        specimenTypeId: null
      };
    },
    //These three properties are reactive to changes in the macro type.
    typeDisplay() {
      const type = this.macroTypes?.find(e => e.id === this.macro.macroType);
      return type?.displayName ? type.displayName : null;
    },
    fieldMap() {
      return this.typeDisplay ? this.map[this.typeDisplay] : [];
    },
    //This array is used to conditionally render the fields available based on the macro type.
    map() {
      return {
        Results: [
          "users",
          "diagnosisSummaryId",
          "diagnosis",
          "interfaceDiagnosis",
          "microscopic",
          "specimenNotes",
          "caseNotes",
          "cptCodes",
          "holdCodes",
          "icdCodes",
          "gross",
          "description",
          "legacyDiagnosisCode",
          "specimenTypeId",
          "isThinPrep",
          "isNonGynecological",
          "isGynecological"
        ],
        Panel: [
          "users",
          "procedures",
          "description",
          "isThinPrep",
          "isNonGynecological",
          "isGynecological"
        ],
        Protocol: [
          "users",
          "gross",
          "procedures",
          "images",
          "prefixes",
          "slideLabelBin",
          "cassetteLabelBin",
          "defaultCassBin",
          "defaultSlideBin",
          "binMapId",
          "description",
          "isProstate",
          "specimenTypeId",
          "isThinPrep",
          "isNonGynecological",
          "isGynecological"
        ],
        General: [
          "users",
          "generalText",
          "cptCodes",
          "holdCodes",
          "icdCodes",
          "procedures",
          "images",
          "tags",
          "useTrailingSpace",
          "insertWithFormatting",
          "description",
          "isThinPrep",
          "isNonGynecological",
          "isGynecological"
        ]
      };
    },
    roles() {
      return new DataSource({
        store: DropdownApi.searchRoles,
        filter: ["userTypeId", "=", 30],
        sort: ["displayName"]
      });
    },
    interfaceDiagnosesDataSource() {
      return new DataSource({
        store: new ArrayStore({ data: this.interfaceDiagnoses })
      });
    },
    showSpecimenTypeSelector() {
      return (
        this.UsesSpecimenTypes &&
        [MacroTypeEnum.Protocol, MacroTypeEnum.Results].includes(this.macro.macroType)
      );
    }
  },
  watch: {
    "macro.macroType"(nv) {
      if ([5, 6, 0, 7].includes(nv) && !this.isEditing && !this.isClone) {
        this.users = [];
      }
    },
    "macro.interfaceDiagnosis"(nv, ov) {
      if (
        this.macro.macroType === MacroTypeEnum.Results &&
        nv !== ov &&
        this.interfaceDiagnoses?.length &&
        !this.hasCheckedInterfaceDiagnosis
      ) {
        this.checkInterfaceDiagnosis(nv);
      }
    },
    interfaceDiagnoses(nv) {
      if (nv?.length && this.macro.interfaceDiagnosis && !this.hasCheckedInterfaceDiagnosis) {
        this.checkInterfaceDiagnosis(this.macro.interfaceDiagnosis);
      }
    },
    "macro.specimenTypeId"(nv) {
      if (this.UsesSpecimenTypes && nv) {
        const { settingType } = this.specimenTypeOptions.find(e => e.id === nv);
        if (settingType?.toLowerCase() === "p") {
          this.macro.isProstate = true;
          return;
        }
      }
      this.macro.isProstate = false;
    }
  },
  methods: {
    removeOption({ option, name, remove }) {
      //With multi select we must handle delete differently.
      if (this.isEditing) {
        const index = this.macro[name].map(e => e.id).indexOf(option.id);
        //This includes finding the index of an property which we click
        if (index > -1) {
          //We then set the isDeleted boolean on the property to true.
          this.macro[name][index].isDeleted = true;
        }
      }
      return remove(option);
    },
    displayImage({ image, data }) {
      if (typeof image === "string") {
        return "data:image/jpeg;base64," + image;
      }
      return data;
    },
    async clear() {
      this.$emit("clear");
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");
        return;
      }
      this.$v.$reset();
      if (this.SpellCheckOnSave) {
        await this.checkEditorSpelling();
      }
      if (
        !this.macro?.macroId &&
        this.macro.macroType === MacroTypeEnum.Results &&
        this.currentUser.isPathologist &&
        !this.macro.users.length
      ) {
        const confirm = await window.confirm(
          "This macro has not been assigned to any pathologists. Would you like to assign it to the current user?"
        );
        if (confirm) {
          this.macro.users.push({ id: this.currentUser.id });
        }
      }
      const macroObject = {
        macroId: this.macro.macroId,
        expiresOn: this.macro.expiresOn,
        effectiveOn: this.macro.effectiveOn,
        macroName: this.macro.macroName.trim(),
        macroType: this.macro.macroType,
        labId: this.currentLab
      };
      const originalMacro = {
        macroId: this.originalMacro.macroId,
        expiresOn: this.originalMacro.expiresOn,
        effectiveOn: this.originalMacro.effectiveOn,
        macroName: this.originalMacro.macroName,
        macroType: this.originalMacro.macroType,
        labId: this.currentLab
      };
      sanitizeHTML(this.macro, this.labSettings);
      this.fieldMap.forEach(field => {
        macroObject[field] = this.macro[field];
        originalMacro[field] = this.originalMacro[field];
        if (this.isEditing && Array.isArray(this.macro[field])) {
          const originalValues = this.currentValues[field];
          const newIds = this.macro[field].map(e => e.id);
          macroObject[field] = [...(originalValues || []), ...this.macro[field]].map(e => {
            if (!newIds.includes(e.id)) {
              e.isDeleted = true;
            } else {
              e.isDeleted = false;
            }
            return e;
          });
          originalMacro[field] = this.originalMacro[field];
          macroObject[field] = uniqBy(macroObject[field], "id");
        }
      });
      try {
        this.isLoading = true;
        if (macroObject?.images?.length) {
          for (let i = 0; i < macroObject.images.length; i++) {
            const { image, isDeleted, imageTypeId } = macroObject.images[i];
            if (!isDeleted && typeof image != "string") {
              const formData = new FormData();
              formData.append("image", image);
              formData.append("imageTypeId", imageTypeId);
              const id = await ImagesAPI.addImage(formData);
              macroObject.images[i] = { id, isDeleted, imageTypeId };
            }
          }
        }
      } catch (error) {
        window.notify("Error saving macro images", "error");
      } finally {
        this.isLoading = false;
      }
      if (this.macro.macroType === MacroTypeEnum.Protocol) {
        this.macro.users = [];
      }

      try {
        this.isLoading = true;
        if (this.isEditing) {
          this.macro = await MacrosApi.editMacro({
            ...macroObject,
            labId: this.currentLab,
            macroRoles: this.macro.macroRoles || []
          });
          const logItem = createLogItem({}, 5);
          logItem.comments = `${this.macro.macroName}:${createLogComment(
            originalMacro,
            macroObject
          )}`;
          await AuditLogApi.insertLogMessage(logItem);
        } else if (!macroObject.macroId) {
          delete macroObject.macroId;
          this.macro = await MacrosApi.addMacro({
            ...macroObject,
            labId: this.currentLab,
            macroRoles: this.macro.macroRoles || []
          });
          const logItem = createLogItem({}, 4);
          logItem.comments = `Created ${macroObject.macroName}.`;
          await AuditLogApi.insertLogMessage(logItem);
        }
        if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
          this.macro.defaultBlockNum = fromLetters(this.macro.defaultBlockNum);
        }

        this.$emit("submit", this.macro);
        this.macro = { ...this.defaultMacro };
      } catch (error) {
        if (error.response?.data?.message && /name/i.test(error.response.data.message)) {
          window.notify(error.response.data.message, "error");
        } else handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    async checkEditorSpelling() {
      const spellcheck$ = fromBusEvent(SPELL_CHECK_ACTION).pipe(
        filter(({ data }) => data.type === "spelling"),
        switchMap(({ instance }) => {
          return interval(500).pipe(map(() => instance.getProblemsCount()));
        })
      );
      const closeDialog$ = interval(500).pipe(
        map(() => {
          const dialogElement = document.querySelector(
            "div.wsc-dialog.wsc-theme-custom.wsc-element.wsc--border-box:not(.wsc--hidden)"
          );
          return dialogElement;
        }),
        filter(e => e != null),
        exhaustMap(wscDialogEl => elementObserver(wscDialogEl)),
        map(([mutations]) => {
          if (Array.isArray(mutations)) {
            for (const mutation of mutations) {
              const { target } = mutation;
              if (target.classList.contains("wsc--hidden")) {
                return 0;
              }
            }
          }
          return 1;
        })
      );
      if (typeof window.WEBSPELLCHECKER !== "undefined" && window.WEBSPELLCHECKER?.init) {
        const spellCheckers = window.WEBSPELLCHECKER.getInstances();
        for (const spellChecker of spellCheckers) {
          if (spellChecker.getProblemsCount && spellChecker.getProblemsCount()) {
            const instanceNode = spellChecker.getContainerNode();
            let editorRef = this.$refs[instanceNode.id];
            if (Array.isArray(editorRef)) {
              editorRef = editorRef[0];
              if (editorRef.expand) {
                editorRef.expand();
              }
            }
            await this.$nextTick();
            scrollToElement(instanceNode);
            spellChecker.openDialog();
            await merge(closeDialog$, spellcheck$)
              .pipe(takeWhile(val => val > 0))
              .toPromise();
            if (editorRef?.collapse) {
              editorRef.collapse();
              await this.$nextTick();
            }
          }
        }
        const dialog = document.querySelector(
          ".wsc-dialog.wsc-element.wsc--border-box:not(.wsc--hidden)"
        );
        if (dialog !== null) {
          dialog.classList.toggle("wsc--hidden");
        }
      }
    },
    closeMacroPopup() {
      this.isMacroPopupOpen = false;
    },
    async macroDialogCallback(macros) {
      this.isMacroDialogOpen = false;
      this.$nextTick(() => {
        this.openEditors();
        this.$store.dispatch("accessionStore/useMacroOnCurrentSpecimen", { macros });
      });
    },
    toggleSpellChecker(value) {
      this.isSpellChecking = value;
    },
    handleShortkey() {
      this.isLoading = true;
      this.saveClick$.next();
    },
    checkInterfaceDiagnosis(diagnosis) {
      this.hasCheckedInterfaceDiagnosis = true;
      if (diagnosis?.length) {
        const interfaceDiagnosisOption = this.interfaceDiagnoses.find(
          e => e.displayName.toLowerCase().trim() === diagnosis.toLowerCase().trim()
        );
        if (interfaceDiagnosisOption) {
          this.macro.interfaceDiagnosis = interfaceDiagnosisOption.displayName;
          this.nonexistentInterfaceDiagnosis = "";
          return;
        } else {
          this.nonexistentInterfaceDiagnosis = diagnosis;
          window.alert(
            `Interface Diagnosis "${diagnosis}" is not recognized as an EMA interface diagnosis. Please select a diagnosis from the dropdown.`
          );
          return;
        }
      }
      this.nonexistentInterfaceDiagnosis = "";
      this.macro.interfaceDiagnosis = diagnosis;
    },
    async deleteMacro() {
      // IP-79: Check to see if macro has been used
      if (this.macroHasBeenUsed) {
        window.alert(
          "This macro is currently being used and cannot be deleted. Please use the Expires On functionality if you wish to remove it."
        );
        return;
      }
      const confirmDelete = await window.confirm(
        "Are you sure you want to delete this macro? This action cannot be undone."
      );
      if (!confirmDelete) {
        return;
      }
      const response = await MacrosApi.deleteMacros({
        macroIds: [this.macro.macroId],
        labId: this.currentLab
      });
      if (response[0]?.wasDeleted) {
        window.alert("Macro deleted.");
        this.$emit("delete");
      } else {
        let error = "";
        if (response[0].reason.includes("following table")) {
          error = "Error: This macro has been used on a case and cannot be deleted.";
        } else {
          error = `Error deleting macro: ${response[0].reason}`;
        }
        window.alert(error);
      }
    }
  },
  domStreams: ["saveClick$"],
  subscriptions() {
    const openMacroFromWysiwyg$ = fromBusEvent(OPEN_GENERAL_MACRO_POPUP).pipe(
      tap(() => {
        this.isMacroPopupOpen = true;
        this.macroFromWysiwyg = true;
      }),
      switchMap(({ callback }) => {
        return fromBusEvent(RESTORE_GENERAL_EDITOR_POSITION).pipe(
          tap(macros => {
            this.macroFromWysiwyg = false;
            this.isMacroPopupOpen = false;
            callback(macros);
          })
        );
      })
    );
    const spellCheckStatus$ = this.$watchAsObservable("isSpellChecking", { immediate: true }).pipe(
      filter(({ newValue }) => !newValue),
      take(1)
    );
    const saveAndSpellCheckFinish$ = this.saveClick$.pipe(
      switchMap(() => spellCheckStatus$),
      // Run save function when both are done
      tap(() => this.handleSubmit())
    );

    return { openMacroFromWysiwyg$, saveAndSpellCheckFinish$ };
  }
};
</script>

<style lang="scss" scoped>
.removeImg {
  position: absolute;
  top: -25px;
  right: -10px;
}
.macro-image {
  background-repeat: no-repeat;
  position: relative;
}

.multi-select {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
}

.custom__multitag {
  border: white solid 1px;
}

.multi-btn {
  padding: 0px;
  margin: 0px;
  margin-left: 5px;
}
.case-panel {
  .user-assignment {
    display: none;
  }
}
</style>
