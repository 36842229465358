<template>
  <div>
    <div class="d-flex justify-content-between my-2">
      <h3>Faxes</h3>
      <AddBtn
        type="button"
        @click="triggerIsAdding"
        :text="isAdding ? 'Close' : 'Add'"
        :icon="isAdding ? 'times' : 'plus'"
      />
    </div>
    <div v-if="value && value.length">
      <PropTable
        v-model="faxes"
        :columns="columns"
        @edit="editFax"
        @input="$emit('input', $event)"
      />
    </div>
    <form v-if="isAdding || isEditing" @submit.prevent="addFax" class="col-8">
      <div class="row">
        <div class="col-1 my-auto mr-2">
          <button @click="setPrimary" type="button" class="btn actions icon">
            <template v-if="fax.primary">
              <icon class="text-primary" icon="parking" />
            </template>
            <template v-else>
              <span class="secondary-icon">S</span>
            </template>
          </button>
        </div>
        <PhoneInput
          name="fax number"
          class="col"
          mask="(999)000-0000"
          label="Fax Number"
          maxLength="16"
          v-model="fax.phoneNumber"
          :validator="$v.fax.phoneNumber"
        />
        <TextInput
          name="recipient"
          class="col"
          maxLength="101"
          label="Recipient"
          v-model="fax.recipient"
          :validator="$v.fax.recipient"
        />
      </div>
      <div class="row d-flex justify-content-center align-items-center">
        <div class="d-flex flex-column justify-content-start">
          <Checkbox label="Final" id="faxFinal" v-model="fax.final" />
          <Checkbox label="Prelim" class="" id="faxPrelim" v-model="fax.prelim" />
          <Checkbox label="Weekend" id="faxWeekend" v-model="fax.weekend" />
          <Checkbox label="Cytology Final" id="cytFinal" v-model="fax.cytFinal" />
          <Checkbox label="Cytology Prelim" id="cytPrelim" v-model="fax.cytPrelim" />
        </div>
        <div class="d-flex flex-column mx-2 col-4">
          <Timepicker
            name="start-time"
            label="Start Time"
            v-model="fax.startTime"
            :validator="$v.fax.recipient"
            placeholder="Start Time"
          />
          <Timepicker
            name="end-time"
            label="End Time"
            v-model="fax.endTime"
            placeholder="End Time"
            :min="fax.startTime"
            :validator="$v.fax.endTime"
          />
        </div>
      </div>

      <div class="row justify-content-end">
        <button @click="cancelEdit" type="button" class="my-2 mr-1 btn btn-danger">Cancel</button>
        <button type="submit" class="my-2 btn btn-primary">
          {{ isEditing ? "Save" : "Submit" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Timepicker from "@/components/common/TimePicker";
import { required, maxLength } from "vuelidate/lib/validators";
import PhoneInput from "@/components/common/PhoneInput";
import PropTable from "@/components/common/PropTable.vue";
import Icon from "@/components/common/Icon.vue";
import moment from "moment-timezone";
import AddBtn from "@/components/common/AddButton.vue";
import TextInput from "@/components/common/TextInput.vue";
import Checkbox from "@/components/common/Checkbox.vue";
import { mapState } from "vuex";
import { formatPhoneNumber } from "@/modules/helpers";

export default {
  name: "faxs",
  components: {
    PhoneInput,
    Timepicker,
    PropTable,
    Icon,
    AddBtn,
    TextInput,
    Checkbox
  },
  props: {
    value: {
      required: true
    },
    timezone: {
      default: Intl.DateTimeFormat().resolvedOptions().timeZone,
      type: String
    }
  },
  data() {
    return {
      isEditing: false,
      isAdding: false,
      defaultfax: {
        prelim: false,
        final: false,
        cytFinal: false,
        cytPrelim: false,
        phoneNumber: "",
        primary: false,
        recipient: "",
        startTime: null,
        endTime: null,
        weekend: false
      },
      fax: {
        prelim: false,
        final: false,
        cytFinal: false,
        cytPrelim: false,
        phoneNumber: "",
        primary: false,
        recipient: "",
        startTime: null,
        endTime: null,
        weekend: false
      }
    };
  },
  computed: {
    ...mapState({ labSettings: state => state.labSettings }),
    faxes: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    },
    columns() {
      return [
        { dataField: "prelim" },
        { dataField: "final" },
        {
          dataField: "cytFinal",
          dataType: "boolean"
        },
        {
          dataField: "cytPrelim",
          dataType: "boolean"
        },
        {
          dataField: "phoneNumber",
          calculateCellValue(data) {
            if (data?.phoneNumber) {
              return formatPhoneNumber(data.phoneNumber);
            }
          }
        },
        {
          dataField: "primary",
          dataType: "boolean"
        },
        { dataField: "recipient" },
        {
          dataField: "startTime",
          dataTypr: "string",
          calculateDisplayValue: data => {
            if (data.startTime) {
              return this.utcformat(data.startTime);
            }
            return "";
          }
        },
        {
          dataField: "endTime",
          dataTypr: "string",
          calculateDisplayValue: data => {
            if (data.endTime) {
              return this.utcformat(data.endTime);
            }
            return "";
          }
        },
        { dataField: "weekend", dataType: "boolean" }
      ];
    }
  },
  mounted() {
    if (!this.value?.length) {
      const anyPrimary = this.value?.find(e => e.primary);
      if (!anyPrimary) {
        this.fax.primary = true;
      }
    }

    if (this.value === null) {
      this.$emit("input", []);
    }
  },
  watch: {
    fax: {
      deep: true,
      handler(value) {
        const primary = this.value.find(e => e.primary);
        if (!primary || !this.value?.length) {
          value.primary = true;
        }
      }
    }
  },
  validations: {
    fax: {
      phoneNumber: {
        required
      },
      recipient: {
        maxLength: maxLength(100)
      },
      startTime: {
        required
      }
    }
  },
  methods: {
    async triggerIsAdding() {
      if (this.isEditing) {
        const confirm = await window.confirm(
          "You may have unsaved data, are you sure you want to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      this.fax = { ...this.defaultfax };
      this.fax.startTime = this.labSettings?.DefaultFaxStartTime || "1969-12-31T00:00:00";
      this.fax.endTime = this.labSettings?.DefaultFaxEndTime || "1969-12-31T23:59:00";
      this.fax.final = Boolean(this.labSettings.DefaultFaxFinal);
      this.fax.weekend = Boolean(this.labSettings.DefaultFaxWeekend);
      this.fax.prelim = Boolean(this.labSettings.DefaultFaxPrelim);
      if (this.isAdding) {
        this.isAdding = false;
        this.isEditing = false;
        return;
      }
      this.isAdding = true;
      this.isEditing = false;
    },
    utcformat(d) {
      if (d) {
        return moment.tz(d, "UTC").format("hh:mm a");
      }
      return "";
    },
    addFax() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      if (!this.value?.length) {
        this.fax.primary = true;
        this.faxes = [this.fax];
      }
      if (!this.isEditing) {
        const values = [...this.value, this.fax];
        this.faxes = values;
      }
      this.isEditing = false;
      this.$v.$reset();
      return (this.fax = { ...this.defaultfax });
    },
    editFax({ data }) {
      this.isEditing = true;
      this.fax = data;
    },
    cancelEdit() {
      if (this.isEditing) {
        this.isEditing = false;
      }
      this.isAdding = false;
      this.fax = { ...this.defaultFax };
    },
    setPrimary() {
      if (this.fax.primary) {
        this.fax.primary = !this.fax.primary;
        return;
      }
      if (this.value.length) {
        const anyPrimary = this.value.find(e => e?.primary);
        if (anyPrimary) {
          const confirm = window.confirm(
            "There is already a primary fax number.\n Are you sure you want to continue?"
          );

          if (confirm) {
            anyPrimary.primary = false;
            this.fax.primary = !this.fax.primary;
          }
        } else {
          this.fax.primary = !this.fax.primary;
        }
      } else {
        this.fax.primary = !this.fax.primary;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-cell {
  width: 10%;
}

table td {
  text-align: center;
  padding: 0;
}
</style>
