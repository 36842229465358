<template>
  <div class="text-input">
    <label
      v-html="displayName(accessKey, label)"
      v-if="!noLabel && label"
      :for="$attrs.id || $attrs.name"
      :id="($attrs.id || $attrs.name) + 'label'"
    />
    <DxNumberBox
      v-bind="inputProps"
      v-model="text"
      :inputAttr="inputAttrs"
      valueChangeEvent="input"
      :disabled="disabled"
      @content-ready="initialize"
    />
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
    <div v-else-if="required && !value">
      <span class="error">This field is required.</span>
    </div>
  </div>
</template>

<script>
import { validatorMsgMapBase } from "@/modules/helpers";
import DxNumberBox from "devextreme-vue/number-box";
export default {
  components: {
    DxNumberBox
  },
  name: "NumberInput",
  inheritAttrs: false,
  props: {
    name: String,
    noLabel: {
      type: Boolean
    },
    label: {
      type: String
    },
    accessKey: {
      type: String
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      required: false
    },
    default: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    customStyles: {
      type: Object
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
    validator: {
      type: Object,
      default: null
    },
    maxLength: {
      type: String
    }
  },
  methods: {
    onChange({ value }) {
      this.text = value;
    },
    initialize({ component }) {
      this.numberBox = component;
    },
    displayName(key = "", name) {
      if (key) {
        const regex = new RegExp(key, "i");
        if (regex.test(name)) {
          const { index } = name.match(regex);
          return `<b>${name.slice(0, index)}<u>${name[index]}</u>${name.slice(index + 1)}</b>`;
        }
      }
      return `<b>${name}</b>`;
    },
    focus() {
      this.numberBox.focus();
    },
    selectAll(inputId) {
      const inputs = document.getElementsByClassName("dx-texteditor-input");
      if (inputs.length) {
        const element = inputs.namedItem(inputId);
        if (element && this.value != null) {
          element.setSelectionRange(0, this.value.toString().length);
        }
      }
    }
  },
  created() {
    if (this.value && !isNaN(this.value)) {
      this.text = Number(this.value);
    } else if (typeof this.default !== "undefined") {
      if (!isNaN(this.default)) {
        this.text = this.default;
      }
    }
  },
  data() {
    return {
      isFocused: false,
      numberBox: {}
    };
  },
  computed: {
    inputProps() {
      return {
        ...this.$attrs,
        isValid: this.isValid,
        required: this.required
      };
    },
    inputAttrs() {
      return {
        ...this.$attrs,
        name: this.name,
        "aria-labelledby": (this.$attrs.id || this.$attrs.name) + "label",
        "data-testid": this.name + "-number-input"
      };
    },
    isValid() {
      if (this.validator) {
        return !this.validator.$invalid || !this.validator.$error;
      }
      return true;
    },
    text: {
      get() {
        if (this.value && !isNaN(this.value)) {
          return Number(this.value);
        }
        return this.value;
      },
      set(value) {
        const { maxlength } = this.$attrs;
        if (maxlength) {
          if (value.length > maxlength) {
            this.$refs.input.value = value.slice(0, maxlength);
            return;
          }
        }

        return this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  font-size: 0.75rem;
}
.hide_arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
