import { CytologyEntities } from "@/services/cytology";

export const grids = {
  namespaced: true,
  state: () => {
    const defaultGrid = {
      allowedPageSizes: [],
      columns: [],
      filterPanel: { filterEnabled: true },
      filterValue: null,
      pageIndex: 0,
      pageSize: 10,
      searchText: "",
      selectedRowKeys: []
    };
    return {
      openCases: defaultGrid,
      allCases: defaultGrid,
      caseDistributions: defaultGrid,
      caseHistory: defaultGrid,
      orders: defaultGrid,
      tasks: defaultGrid,
      slides: defaultGrid,
      tags: defaultGrid,
      prefixes: defaultGrid,
      distributions: defaultGrid,
      insurance: defaultGrid,
      orderMaintenance: defaultGrid,
      roles: defaultGrid,
      activity: defaultGrid,
      users: defaultGrid,
      contact: defaultGrid,
      labSettings: defaultGrid,
      typeCodes: defaultGrid,
      [CytologyEntities.CytDiagnosticCodes]: defaultGrid,
      [CytologyEntities.CytDiagnosticMacros]: defaultGrid,
      [CytologyEntities.CytQuestions]: defaultGrid,
      [CytologyEntities.CytMolecResults]: defaultGrid,
      [CytologyEntities.CytMolecTests]: defaultGrid,
      [CytologyEntities.CytMolecPanels]: defaultGrid,
      [CytologyEntities.CytTriggers]: defaultGrid
    };
  },
  mutations: {
    setGridState(state, { gridState, columns, name }) {
      for (let i = 0; i < Math.min(gridState.columns.length, columns.length); i++) {
        const gridColumn = gridState.columns[i];
        const column = columns.find(col => col.dataField === gridColumn.dataField);
        if (column) {
          gridColumn.filterValue = column.filterValue;
          gridColumn.filterValues = column.filterValues;
        }
        if (column.defaultFilterValues) {
          gridColumn.filterValues = column.defaultFilterValues;
        }
        if (column.defaultFilterValue) {
          gridColumn.filterValue = column.defaultFilterValue;
        }
      }
      state[name] = { ...gridState, searchText: "", selectedRowKeys: [], pageIndex: 0 };
    }
  },
  actions: {
    async setGridState({ commit }, payload) {
      commit("setGridState", payload);
    },
    resetGrid({ commit }, payload) {
      const defaultGrid = {
        allowedPageSizes: [],
        columns: [],
        filterPanel: { filterEnabled: true },
        filterValue: null,
        pageIndex: 0,
        pageSize: 10,
        searchText: "",
        selectedRowKeys: []
      };
      commit("setGridState", { gridState: defaultGrid, columns: [], name: payload });
    }
  }
};
