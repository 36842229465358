<template>
  <div>
    <h2>Custom QuickLinks Order</h2>
    <div v-if="isLoading">Loading...</div>
    <draggable v-model="customOrder">
      <div v-for="quickLink of customOrder" v-bind:key="quickLink.id" class="quick-links-card">
        {{ quickLink.displayName }}
      </div>
    </draggable>
    <SubmitCancelRow @submit="handleSubmit" @cancel="handleCancel" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";

export default {
  components: { draggable, SubmitCancelRow },
  props: ["currentOrder"],
  data() {
    return { customOrder: [], isLoading: false };
  },
  computed: {
    ...mapState({
      userProcedures: state => state.userProcedures,
      currentLab: state => state.currentLab,
      quickLinksCustomOrder: state => state.applicationSettings.quickLinksCustomOrder
    })
  },
  mounted() {
    this.loadProcedures();
  },
  methods: {
    ...mapActions(["getUserProcedures"]),
    async loadProcedures() {
      if (!this.userProcedures?.length) {
        this.isLoading = true;
        await this.getUserProcedures({ labId: this.currentLab });
        this.isLoading = false;
      }
      if (this.currentOrder?.length) {
        this.customOrder = this.currentOrder;
        for (const procedure of this.userProcedures) {
          if (
            !this.customOrder.find(
              e => e.id === procedure.id || (e.macroId && e.macroId === procedure.macroId)
            )
          ) {
            this.customOrder.push({
              id: procedure.id || "m" + procedure.macroId,
              macroId: procedure.macroId,
              displayName: procedure?.macroName
                ? procedure.macroName
                : procedure.code + " - " + procedure.description
            });
          }
        }
      } else {
        this.customOrder = this.userProcedures.map(e => {
          return {
            id: e.id || "m" + e.macroId,
            macroId: e.macroId,
            displayName: e?.macroName ? e.macroName : e.code + " - " + e.description
          };
        });
      }
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleSubmit() {
      this.$emit("submit", this.customOrder);
    }
  }
};
</script>

<style lang="scss" scoped>
.quick-links-card {
  border: 1px solid black;
  padding: 0.5em;
  cursor: move;
  margin: 0.5em;
}
</style>
