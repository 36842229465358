<template>
  <div v-shortkey="advancedShortkey" @shortkey="showAdvancedSettings = true">
    <h3>Printing/Images</h3>
    <div class="row">
      <SelectInput
        label="Default Print Mode"
        :items="printModes"
        name="printMode"
        class="col-6"
        v-model="userSettings.defaultPrintMode"
      />
      <SelectInput
        label="Default Batch Print Mode"
        :items="batchPrintModeOptions"
        name="defaultBatchPrintMode"
        class="col-6"
        v-model="userSettings.defaultBatchPrintMode"
      />
      <SelectInput
        label="Print Images On Report"
        :items="booleanOptions"
        name="imagePrintOnReport"
        class="col-6"
        v-model="userSettings.imagePrintOnReport"
      />
      <SelectInput
        label="Default Image Type"
        :items="imageTypes"
        name="defaultImageType"
        class="col-6"
        v-model="userSettings.defaultImageType"
      />
      <SelectInput
        label="Auto Open Slide Images"
        :items="booleanOptions"
        name="autoOpenSlideImages"
        class="col-6"
        v-model="userSettings.autoOpenSlideImages"
      />
      <SelectInput
        label="autoOpenReqSlip"
        :items="booleanOptionsWithLabSetting"
        name="autoOpenReqSlip"
        class="col-6"
        v-model="userSettings.autoOpenReqSlip"
      />
    </div>
    <h3>Layout/Navigation</h3>
    <div class="row">
      <SelectInput
        label="Default Dashboard Mode"
        :items="defaultDashboardOptions"
        name="defaultDashboardMode"
        class="col-6"
        v-model="userSettings.defaultDashboardMode"
      />
      <SelectInput
        label="Path Report Previews"
        class="col-6"
        v-model="userSettings.pathReportLocation"
        :items="pathOptions"
        name="pathReportLocation"
      />
      <SelectInput
        label="Auto Expand Demographics"
        :items="booleanOptions"
        name="expandDemo"
        class="col-6"
        v-model="userSettings.expandDemo"
      />
      <SelectInput
        label="Auto Open Case"
        :items="booleanOptions"
        name="autoOpenCase"
        class="col-6"
        v-model="userSettings.autoOpenCase"
      />
      <SelectInput
        label="Task Grid View"
        :items="taskGridViewOptions"
        v-if="permissions.TaskViewAllUsers"
        name="taskGridView"
        class="col-6"
        v-model="userSettings.taskGridViewAllUsers"
      />
      <SelectInput
        label="Auto Path Report Info"
        :items="booleanOptions"
        name="autoPathReportInfo"
        class="col-6"
        v-model="userSettings.autoPathReportInfo"
      />
      <NumberInput
        v-if="userSettings.pathReportLocation === 1"
        label="Default Path Report Zoom %"
        name="defaultPathReportZoom"
        class="col-6"
        v-model="userSettings.defaultPathReportZoom"
      />
      <SelectInput
        label="Always Auto Open Specimen Results"
        :items="booleanOptions"
        name="autoOpenSpecimenResults"
        class="col-6"
        v-model="userSettings.autoOpenSpecimenResults"
      />
      <SelectInput
        label="Show Orders Count on Dashboard"
        :items="booleanOptions"
        name="showOrdersCount"
        class="col-6"
        v-model="userSettings.showOrdersCount"
      />
      <SelectInput
        label="Default Case Edit Type"
        :items="editTypeOptions"
        name="defaultEditType"
        class="col-6"
        v-model="userSettings.defaultEditType"
      />
      <SelectInput
        label="QuickLinks Custom Order"
        :items="[]"
        name="quickLinksCustomOrder"
        class="col-6"
        placeholder="Click to Edit"
        @focusIn="toggleQuickLinksPopup"
        v-tooltip="'Click to edit'"
      />
      <!-- Backed out due to issues -->
      <!-- <SelectInput
        v-if="permissions.CaseResultsView"
        label="Results Mode Statuses"
        :items="[]"
        name="userSettings.customResultStatuses"
        class="col-6"
        :placeholder="selectedCustomResultStatuses"
        @focusIn="toggleCustomResultStatusPopup"
        v-tooltip="'Click to edit'"
      /> -->
    </div>
    <h3>Next Case Options</h3>
    <div class="row">
      <SelectInput
        label="Auto Next Case Type"
        :items="nextCaseTypeOptions"
        name="nextCaseType"
        class="col-6"
        v-model="userSettings.nextCaseType"
      />
      <SelectInput
        label="Auto Next Case Trigger"
        :items="nextCasePopupOptions"
        name="nextCasePopup"
        class="col-6"
        v-model="userSettings.nextCasePopup"
      />
      <SelectInput
        label="Autofill Next Case Popup"
        :items="booleanOptions"
        name="autoFillNextCase"
        class="col-6"
        v-model="userSettings.autoFillNextCase"
      />
      <SelectInput
        label="Next Case Behavior"
        :items="nextCaseBehaviorOptions"
        name="nextCaseUseDashboardMode"
        class="col-6"
        v-model="userSettings.nextCaseUseDashboardMode"
      />
      <SelectInput
        label="Next Case Selection"
        :items="nextCaseDashboardFilterOptions"
        name="nextCaseDashboardFilter"
        class="col-6"
        v-model="userSettings.nextCaseDashboardFilter"
      />
    </div>
    <h3>Macros</h3>
    <div class="row">
      <SelectInput
        label="Segregate Results Macros"
        :items="booleanOptionsWithLabSetting"
        name="macroStartsWith"
        class="col-6"
        v-model="userSettings.segregateResultsMacros"
      />
      <SelectInput
        label="Macro Type Assist"
        :items="booleanOptions"
        name="expandDemo"
        class="col-6"
        v-model="userSettings.macroAssist"
      />
      <SelectInput
        label="Macro Search Mode"
        :items="macroSearchModeOptions"
        name="macroSearchMode"
        class="col-6"
        v-model="userSettings.macroSearchMode"
      />
      <SelectInput
        label="Macro Starts With"
        :items="macroStartsWithOptions"
        name="macroStartsWith"
        class="col-6"
        v-model="userSettings.macroStartsWith"
      />
      <SelectInput
        label="Automatic Macro Popup"
        :items="booleanOptions"
        name="automaticMacroPopup"
        class="col-6"
        v-model="userSettings.automaticMacroPopup"
      />
      <SelectInput
        label="Macro Window Gross"
        :items="booleanOptions"
        name="macroWindowGross"
        class="col-6"
        v-model="userSettings.macroWindowGross"
      />
      <SelectInput
        label="Primary Macro Window Text"
        :items="primaryMacroWindowTextOptions"
        name="primaryMacroWindowText"
        class="col-6"
        v-model="userSettings.primaryMacroWindowText"
      />
      <SelectInput
        label="Macro Window Default Input"
        :items="macroDefaultInputOptions"
        name="macroDefaultInput"
        class="col-6"
        v-model="userSettings.macroDefaultInput"
      />
      <SelectInput
        label="Retain Scroll After Results Macro"
        :items="booleanOptions"
        name="resultsMacroRetainScroll"
        class="col-6"
        v-model="userSettings.resultsMacroRetainScroll"
      />
      <SelectInput
        label="Confirm Before Clearing Results Macros"
        :items="booleanOptions"
        name="confirmClearResultsMacro"
        class="col-6"
        v-model="userSettings.confirmClearResultsMacro"
      />
      <SelectInput
        label="Preserve Macro Case Notes"
        :items="booleanOptions"
        name="preserveMacroCaseNotes"
        class="col-6"
        v-model="userSettings.preserveMacroCaseNotes"
      />
      <SelectInput
        label="Display Last Macro"
        :items="booleanOptions"
        name="displayLastMacro"
        class="col-6"
        v-model="userSettings.displayLastMacro"
      />
    </div>
    <h3>Editors</h3>
    <div class="row">
      <SelectInput
        label="Auto Open Editors"
        :items="booleanOptions"
        name="autoOpenEditors"
        class="col-6"
        v-model="userSettings.autoOpenEditors"
      />
      <SelectInput
        label="Results Auto Open"
        :items="resultsEditorAutoOpenOptions"
        name="autoOpenFirstEditorResults"
        class="col-6"
        v-model="userSettings.autoOpenFirstEditorResults"
      />
      <SelectInput
        label="Enable Spell Checker"
        :items="booleanOptions"
        name="enableSpellchecker"
        class="col-6"
        v-model="userSettings.enableSpellchecker"
        hover="Click to view dictionary name."
        :clickLabel="clickEnableSpellChecker"
      />

      <SelectInput
        label="Use Dragon Editors"
        :items="booleanOptions"
        name="useDragonEditors"
        class="col-6"
        v-model="userSettings.useDragonEditors"
      />
    </div>
    <h3>QuickLinks</h3>
    <div class="row">
      <SelectInput
        label="Quick Links Add Auto Popup"
        :items="autoAddQuickLinksOptions"
        name="disableAutoAddQuickLinks"
        class="col-6"
        v-model="userSettings.disableAutoAddQuickLinks"
      />
      <SelectInput
        label="Case Orders Fields"
        :items="caseOrdersFieldsOptions"
        name="caseOrdersFields"
        class="col-6"
        v-model="userSettings.caseOrdersFields"
      />
      <SelectInput
        label="Confirm Before Removing Hold"
        :items="booleanOptions"
        name="confirmRemoveHold"
        class="col-6"
        v-model="userSettings.confirmRemoveHold"
      />
      <SelectInput
        label="Confirm Before Removing Order"
        :items="booleanOptions"
        name="confirmRemoveOrder"
        class="col-6"
        v-model="userSettings.confirmRemoveOrder"
      />
      <SelectInput
        label="Quick Links No Blocks"
        :items="booleanOptions"
        name="quickLinksNoBlocks"
        class="col-6"
        v-model="userSettings.quickLinksNoBlocks"
      />
      <SelectInput
        label="Show Orders Grid After Adding"
        :items="booleanOptions"
        name="showOrdersGridAfterAdd"
        class="col-6"
        v-model="userSettings.showOrdersGridAfterAdd"
      />
    </div>
    <div v-if="showAdvancedSettings">
      <h3>Advanced</h3>
      <div class="row">
        <SelectInput
          label="Use Staging Environment"
          :items="booleanOptions"
          name="userInStaging"
          class="col-6"
          v-model="userSettings.userInStaging"
        />
        <!-- IP-1328 - removes user setting -->
        <!-- <SelectInput
            label="Warn If No Distribution"
            :items="booleanOptions"
            name="warnIfNoDistribution"
            class="col-6"
            v-model="warnIfNoDistribution"
          /> -->
      </div>
    </div>
    <modal :status="isCustomResultsOpen" @close="toggleCustomResultStatusPopup">
      <CustomResultStatusPopup
        :statusProp="userSettings.customResultStatuses"
        @cancel="toggleCustomResultStatusPopup"
        @submit="handleCustomStatusSubmit"
      />
    </modal>
    <modal :status="isQuickLinksPopupOpen" @close="toggleQuickLinksPopup">
      <CustomQuickLinksOrder
        :currentOrder="userSettings.quickLinksCustomOrder"
        @cancel="toggleQuickLinksPopup"
        @submit="handleUpdateQuickLinksOrder"
      />
    </modal>
  </div>
</template>

<script>
import SelectInput from "@/components/common/SelectInput.vue";
import { mapGetters, mapState } from "vuex";
import { altKey, booleanLookup } from "@/modules/helpers";
import { ImagesApi } from "../services";
import { defaultUserSettings } from "@/modules/defaultUserSettings";
import NumberInput from "@/components/common/NumberInput.vue";
import Modal from "@/components/common/Modal.vue";
import { CaseStatusEnum } from "@/modules/enums";
import CustomResultStatusPopup from "@/components/CustomResultStatusPopup.vue";
import CustomQuickLinksOrder from "./CustomQuickLinksOrder.vue";

export default {
  props: ["value"],
  components: { SelectInput, NumberInput, Modal, CustomResultStatusPopup, CustomQuickLinksOrder },
  data() {
    return {
      userSettings: { ...defaultUserSettings },
      booleanOptions: booleanLookup.dataSource,
      booleanOptionsWithLabSetting: [
        { id: null, displayName: "Use Lab Setting" },
        ...booleanLookup.dataSource
      ],
      macroSearchModeOptions: [
        { id: true, displayName: "Name Search" },
        { id: false, displayName: "Free Text Search" }
      ],
      macroStartsWithOptions: [
        { id: true, displayName: "Starts With" },
        { id: false, displayName: "Contains" }
      ],
      defaultDashboardOptions: [
        { id: 0, displayName: "Open Cases" },
        { id: 1, displayName: "All Cases" },
        { id: 2, displayName: "Orders" },
        { id: 3, displayName: "Tasks" },
        { id: 4, displayName: "Search" }
      ],
      taskGridViewOptions: [
        {
          id: false,
          displayName: "Only Me"
        },
        { id: true, displayName: "All Users" }
      ],
      nextCaseBehaviorOptions: [
        { id: 0, displayName: "Same Tab" },
        { id: 1, displayName: "Dashboard Mode" }
      ],
      caseOrdersFieldsOptions: [
        { id: 0, displayName: "Default (Block, Order, Panel)" },
        { id: 1, displayName: "Order (Order, Panel, Block)" },
        { id: 2, displayName: "Panel (Panel, Order, Block)" }
      ],
      nextCasePopupOptions: [
        { id: 0, displayName: "Never" },
        { id: 1, displayName: "After Save" },
        { id: 2, displayName: "After Sign" }
      ],
      autoAddQuickLinksOptions: [
        { id: false, displayName: "Yes" },
        { id: true, displayName: "No" }
      ],
      macroDefaultInputOptions: [
        { id: 0, displayName: "Search" },
        { id: 1, displayName: "Exact" }
      ],
      batchPrintModeOptions: [
        {
          displayName: "Initial",
          id: 1
        },
        {
          displayName: "Re-Distribute",
          id: 2
        },
        {
          displayName: "Review Copies",
          id: 3
        }
      ],
      primaryMacroWindowTextOptions: [
        { id: 0, displayName: "None" },
        { id: 1, displayName: "Clinical" },
        { id: 2, displayName: "Gross" }
      ],
      imageTypes: [],
      showAdvancedSettings: false,
      advancedShortkey: altKey("a"),
      nextCaseTypeOptions: [
        { id: 0, displayName: "Popup" },
        { id: 1, displayName: "Navigate" }
      ],
      resultsEditorAutoOpenOptions: [
        { id: false, displayName: "Diagnosis" },
        { id: true, displayName: "First Editor" }
      ],
      isCustomResultsOpen: false,
      nextCaseDashboardFilterOptions: [
        { id: 0, displayName: "Next Number" },
        { id: 1, displayName: "Next in Dashboard" }
      ],
      editTypeOptions: [
        { id: 0, displayName: "None" },
        { id: 4, displayName: "Non-Diagnostic" }
      ],
      isQuickLinksPopupOpen: false
    };
  },
  computed: {
    ...mapState({
      applicationSettings: state => state.applicationSettings,
      pathOptions: state => state.dropdowns.pathReportPreviewLocations,
      labSettings: state => state.labSettings
    }),
    ...mapGetters(["permissions"]),
    printModes() {
      const modes = [
        {
          displayName: "Cassettes",
          id: 1
        },
        {
          displayName: "Slides",
          id: 2
        },
        {
          displayName: "Slides (Glass)",
          id: 3
        },
        {
          displayName: "Worksheet",
          id: 4
        }
      ];
      if (JSON.parse(this.labSettings.FileDropPrintingConfiguration)?.url) {
        modes.push({
          displayName: "Cassettes (File Drop)",
          id: 5
        });
      }
      return modes;
    },
    selectedCustomResultStatuses() {
      if (!this.userSettings.customResultStatuses?.length) {
        return "Spec, Gross, Orders";
      }
      let selectedStatusNames = [];
      const statuses = Object.keys(CaseStatusEnum).map(e => {
        return {
          id: CaseStatusEnum[e],
          displayName: e
        };
      });
      for (const statusId of [...this.userSettings.customResultStatuses].sort(function (a, b) {
        return a - b;
      })) {
        const statusData = statuses.find(e => e.id === statusId);
        selectedStatusNames.push(statusData.displayName);
      }
      return selectedStatusNames.join(", ");
    },
    defaultResultsStatuses() {
      const { Spec, Gross, Orders } = CaseStatusEnum;
      return [Spec, Gross, Orders];
    }
  },
  mounted() {
    this.$store.dispatch("dropdowns/getPathReportPreviewLocations");
    ImagesApi.getImageTypes().then(res => {
      this.imageTypes = res || [];
    });
    this.loadUserSettings();
    if (this.labSettings.AllowCaseEditTypeBilling) {
      this.editTypeOptions.push({ id: 6, displayName: "Billing" });
    }
  },
  watch: {
    userSettings: {
      deep: true,
      handler(nv) {
        this.$emit("updateSettings", nv);
      }
    }
  },
  methods: {
    loadUserSettings() {
      this.userSettings = this.value;
      if (this.userSettings.userInStaging) {
        this.showAdvancedSettings = true;
      }
    },
    clickEnableSpellChecker() {
      window.alert("Dictionary name: " + this.applicationSettings.userDictionaryName);
    },
    toggleCustomResultStatusPopup() {
      this.isCustomResultsOpen = !this.isCustomResultsOpen;
    },
    handleCustomStatusSubmit(data) {
      data = data.sort(function (a, b) {
        return a - b;
      });
      if (JSON.stringify(data) === JSON.stringify(this.defaultResultsStatuses)) {
        this.userSettings.customResultStatuses = [];
      } else {
        this.userSettings.customResultStatuses = data;
      }
      this.toggleCustomResultStatusPopup();
    },
    toggleQuickLinksPopup() {
      this.isQuickLinksPopupOpen = !this.isQuickLinksPopupOpen;
    },
    handleUpdateQuickLinksOrder(data) {
      this.userSettings.quickLinksCustomOrder = data;
      this.toggleQuickLinksPopup();
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
h3 {
  margin-top: 0.5em;
  margin-bottom: 1em;
}
</style>
