<template>
  <div class="search-wrapper ml-2">
    <div class="search-filter">
      <div class="d-flex align-items-center">
        <page-title class="ml-1">Search</page-title>
        <checkbox
          class="ml-3"
          label="Show Expired"
          name="showExpired"
          id="showExpired"
          v-model="showExpired"
        />
        <icon-button
          icon="info-circle"
          v-tooltip="
            'Show expired Prefixes, Protocols, Results Macros, Case Tags, and Hold Codes, as well as disabled users.'
          "
        />
      </div>
      <form @submit.prevent="handleCreateSearch" class="searchHeader">
        <div class="row ml-2 mb-3">
          <div class="my-2 my-lg-0 d-flex">
            <loader size="small" class="mr-2 align-self-center" v-show="isLoading" />
            <button
              v-if="loadedSearch"
              @click.prevent="deleteSavedSearch"
              class="btn btn-danger mr-1"
            >
              Delete Saved Search
            </button>
            <button @click="openSaveSearch" class="btn btn-primary mr-1 py-0">
              Save Search As
              <DxDropDownButton
                v-if="userSearches.length"
                icon="chevrondown"
                type="normal"
                :useSelectMode="false"
                :showArrowIcon="false"
                :split-button="false"
                :use-select-mode="true"
                stylingMode="text"
                class="btn btn-primary dropdown-button p-0"
                @item-click="loadSearch"
                :items="userSearches"
                displayExpr="name"
                valueExpr="id"
                :elementAttr="buttonAttr"
                :wrapItemText="false"
                :drop-down-options="dropDownOptions"
                :itemTemplate="itemTemplate"
              >
                <template #itemTemplate="{ data }">
                  <div>
                    {{ data.name }}
                  </div>
                </template>
              </DxDropDownButton>
            </button>
            <button
              type="button"
              @click="clearFilters"
              icon="search"
              class="btn btn-outline-danger mr-1"
            >
              <span class="ml-1">Clear Filters</span>
            </button>
            <icon-button :disable="isLoading" type="submit" icon="search" class="btn-primary mr-1">
              <span class="ml-1">Search</span></icon-button
            >
            <button :disable="isLoading" class="btn btn-primary mr-1" @click.prevent="handleExport">
              Export
            </button>
            <button
              :disable="isLoading"
              class="btn btn-primary"
              @click.prevent="toggleExportsPopup"
            >
              Download Exports
            </button>
          </div>
        </div>
        <h2 class="pb-2">Filters</h2>
        <div class="row">
          <div class="col-lg-6 col-xl-3">
            <div class="row" v-if="hasPermission('PatientName')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Patient Name</b></div>
              <text-input
                class="col input-field"
                :noLabel="true"
                name="patientName"
                id="patientName"
                :highlightSearchValue="true"
                placeholder="Last, First"
                v-model="searchFields.patientFieldContains"
                data-private="redact"
              />
            </div>
            <div class="row" v-if="hasPermission('CaseNumber')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Accession #</b></div>
              <text-input
                class="col input-field pr-1"
                :noLabel="true"
                :highlightSearchValue="true"
                placeholder="From"
                name="caseNumberStart"
                ref="caseNumberStart"
                @blur="formatAccessionNumber"
                v-model="caseNumberStart"
              />
              <text-input
                class="col input-field pl-1"
                :noLabel="true"
                :highlightSearchValue="true"
                placeholder="To"
                name="caseNumberEnd"
                ref="caseNumberEnd"
                @blur="formatAccessionNumber"
                v-model="caseNumberEnd"
              />
            </div>
            <div class="row" v-if="hasPermission('LabLocation')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Lab Location</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                name="labLocation"
                id="labLocation"
                :highlightSearchValue="true"
                v-model="searchFields.labLocationIds"
                :items="availableLabLocations"
              />
            </div>
            <div class="row" v-if="hasPermission('Prefix')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Prefix</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                v-model="searchFields.prefixIds"
                :highlightSearchValue="true"
                :dataSource="prefixDataSource"
                displayExpr="code"
                :grayOutExpired="true"
              />
            </div>
            <div class="row" v-if="hasPermission('PrefixTags')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Prefix Tags</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                v-model="searchFields.prefixTagIds"
                :dataSource="prefixTagOptions"
              />
            </div>
            <div class="row" v-if="hasPermission('PatientDateOfBirth')">
              <div class="col-lg-4 col-xl-3 field-label"><b>DOB</b></div>
              <date-picker
                class="col input-field"
                ref="bornOn"
                :noLabel="true"
                id="bornOn"
                :highlightSearchValue="true"
                dateSerializationFormat="yyyy-MM-dd"
                v-model="searchFields.bornOn"
                name="bornOn"
                data-private="redact"
                :twoDigitYear="true"
              />
            </div>
            <div class="row" v-if="hasPermission('PatientAge')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Age (years)</b></div>
              <text-input
                class="col input-field pr-1"
                :noLabel="true"
                :highlightSearchValue="true"
                placeholder="From"
                v-model="searchFields.ageMinYears"
              />
              <text-input
                class="col input-field pl-1"
                :noLabel="true"
                :highlightSearchValue="true"
                placeholder="To"
                v-model="searchFields.ageMaxYears"
              />
            </div>
            <div class="row" v-if="hasPermission('PatientGender')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Gender</b></div>
              <tag-input
                class="col input-field"
                name="sex"
                ref="genderIds"
                :noLabel="true"
                :highlightSearchValue="true"
                id="sex"
                :items="sexes"
                v-model="searchFields.genderIds"
              />
            </div>
            <div class="row" v-if="hasPermission('PatientSSN')">
              <div class="col-lg-4 col-xl-3 field-label"><b>SSN</b></div>
              <text-input
                class="col input-field"
                :noLabel="true"
                name="ssn"
                id="ssn"
                :highlightSearchValue="true"
                v-model="searchFields.ssn"
                data-private="redact"
              />
            </div>
            <div class="row" v-if="hasPermission('PatientMRN')">
              <div class="col-lg-4 col-xl-3 field-label"><b>MRN</b></div>
              <text-input
                class="col input-field"
                :noLabel="true"
                name="mrn"
                id="mrn"
                :highlightSearchValue="true"
                v-model="searchFields.mrn"
                data-private="redact"
              />
            </div>
            <div class="row" v-if="hasPermission('PatientAccountNumber')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Account #</b></div>
              <text-input
                class="col input-field"
                :noLabel="true"
                name="accountNum"
                id="accountNum"
                :highlightSearchValue="true"
                v-model="searchFields.accountNum"
                data-private="redact"
              />
            </div>
            <div class="row" v-if="hasPermission('CaseOrderNumber')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Order #</b></div>
              <text-input
                class="col input-field"
                :noLabel="true"
                name="orderNum"
                id="orderNum"
                :highlightSearchValue="true"
                v-model="searchFields.orderNum"
                data-private="redact"
              />
            </div>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="row" v-if="hasPermission('ProviderName')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Provider</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                placeholder="Last, First"
                :highlightSearchValue="true"
                :dataSource="createArrayStore('providerNamesList')"
                valueExpr="id"
                v-model="searchFields.providerIds"
              />
            </div>
            <div class="row" v-if="hasPermission('ProviderLocationId')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Provider Location</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                :dataSource="createArrayStore('locationsNamesList')"
                displayExpr="displayName"
                valueExpr="id"
                v-model="searchFields.providerLocationIds"
              />
            </div>
            <div class="row" v-if="hasPermission('ProviderLocationCity')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Provider City</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                :items="providers.cities"
                :highlightSearchValue="true"
                displayExpr="displayName"
                valueExpr="displayName"
                v-model="searchFields.providerLocationCities"
              />
            </div>
            <div class="row" v-if="hasPermission('ProviderLocationState')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Provider State</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                :items="states"
                displayExpr="displayName"
                v-model="searchFields.providerLocationStates"
              />
            </div>
            <div class="row" v-if="hasPermission('ProviderLocationZip')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Provider ZIP</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                :items="providers.zips"
                :highlightSearchValue="true"
                displayExpr="displayName"
                valueExpr="displayName"
                v-model="searchFields.providerLocationZipCodes"
              />
            </div>
            <div class="row" v-if="hasPermission('PathologistName')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Pathologist</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                placeholder="Last, First"
                :highlightSearchValue="true"
                v-model="searchFields.pathologistIds"
                :dataSource="pathologistsDataSource"
                searchExpr="displayName"
                :grayOutExpired="true"
              />
            </div>
            <div class="row" v-if="hasPermission('Proxy')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Proxy</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                placeholder="Last, First"
                :highlightSearchValue="true"
                v-model="searchFields.proxyUserIds"
                :dataSource="pathologistsDataSource"
                searchExpr="displayName"
                :grayOutExpired="true"
              />
            </div>
            <div class="row" v-if="hasPermission('Protocol')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Protocol</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                v-model="searchFields.protocolIds"
                :dataSource="protocolDataSource"
                displayExpr="macroName"
                searchExpr="macroName"
                valueExpr="macroId"
                :grayOutExpired="true"
              />
            </div>
            <div class="row" v-if="hasPermission('SpecimenSite')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Site</b></div>
              <text-input
                class="col input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                v-model="searchFields.specimenSiteContains"
              />
            </div>
            <div class="row" v-if="hasPermission('PreDiagnosis')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Pre-Diagnosis Text</b></div>
              <text-input
                class="col input-field"
                :noLabel="true"
                placeholder="Clinical, gross"
                :highlightSearchValue="true"
                v-model="searchFields.prediagnosis"
              />
            </div>
            <div class="row" v-if="hasPermission('Diagnosis')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Diagnostic Text</b></div>
              <text-input
                class="col input-field"
                :noLabel="true"
                placeholder="Diagnosis, microscopic, specimen note, case note"
                :highlightSearchValue="true"
                v-model="searchFields.diagnosis"
              />
            </div>
            <div class="row" v-if="hasPermission('SpecimenResultMacroName')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Results Macro</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                v-model="searchFields.resultsMacroIds"
                :highlightSearchValue="true"
                :dataSource="resultsMacrosDataSource"
                displayExpr="macroName"
                valueExpr="macroId"
                :grayOutExpired="true"
              />
            </div>
            <div class="row" v-if="hasPermission('SpecimenResultMacroName')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Macro Description</b></div>
              <text-input
                class="col input-field"
                :noLabel="true"
                v-model="searchFields.macroDescriptionStartsWith"
                :highlightSearchValue="true"
              />
            </div>
            <div class="row" v-if="hasPermission('CPT')">
              <div class="col-lg-4 col-xl-3 field-label"><b>CPT Code</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                v-model="searchFields.cptCodeIds"
                :highlightSearchValue="true"
                :dataSource="cptCodesDataSource"
                displayExpr="code"
                :grayOutExpired="true"
              />
            </div>
            <div class="row" v-if="hasPermission('SpecimenIcdCode')">
              <div class="col-lg-4 col-xl-3 field-label"><b>ICD10 Code</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                v-model="searchFields.icdCodeIds"
                :highlightSearchValue="true"
                :dataSource="icdDataSource"
                searchExpr="displayName"
                :grayOutExpired="true"
              />
            </div>
            <div class="row" v-if="hasPermission('CaseTags')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Case Tags</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                v-model="searchFields.caseTagIds"
                :highlightSearchValue="true"
                :dataSource="caseTagDataSource"
                searchExpr="displayName"
                :grayOutExpired="true"
              />
            </div>
            <div class="row" v-if="hasPermission('HoldCodes')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Hold Codes</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                v-model="searchFields.holdCodeIds"
                :highlightSearchValue="true"
                :dataSource="holdCodeDataSource"
                :displayExpr="holdCodeDisplayExpr"
                :searchExpr="labSettings.SearchByHoldCode ? 'holdCode' : 'displayName'"
                :grayOutExpired="true"
              />
            </div>
            <div class="row" v-if="hasPermission('InterfaceDiagnosis')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Interface Diagnosis</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                :dataSource="interfaceDiagnosesDataSource"
                :highlightSearchValue="true"
                valueExpr="displayName"
                v-model="searchFields.interfaceDiagnoses"
              />
            </div>
            <div class="row" v-if="hasPermission('BillingTypes')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Billing Types</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                :items="billingTypeOptions"
                :highlightSearchValue="true"
                v-model="searchFields.billingTypes"
              />
            </div>
            <div class="row" v-if="hasPermission('BillingCycles')">
              <div class="col-lg-4 col-xl-3 field-label"><b>Billing Cycles</b></div>
              <tag-input
                class="col input-field"
                :noLabel="true"
                :items="billingCycleOptions"
                :highlightSearchValue="true"
                v-model="searchFields.billingCycles"
              />
            </div>
          </div>
          <div class="col-xl-6 mt-2">
            <div class="row" v-if="hasPermission('CaseCollectedOn')">
              <div class="col-2 field-label"><b>Collected</b></div>
              <date-range
                ref="collectedRange"
                class="col-5 input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                placeholder="Start Date"
                name="collectedOnOrAfter"
                @input="handleCollectedRange"
                :start="searchFields.collectedOnOrAfter"
                :end="searchFields.collectedOnOrBefore"
              />
              <div class="col-6"></div>
            </div>
            <div
              v-if="hasPermission('CaseAccessionedOn') || hasPermission('AccessionedBy')"
              class="row"
            >
              <div class="col-2 field-label"><b>Accessioned</b></div>
              <date-range
                v-if="hasPermission('CaseAccessionedOn')"
                ref="accessionedRange"
                class="col-5 input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                placeholder="Start Date"
                name="accessionedOnOrAfter"
                @input="handleAccessionedRange"
                :start="searchFields.accessionedOnOrAfter"
                :end="searchFields.accessionedOnOrBefore"
              />
              <div v-if="hasPermission('AccessionedBy')" class="field-label">
                <b>By</b>
              </div>
              <tag-input
                v-if="hasPermission('AccessionedBy')"
                class="col input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                :dataSource="userDataSource"
                :displayExpr="userDisplayName"
                valueExpr="userName"
                v-model="searchFields.accessioners"
                :grayOutExpired="true"
              />
            </div>
            <div v-if="hasPermission('CaseReceivedOn')" class="row">
              <div class="col-2 field-label"><b>Received</b></div>
              <date-range
                ref="receivedRange"
                class="col-5 input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                @input="handleReceivedRange"
                name="receivedOnOrAfter"
                placeholder="Start Date"
                :start="searchFields.receivedOnOrAfter"
                :end="searchFields.receivedOnOrBefore"
              />
            </div>
            <div class="row" v-if="hasPermission('GrossedOn') || hasPermission('GrossedBy')">
              <div class="col-2 field-label"><b>Grossed</b></div>
              <date-range
                v-if="hasPermission('GrossedOn')"
                ref="grossedRange"
                class="col-5 input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                placeholder="Start Date"
                name="grossedOnOrAfter"
                @input="handleGrossedRange"
                :start="searchFields.grossedOnOrAfter"
                :end="searchFields.grossedOnOrBefore"
              />
              <div v-if="hasPermission('GrossedBy')" class="field-label"><b>By</b></div>
              <tag-input
                v-if="hasPermission('GrossedBy')"
                class="col input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                :dataSource="userDataSource"
                :displayExpr="userDisplayName"
                valueExpr="id"
                v-model="searchFields.grossers"
                :grayOutExpired="true"
              />
            </div>
            <div class="row" v-if="hasPermission('ResultedOn') || hasPermission('ResultedBy')">
              <div class="col-2 field-label"><b>Resulted</b></div>
              <date-range
                v-if="hasPermission('ResultedOn')"
                ref="resultedRange"
                class="col-5 input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                placeholder="Resulted Date"
                name="resultedOnOrAfter"
                @input="handleResultedOnRange"
                :start="searchFields.resultedOnOrAfter"
                :end="searchFields.resultedOnOrBefore"
              />
              <div v-if="hasPermission('ResultedBy')" class="field-label">
                <b>By</b>
              </div>
              <tag-input
                v-if="hasPermission('ResultedBy')"
                class="col input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                :dataSource="userDataSource"
                :displayExpr="userDisplayName"
                valueExpr="userName"
                v-model="searchFields.resulters"
                :grayOutExpired="true"
              />
            </div>
            <div v-if="hasPermission('SignedOn')" class="row">
              <div class="col-2 field-label"><b>Signed</b></div>
              <date-range
                ref="signedRange"
                class="col-5 input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                placeholder="Start Date"
                name="firstSignedOnOrAfter"
                @input="handleSignedDateRange"
                :start="searchFields.firstSignedOnOrAfter"
                :end="searchFields.firstSignedOnOrBefore"
              />
            </div>
            <div class="row" v-if="hasPermission('ModifiedOn') || hasPermission('ModifiedBy')">
              <div class="col-2 field-label"><b>Modified After Reported On</b></div>
              <date-range
                v-if="hasPermission('ModifiedOn')"
                ref="modifiedRange"
                class="col-5 input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                placeholder="Start Date"
                name="modifiedOnOrAfter"
                @input="handleModifiedOnRange"
                :start="searchFields.modifiedOnOrAfter"
                :end="searchFields.modifiedOnOrBefore"
              />
              <div v-if="hasPermission('ModifiedBy')" class="field-label"><b>By</b></div>
              <tag-input
                v-if="hasPermission('ModifiedBy')"
                class="col input-field"
                :noLabel="true"
                :highlightSearchValue="true"
                :dataSource="userDataSource"
                :displayExpr="userDisplayName"
                valueExpr="userName"
                v-model="searchFields.modifiers"
                :grayOutExpired="true"
              />
            </div>
            <div class="row" v-if="hasPermission('CaseStage')">
              <div class="col-2 field-label"><b>Status</b></div>
              <select-input
                class="col-3 input-field"
                :noLabel="true"
                v-model="searchFields.caseStageId"
                :items="caseStageOptions"
              />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-start mb-2">
          <h2 class="mb-2">Columns to Export</h2>
          <button class="btn btn-primary ml-2" @click.prevent="selectAllColumns">Select All</button>
          <button class="btn btn-primary ml-2" @click.prevent="clearAllColumns">Clear All</button>
        </div>

        <div class="d-flex justify-content-start">
          <div v-for="maxNum of [11, 22, 33, 44, 55]" v-bind:key="maxNum">
            <div
              v-for="(field, idx) of Object.keys(exportFieldsEnum).slice(maxNum - 11, maxNum)"
              v-bind:key="idx"
              class="ml-2 pr-2"
            >
              <Checkbox
                :label="startCase(field)"
                :name="field"
                :id="field + 'Box'"
                :value="
                  searchFields.exportFields.split(',').includes(exportFieldsEnum[field].toString())
                "
                @input="toggleField(field)"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <modal :status="saveModalOpen" @close="closeSaveSearchModal">
      <h2>Save Search Filter</h2>
      <text-input v-model="searchName" label="Name" v-focus />
      <div class="ml-auto my-2 my-lg-0 d-flex">
        <button class="btn btn-danger mr-1" @click="closeSaveSearchModal">Cancel</button>
        <button class="btn btn-success" @click="saveSearch">Save</button>
      </div>
    </modal>
    <modal :status="isExportsPopupOpen" @close="toggleExportsPopup">
      <h3>Download Export</h3>
      <select-input
        label="Exports"
        :dataSource="exportsDataSource"
        v-model="exportToDownload"
        :displayExpr="exportDisplayExpr"
      />
      <div class="ml-auto my-2 my-lg-0 d-flex">
        <Loader v-if="isDownloading" size="small" />
        <button class="btn btn-danger mr-1" @click="toggleExportsPopup">Cancel</button>
        <button class="btn btn-primary" @click="handleDownload">Download</button>
      </div>
    </modal>
  </div>
</template>

<script>
import DataSource from "devextreme/data/data_source";
import { mapGetters, mapState } from "vuex";
import {
  BillingApi,
  CasesApi,
  DropdownApi,
  MacrosApi,
  PrefixApi,
  ProvidersApi,
  UsersApi,
  ExportsApi
} from "@/services";
import { MacroTypeEnum, UserTypesEnum } from "@/modules/enums";
import eventBus, {
  EDIT_SAVED_SEARCH_COLUMNS,
  LOAD_SAVED_SEARCH,
  SAVE_SEARCH
} from "@/modules/eventBus";
import { sortBy } from "lodash";
import {
  CLEAR_FREE_TEXT_FILTERS,
  RENDER_FREE_TEXT_SEARCH,
  SET_FREE_TEXT_SEARCH_FILTERS
} from "@/modules/constants";
import TextInput from "./common/TextInput.vue";
import TagInput from "./common/TagInput.vue";
import DateRange from "./common/DateRange.vue";
import Checkbox from "./common/Checkbox.vue";
import Modal from "./common/Modal.vue";
import IconButton from "./common/IconButton.vue";
import DxDropDownButton from "devextreme-vue/drop-down-button";
import DatePicker from "./common/DatePicker.vue";
import Loader from "./common/Loader.vue";
import PageTitle from "./common/PageTitle.vue";
import {
  dateRangeFilter,
  formatPhoneNumber,
  formatSSN,
  downloadBlob,
  utcToLocal,
  calculateAccessionNumberingWithoutPrevCentury,
  capitalize
} from "@/modules/helpers";
import ArrayStore from "devextreme/data/array_store";
import moment from "moment";
import { handleErrors } from "@/modules/handleErrors";
import SelectInput from "@/components/common/SelectInput.vue";
import { startCase } from "lodash";
import { scanCaseBarcode } from "@/modules/scanCaseBarcode";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    TextInput,
    TagInput,
    DateRange,
    Checkbox,
    Modal,
    IconButton,
    DxDropDownButton,
    DatePicker,
    Loader,
    PageTitle,
    SelectInput
  },
  data() {
    return {
      isSearchOpen: true,
      isLoading: false,
      isExpanded: false,
      searchFields: {},
      defaultFields: {
        patientFieldContains: null,
        specimenSiteContains: null,
        providerLocationIds: [],
        providerLocationCities: [],
        providerLocationStates: [],
        providerLocationZipCodes: [],
        caseIdStart: null,
        caseIdEnd: null,
        caseTagIds: [],
        labLocationIds: [],
        prefixIds: [],
        prefixTagIds: [],
        bornOn: null,
        ageMinYears: null,
        ageMaxYears: null,
        genderIds: [],
        ssn: null,
        mrn: null,
        accountNum: null,
        orderNum: null,
        proxyUserIds: [],
        prediagnosis: null,
        diagnosis: null,
        interfaceDiagnoses: [],
        cptCodeIds: [],
        holdCodeIds: [],
        protocolIds: [],
        accessioners: [], //email
        grossers: [], //id
        resulters: [], //email
        modifiers: [], //email
        pathologistIds: [],
        providerIds: [],
        icdCodeIds: [],
        resultsMacroIds: [],
        macroDescriptionStartsWith: "",
        modifiedOnOrAfter: null,
        modifiedOnOrBefore: null,
        grossedOnOrAfter: null,
        grossedOnOrBefore: null,
        resultedOnOrAfter: null,
        resultedOnOrBefore: null,
        firstSignedOnOrBefore: null,
        firstSignedOnOrAfter: null,
        receivedOnOrAfter: null,
        receivedOnOrBefore: null,
        accessionedOnOrAfter: null,
        accessionedOnOrBefore: null,
        collectedOnOrAfter: null,
        collectedOnOrBefore: null,
        caseStageId: 1,
        billingTypes: [],
        billingCycles: [],
        exportFields: Array.from({ length: 47 }, (_, i) => i + 1).join(",")
      },
      providers: {
        names: [],
        locations: [],
        cities: [],
        states: [],
        zips: []
      },
      users: {
        ids: [],
        emails: []
      },
      nameSearch: ["firstName", "lastName"],
      prefixTagOptions: [],
      userSearches: [],
      searchName: "",
      saveModalOpen: false,
      buttonAttr: {
        class: "split-button"
      },
      dropDownOptions: {
        width: "17rem"
      },
      loadedSearch: null,
      columnsToExportToSave: null,
      showExpired: false,
      tooltipLookups: {
        prefixes: [],
        prefixTags: [],
        pathologists: [],
        resultsMacros: [],
        cptCodes: [],
        icdCodes: [],
        holdCodes: [],
        interfaceDiagnoses: [],
        users: []
      },
      isExportsPopupOpen: false,
      exportToDownload: null,
      isDownloading: false,
      caseNumberStart: "",
      caseNumberEnd: "",
      isFromCaseValid: false,
      isToCaseValid: false,
      exportFieldsEnum: {
        CaseId: 1,
        CaseNumber: 2,
        PatientName: 3,
        PatientDateOfBirth: 4,
        PatientAge: 5,
        PatientGender: 6,
        PatientMRN: 7,
        PatientSSN: 8,
        ProviderName: 9,
        CollectedOn: 10,
        ReceivedOn: 11,
        ReportedOn: 12,
        OrderNumber: 13,
        AccountNumber: 14,
        PathologistName: 15,
        SpecimenOrder: 16,
        SpecimenId: 17,
        CaseStatus: 18,
        Protocol: 19,
        Site: 20,
        Diagnosis: 21,
        Microscopic: 22,
        CaseNote: 23,
        Gross: 24,
        Clinical: 25,
        CaseTags: 26,
        LabLocation: 27,
        ProviderLocation: 28,
        ProviderCity: 29,
        ProviderState: 30,
        ProviderZipCode: 31,
        SignedOn: 32,
        AccessionedOn: 33,
        Prefix: 34,
        PrefixTags: 35,
        CptCodes: 36,
        HoldCodes: 37,
        AccessionedBy: 38,
        GrossedBy: 39,
        ResultedBy: 40,
        ModifiedBy: 41,
        IcdCodes: 42,
        ResultMacros: 43,
        SpecimenNote: 44,
        GrossedOn: 45,
        ResultedOn: 46,
        InterfaceDiagnosis: 47,
        NumberOfBlocks: 48,
        NumberOfSlides: 49,
        PatientPhoneNumber: 50,
        PatientInsurance: 51,
        MacroDescriptions: 52,
        billingTypes: 53,
        billingCycles: 54,
        caseTagText: 55
      },
      caseStageOptions: [
        { id: 1, displayName: "All Cases" },
        { id: 2, displayName: "Ready to Result" },
        { id: 3, displayName: "Not Signed" },
        { id: 4, displayName: "Signed" }
      ],
      billingCycleOptions: []
    };
  },
  mounted() {
    if (!this.states?.length) {
      this.$store.dispatch("dropdowns/getStates");
    }
    this.searchFields = {
      ...this.defaultFields
    };
    ProvidersApi.getProviders(this.currentLab).then(res => {
      this.providers.names = sortBy(
        [
          ...new Set(
            res.data.map(e => ({ displayName: e.lastName + ", " + e.firstName, id: e.id }))
          )
        ],
        e => e.displayName.toLowerCase()
      );
    });
    ProvidersApi.getLocations().then(res => {
      this.providers.locations = sortBy(
        res.data.map(loc => {
          return {
            id: loc.id,
            displayName: loc.name,
            providerIds: loc.providers.map(e => e.id)
          };
        }),
        e => e.displayName.toLowerCase()
      );
      this.providers.cities = sortBy([...new Set(res.data.map(e => e.city))], e =>
        e.toLowerCase()
      ).map((e, idx) => ({ displayName: e, id: idx }));
      this.providers.zips = [...new Set(res.data.map(e => e.zipCode))]
        .sort()
        .map((e, idx) => ({ displayName: e, id: idx }));
    });
    DropdownApi.getPrefixTags().then(res => {
      this.prefixTagOptions = sortBy(res, "displayName");
    });
    if (!this.interfaceDiagnosisOptions.length) {
      this.$store.dispatch("dropdowns/getInterfaceDiagnoses");
    }
    DropdownApi.getBillingCycles().then(res => {
      this.billingCycleOptions = res || [];
    });
    this.$store.dispatch("dropdowns/getBillingTypes");
    this.loadSearches();
    eventBus.$on(EDIT_SAVED_SEARCH_COLUMNS, data => {
      const { columns, id } = data;
      if (id) {
        this.loadSearches();
        this.updateLoadedSearch(id);
      } else {
        this.saveModalOpen = true;
        this.columnsToExportToSave = columns;
      }
    });
  },
  beforeDestroy() {
    eventBus.$off(EDIT_SAVED_SEARCH_COLUMNS);
  },
  validations() {
    return {
      caseNumberEnd: {
        required,
        validCase: async value => (value ? this.isToCaseValid : true),
        greaterThanFrom: value => {
          value = value.replace(/[a-z]/gi, "");
          const matcher = /(\d{2})(\d{2})-(\d{7})/im;
          if (matcher.test(value) && matcher.test(this.caseNumberStart)) {
            const [fromYear, fromNumberSequence] = this.caseNumberStart
              .replace(/[a-z]/gi, "")
              .split("-");
            const [toYear, toNumberSequence] = value.split("-");
            return (
              parseInt(toYear) >= parseInt(fromYear) &&
              parseInt(toNumberSequence) >= parseInt(fromNumberSequence)
            );
          }
          return true;
        },
        hasPrefix: value => (value && !this.usePathologyNumbering ? this.hasPrefix(value) : true),
        inSamePrefixGroup: value =>
          value && !this.usePathologyNumbering ? this.inSamePrefixGroup : true
      },
      caseNumberStart: {
        required,
        validCase: async value => (value ? this.isFromCaseValid : true),
        hasPrefix: value => (value && !this.usePathologyNumbering ? this.hasPrefix(value) : true)
      }
    };
  },
  methods: {
    handleCreateSearch() {
      if (!this.checkFields(this.searchFields)) {
        window.alert("Please enter a search criteria.");
        return;
      }
      if (!this.hasDatesSelected && !this.hasKeyField) {
        const keyFieldNames = [
          "Patient Name",
          "Accession # Range",
          "SSN",
          "MRN",
          "Account #",
          "Order #"
        ];
        window.alert(
          `Please select at least one date range or one of the below fields to search.<br><br>${keyFieldNames.join(
            "<br>"
          )}`,
          "warning"
        );
        return;
      }
      const invalidDateRanges = this.checkDateRanges();
      if (invalidDateRanges.length) {
        const isPlural = invalidDateRanges.length > 1;
        window.alert(
          `The selected date range${isPlural ? "s" : ""} for ${invalidDateRanges
            .map(e => capitalize(e))
            .join(", ")} ${isPlural ? "are" : "is"} greater than the range allowed by the lab (${
            this.labSettings.MaxSearchDateRange || "365"
          } days). Please check your input.`
        );
        return;
      }
      this.isLoading = true;
      eventBus.$once(RENDER_FREE_TEXT_SEARCH, () => {
        this.isLoading = false;
      });
      eventBus.$emit(SET_FREE_TEXT_SEARCH_FILTERS, this.searchFields);
      this.$emit("submitSearch");
    },
    checkFields(obj) {
      const keysWithValue = [];
      for (const key in obj) {
        if (this.hasValue(key) && !["readyToRead", "isUnsigned"].includes(key)) {
          keysWithValue.push(key);
        }
      }
      return keysWithValue.length > 0;
    },
    clearFilters() {
      this.searchFields = { ...this.defaultFields };
      this.loadedSearch = null;
      eventBus.$emit(CLEAR_FREE_TEXT_FILTERS);
      eventBus.$emit(LOAD_SAVED_SEARCH, null);
    },
    handleAccessionedRange(event) {
      this.searchFields.accessionedOnOrAfter = event[0];
      this.searchFields.accessionedOnOrBefore = event[1];
    },
    handleReceivedRange(event) {
      this.searchFields.receivedOnOrAfter = event[0];
      this.searchFields.receivedOnOrBefore = event[1];
    },
    handleCollectedRange(event) {
      this.searchFields.collectedOnOrAfter = event[0];
      this.searchFields.collectedOnOrBefore = event[1];
    },
    handleSignedDateRange(event) {
      this.searchFields.firstSignedOnOrAfter = event[0];
      this.searchFields.firstSignedOnOrBefore = event[1];
    },
    handleModifiedOnRange(event) {
      this.searchFields.modifiedOnOrAfter = event[0];
      this.searchFields.modifiedOnOrBefore = event[1];
    },
    getProviders() {
      ProvidersApi.getProviders(this.currentLab).then(res => {
        return res;
      });
    },
    nameDisplay: ({ firstName, lastName }) => lastName + ", " + firstName,
    openSaveSearch(event) {
      event.preventDefault();
      if (event.target.tagName === "BUTTON") {
        if (this.loadedSearch) {
          this.searchName = this.loadedSearch.name;
        }
        this.saveModalOpen = true;
      }
    },
    async loadSearches() {
      const searches = await UsersApi.getUserSearches();
      this.userSearches = sortBy([...searches], "name");
      // const searchStates = this.userSearches.map(e => JSON.parse(e.state));
      // function getUniqueEntries(fieldNameArray) {
      //   const returnArray = [];
      //   for (const fieldName of fieldNameArray) {
      //     for (const searchData of searchStates) {
      //       if (searchData.fields[fieldName]?.length) {
      //         for (const item of searchData.fields[fieldName]) {
      //           if (!returnArray.includes(item)) {
      //             returnArray.push(item);
      //           }
      //         }
      //       }
      //     }
      //   }
      //   return returnArray;
      // }
      // function createFilter(array, valueExpr = "id") {
      //   return array.length > 1
      //     ? array.reduce((acc, curr) => {
      //         if (Array.isArray(acc)) {
      //           return [...acc, "or", [valueExpr, "=", curr]];
      //         }
      //         return [[valueExpr, "=", acc], "or", [valueExpr, "=", curr]];
      //       })
      //     : [valueExpr, "=", array[0]];
      // }
      // const prefixesInSearch = getUniqueEntries(["prefixIds"]);
      // if (prefixesInSearch.length) {
      //   PrefixApi.searchStore
      //     .load({
      //       filter: createFilter(prefixesInSearch)
      //     })
      //     .then(res => {
      //       this.tooltipLookups.prefixes = res;
      //     });
      // }
      // const pathologistsInSearch = getUniqueEntries(["pathologistIds", "proxyUserIds"]);
      // if (pathologistsInSearch.length) {
      //   DropdownApi.searchPathologists
      //     .load({ filter: createFilter(pathologistsInSearch) })
      //     .then(res => {
      //       this.tooltipLookups.pathologists = res;
      //     });
      // }
      // const resultsMacrosInSearch = getUniqueEntries(["resultsMacroIds"]);
      // if (resultsMacrosInSearch.length) {
      //   MacrosApi.searchStore
      //     .load({
      //       filter: [
      //         ["macroType", MacroTypeEnum.Results],
      //         "and",
      //         createFilter(resultsMacrosInSearch, "macroId")
      //       ]
      //     })
      //     .then(res => {
      //       this.tooltipLookups.resultsMacros = res;
      //     });
      // }
      // const cptCodesInSearch = getUniqueEntries(["cptCodeIds"]);
      // if (cptCodesInSearch.length) {
      //   DropdownApi.searchCptCodes.load({ filter: createFilter(cptCodesInSearch) }).then(res => {
      //     this.tooltipLookups.cptCodes = res;
      //   });
      // }
      // const icdCodesInSearch = getUniqueEntries(["icdCodeIds"]);
      // if (icdCodesInSearch.length) {
      //   DropdownApi.searchIcdCodes.load({ filter: createFilter(icdCodesInSearch) }).then(res => {
      //     this.tooltipLookups.icdCodes = res;
      //   });
      // }
      // const holdCodesInSearch = getUniqueEntries(["holdCodeIds"]);
      // if (cptCodesInSearch.length) {
      //   DropdownApi.searchHoldCodes.load({ filter: createFilter(holdCodesInSearch) }).then(res => {
      //     this.tooltipLookups.holdCodes = res;
      //   });
      // }
      // UsersApi.getAllUsers().then(res => {
      //   this.tooltipLookups.users = res.data;
      // });
    },
    closeSaveSearchModal() {
      this.saveModalOpen = false;
      this.searchName = "";
      this.columnsToExportToSave = null;
    },
    async saveSearch() {
      if (!this.searchName) {
        window.alert("Please enter a name.");
        return;
      }
      if (this.nameInUse) {
        const confirm = await window.confirm(
          `This name is already in use. Overwrite existing search named "${this.searchName}"?`
        );
        if (!confirm) {
          return;
        }
        const savedSearchToUpdate = this.userSearches.find(
          e => e.name.toLowerCase() === this.searchName.toLowerCase()
        );
        const savedState = savedSearchToUpdate.state;
        const currentState = JSON.stringify({
          fields: this.searchFields,
          gridState: this.searchGridState,
          columnsToExport: savedSearchToUpdate.columnsToExport
        });
        if (savedState === currentState) {
          window.alert(`No changes have been made to ${this.loadedSearch.name}.`);
          return;
        }
        try {
          await UsersApi.updateSavedSearch({
            ...savedSearchToUpdate,
            state: JSON.stringify({
              fields: this.searchFields,
              gridState: this.searchGridState,
              columnsToExport: this.loadedSearch.columnsToExport
            })
          }).then(res => eventBus.$emit(SAVE_SEARCH, res));
          window.notify(`Updated ${this.loadedSearch.name}.`);
          this.loadSearches();
          this.closeSaveSearchModal();
        } catch (error) {
          window.notify("An error ocurred.", "error");
        }
      } else {
        try {
          await UsersApi.createSavedSearch({
            name: this.searchName,
            state: JSON.stringify({
              fields: this.searchFields,
              gridState: this.searchGridState,
              columnsToExport:
                this.columnsToExportToSave || this.loadedSearch?.columnsToExport || null
            })
          }).then(res => {
            this.loadSearch({ itemData: res });
            eventBus.$emit(SAVE_SEARCH, res);
          });
          await this.loadSearches();
          this.closeSaveSearchModal();
        } catch (error) {
          window.notify("An error occured.", "error");
        }
      }
    },
    loadSearch({ itemData }) {
      try {
        const state = JSON.parse(itemData.state);
        this.searchFields = state.fields;
        this.loadedSearch = {
          id: itemData.id,
          name: itemData.name,
          fields: state.fields,
          gridState: state.gridState,
          columnsToExport:
            state?.columnsToExport ||
            this.searchGridState.columns.map(e => {
              return {
                name: e.name,
                visible: [
                  "diagnosis",
                  "microscopic",
                  "notes",
                  "gross",
                  "caseNote",
                  "clinical"
                ].includes(e.name)
                  ? true
                  : e.visible,
                displayName: e.name
              };
            })
        };
        this.columnsToExportToSave = this.loadedSearch.columnsToExport;
        const payload = {
          name: "freeTextSearch",
          gridState: state.gridState,
          columns: state.gridState.columns
        };
        this.$store.dispatch("grids/setGridState", payload);
        eventBus.$emit(LOAD_SAVED_SEARCH, this.loadedSearch);
      } catch (error) {
        window.notify("An error occured.", "error");
      }
    },
    async deleteSavedSearch() {
      const confirm = await window.confirm(
        `Are you sure you want to permanently delete ${this.loadedSearch.name}?`
      );
      if (!confirm) {
        return;
      }
      try {
        await UsersApi.deleteUserSearch(this.loadedSearch.id);
        window.notify(`Deleted ${this.loadedSearch.name}`);
        this.loadSearches();
        this.loadedSearch = null;
        this.clearFilters();
      } catch (error) {
        window.notify("An error ocurred.", "error");
      }
    },
    setGridState(gridState) {
      const payload = {
        name: "freeTextSearch",
        gridState,
        columns: gridState.columns
      };
      this.$store.dispatch("grids/setGridState", payload);
    },
    updateLoadedSearch(id) {
      const searchToLoad = this.userSearches.find(e => e.id === id);
      this.loadSearch({ itemData: searchToLoad });
    },
    providerFullName({ lastName, firstName }) {
      return lastName + ", " + firstName;
    },
    handleGrossedRange(event) {
      this.searchFields.grossedOnOrAfter = event[0];
      this.searchFields.grossedOnOrBefore = event[1];
    },
    handleResultedOnRange(event) {
      this.searchFields.resultedOnOrAfter = event[0];
      this.searchFields.resultedOnOrBefore = event[1];
    },
    hasValue(property) {
      const value = this.searchFields[property];
      if (value !== null && value !== undefined && value !== "") {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return true;
      }
      return false;
    },
    userDisplayName(user) {
      return user.lastName + ", " + user.firstName;
    },
    async handleExport() {
      if (!this.searchFields.exportFields.length) {
        window.alert("Please select at least one column to export.");
        return;
      }
      if (!this.hasDatesSelected && !this.hasKeyField) {
        const keyFieldNames = [
          "Patient Name",
          "Accession # Range",
          "SSN",
          "MRN",
          "Account #",
          "Order #"
        ];
        window.alert(
          `Please select at least one date range or one of the below fields to search.<br><br>${keyFieldNames.join(
            "<br>"
          )}`,
          "warning"
        );
        return;
      }
      try {
        await CasesApi.exportSearch(this.searchFields);
        const { userName, phoneNumber, sendMfaCodeByEmail, sendMfaCodeBySms } = this.currentUser;
        const alertMessage = `Your export has been requested. You will receive
        ${sendMfaCodeByEmail ? "an email at " + userName : ""}
        ${sendMfaCodeByEmail && sendMfaCodeBySms ? " and " : ""}
        ${sendMfaCodeBySms ? "a text message at " + formatPhoneNumber(phoneNumber) : ""}
        when it is ready to download below.`;
        window.alert(alertMessage);
      } catch (error) {
        handleErrors(error);
      }
    },
    getItemTooltip(data) {
      const { fields } = JSON.parse(data.state);
      const dataForFields = [];
      // So that it displays a user-friendly name
      const fieldNameEnum = {
        prefixIds: "Prefixes",
        prefixTagIds: "Prefix Tags",
        specimenSiteContains: "Site",
        resultsMacroIds: "Results Macros",
        providerIds: "Provider Names",
        providerLocationIds: "Provider Locations",
        providerLocationCities: "Provider Cities",
        providerLocationStates: "Provider States",
        providerLocationZipCodes: "Provider ZIPs",
        pathologistIds: "Pathologists",
        accessioners: "Accessoned By", //email
        grossers: "Grossed By", //id
        resulters: "Resulted By", //email
        modifiers: "Modified After Reported By", //email
        cptCodeIds: "CPT Codes",
        holdCodeIds: "Hold Codes",
        isUnsigned: "Show Signed Cases",
        readyToRead: "Show Ready to Result Cases",
        patientFieldContains: "Patient Name",
        labLocationIds: "Lab Locations",
        bornOn: "Patient DOB",
        genderIds: "Patient Sexes",
        ssn: "Patient SSN",
        mrn: "Patient MRN",
        accountNum: "Account Number",
        orderNum: "Order Number",
        proxyUserIds: "Proxies",
        prediagnosis: "Pre-Diagnosis Text",
        diagnosis: "Diagnosis Text",
        icdCodeIds: "ICD Codes",
        interfaceDiagnoses: "Interface Diagnosis"
      };
      for (const fieldName of Object.keys(fields)) {
        // Date ranges
        if (fieldName.includes("OnOrBefore") && fields[fieldName]) {
          const fieldRangeName = fieldName.replace("OnOrBefore", "");
          dataForFields.push(
            `${fieldRangeName}: ${moment(fields[fieldRangeName + "OnOrAfter"]).format(
              "M/D/yyyy"
            )} - ${moment(fields[fieldRangeName + "OnOrBefore"]).format("M/D/yyyy")}`
          );
          // Arrays
        } else if (Array.isArray(fields[fieldName]) && fields[fieldName].length) {
          let arrayItems = fields[fieldName];
          let sourceArray = [];
          let valueExpr = "id";
          let displayExpr = "displayName";
          switch (fieldName) {
            case "providerIds":
              sourceArray = this.providers.names;
              break;
            case "providerLocationStates":
              sourceArray = this.states;
              break;
            case "genderIds":
              sourceArray = this.sexes;
              break;
            case "prefixIds":
              sourceArray = this.tooltipLookups.prefixes;
              displayExpr = "code";
              break;
            case "prefixTagIds":
              sourceArray = this.prefixTagOptions;
              break;
            case "pathologistIds":
            case "proxyUserIds":
              sourceArray = this.tooltipLookups.pathologists;
              break;
            case "resultsMacroIds":
              sourceArray = this.tooltipLookups.resultsMacros;
              valueExpr = "macroId";
              displayExpr = "macroName";
              break;
            case "cptCodes":
              sourceArray = this.tooltipLookups.cptCodes;
              break;
            case "icdCodes":
              sourceArray = this.tooltipLookups.icdCodes;
              displayExpr = "displayCode";
              break;
            case "holdCodes":
              sourceArray = this.tooltipLookups.holdCodes;
              break;
            case "accessioners":
            case "resulters":
            case "modifiers":
              sourceArray = this.tooltipLookups.users;
              valueExpr = "guid";
              break;
            case "grossers":
              sourceArray = this.tooltipLookups.users;
              valueExpr = "username";
              break;
            default:
              arrayItems = fields[fieldName];
          }
          if (sourceArray.length) {
            arrayItems = this.getDataForIds(arrayItems, sourceArray, valueExpr, displayExpr);
          } else {
            arrayItems = fields[fieldName];
          }
          dataForFields.push(`${fieldNameEnum[fieldName]}: ${arrayItems.join(", ")}`);
          // Everything else
        } else if (
          !Array.isArray(fields[fieldName]) &&
          fields[fieldName] &&
          !fieldName.includes("OnOrAfter")
        ) {
          dataForFields.push(`${fieldNameEnum[fieldName] || fieldName}: ${fields[fieldName]}`);
        }
      }
      return dataForFields.join(";<br>");
    },
    hasPermission(field) {
      const { permissions } = this;
      function checkPermission(suffix) {
        return permissions["CaseSearchBy" + suffix];
      }
      if (field === "PreDiagnosis") {
        return checkPermission("SpecimenGross") || checkPermission("SpecimenClinical");
      }
      if (field === "Diagnosis") {
        return (
          checkPermission("SpecimenDiagnosis") ||
          checkPermission("SpecimenMicroscopic") ||
          checkPermission("SpecimenNote") ||
          checkPermission("CaseNotes")
        );
      }
      return checkPermission(field);
    },
    getDataForIds(dataArray, lookupArray, valueExpr = "id", displayExpr = "displayName") {
      dataArray = dataArray.map(e => {
        const item = lookupArray.find(i => i[valueExpr] === e);
        if (item) {
          return item[displayExpr];
        }
        return e;
      });
      return dataArray;
    },
    toggleExportsPopup() {
      this.isExportsPopupOpen = !this.isExportsPopupOpen;
    },
    async handleDownload() {
      this.isDownloading = true;
      try {
        const exportData = await ExportsApi.searchExports.load({
          filter: ["id", "=", this.exportToDownload]
        });
        const file = await ExportsApi.downloadExport(this.exportToDownload);
        downloadBlob(
          file,
          `CASE_EXPORT_${moment(utcToLocal(exportData.requestedOn)).format("M-D-yyyy_h-mma")}`,
          "xlsx"
        );
      } catch (error) {
        if (error?.response?.data && error.response.data.includes("does not exist")) {
          window.alert("Export not currently available. Please try again later.");
        } else {
          handleErrors(error);
        }
      } finally {
        this.isDownloading = false;
      }
    },
    exportDisplayExpr(data) {
      if (data?.requestedOn) {
        return moment(utcToLocal(data.requestedOn)).format("M/D/yyyy h:mm a");
      }
      return "";
    },
    startCase,
    async formatAccessionNumber(event) {
      const { value } = event.target;
      const targetField = event.target.name;
      let targetId = "";
      if (targetField === "caseNumberStart") {
        targetId = "caseIdStart";
      }
      if (targetField === "caseNumberEnd") {
        targetId = "caseIdEnd";
      }
      this.searchFields[targetId] = null;
      let prefix = "";
      if (value) {
        if (this.hasPrefix(value)) {
          const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
          prefix = regex.groups.prefix.toUpperCase();
        }
        let targetNumber = this.caseNumberWithoutPrefix(value);
        let number = targetNumber;
        if (targetNumber.replace("-", "").length < 11) {
          number = calculateAccessionNumberingWithoutPrevCentury(targetNumber);
        }
        if (number?.length === 12) {
          const fullNewNumber = scanCaseBarcode(prefix + number).caseNumber || prefix + number;
          this[targetField] = fullNewNumber;
          const matchingCases = await CasesApi.searchStore.load({
            filter: ["accessionNumber", prefix ? "=" : "contains", fullNewNumber]
          });
          if (matchingCases.length === 1) {
            const caseDetails = matchingCases[0];
            this.searchFields[targetId] = caseDetails.caseId;
            this[targetField] = caseDetails.accessionNumber;
            if (targetField === "caseNumberStart" && !this.caseNumberEnd) {
              this.searchFields.caseIdEnd = caseDetails.caseId;
              this.caseNumberEnd = caseDetails.accessionNumber;
              this.$nextTick(() => {
                this.$refs.caseNumberEnd.focus();
                this.$refs.caseNumberEnd.selectAll();
              });
            }
            if ((targetField === "caseNumberEnd") & !this.caseNumberStart) {
              this.searchFields.caseIdStart = caseDetails.caseId;
              this.caseNumberStart = caseDetails.accessionNumber;
              this.$nextTick(() => {
                this.$refs.caseNumberStart.focus();
                this.$refs.caseNumberStart.selectAll();
              });
            }
          } else {
            await window.alert("No matching case found.");
            this.searchFields[targetField] = "";
            this.$nextTick(() => {
              this.$refs[targetField].focus();
              this.$refs[targetField].selectAll();
            });
          }
        }
      }
    },
    hasPrefix(value) {
      if (value) {
        const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
        return Boolean(regex?.groups?.prefix);
      }
      return false;
    },
    caseNumberWithoutPrefix(caseNumber) {
      if (!caseNumber) {
        return "";
      }
      const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(caseNumber);
      return caseNumber.replace(regex?.groups?.prefix, "");
    },
    async getCase(caseId) {
      this.isSearching = true;
      const caseDetails = await CasesApi.getCaseById(caseId);
      this.isSearching = false;
      return caseDetails;
    },
    toggleField(field) {
      const enumValue = this.exportFieldsEnum[field].toString();
      let fieldsToExport = this.searchFields.exportFields?.length
        ? this.searchFields.exportFields.split(",")
        : [];
      if (fieldsToExport.includes(enumValue)) {
        fieldsToExport = fieldsToExport.filter(e => e !== enumValue);
      } else {
        fieldsToExport.push(enumValue);
      }
      this.searchFields.exportFields = fieldsToExport.join(",");
    },
    selectAllColumns() {
      this.searchFields.exportFields = Array.from(
        { length: Object.keys(this.exportFieldsEnum).length },
        (_, i) => i + 1
      ).join(",");
    },
    clearAllColumns() {
      this.searchFields.exportFields = "";
    },
    createArrayStore(field) {
      return new DataSource({
        store: new ArrayStore({ data: this[field] })
      });
    },
    checkDateRanges() {
      let invalidRanges = [];
      for (const field of [
        "collected",
        "accessioned",
        "received",
        "grossed",
        "resulted",
        "signed",
        "modified"
      ]) {
        const ref = this.$refs[field + "Range"];
        if (ref && ref.isInvalidDateRange) {
          invalidRanges.push(field);
        }
      }
      return invalidRanges;
    },
    holdCodeDisplayExpr(holdCode) {
      if (this.labSettings.SearchByHoldCode && holdCode?.holdCode) {
        return `${holdCode.holdCode} - ${holdCode.displayName}`;
      }
      return holdCode.displayName;
    }
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      currentUser: state => state.currentUser,
      casesSearchHeader: state => state.applicationSettings.casesSearchHeader,
      labSettings: state => state.labSettings,
      ApplyPathologistSearchFilter: state => state.labSettings.ApplyPathologistSearchFilter,
      states: state => state.dropdowns.states,
      searchGridState: state => state.grids.freeTextSearch,
      availableLabLocations: state => state.availableLabLocations,
      sexes: state => state.dropdowns.sexes,
      billingTypeOptions: state => state.dropdowns.billingTypes,
      interfaceDiagnosisOptions: state => state.dropdowns.interfaceDiagnoses
    }),
    ...mapGetters(["permissions"]),
    hasDatesSelected() {
      const {
        firstSignedOnOrAfter,
        firstSignedOnOrBefore,
        receivedOnOrAfter,
        receivedOnOrBefore,
        accessionedOnOrAfter,
        accessionedOnOrBefore,
        collectedOnOrAfter,
        collectedOnOrBefore,
        modifiedOnOrAfter,
        modifiedOnOrBefore,
        grossedOnOrAfter,
        grossedOnOrBefore,
        resultedOnOrAfter,
        resultedOnOrBefore
      } = this.searchFields;
      if (
        (collectedOnOrAfter && collectedOnOrBefore) ||
        (accessionedOnOrAfter && accessionedOnOrBefore) ||
        (receivedOnOrAfter && receivedOnOrBefore) ||
        (firstSignedOnOrAfter && firstSignedOnOrBefore) ||
        (modifiedOnOrAfter && modifiedOnOrBefore) ||
        (grossedOnOrAfter && grossedOnOrBefore) ||
        (resultedOnOrAfter && resultedOnOrBefore)
      ) {
        return true;
      }
      return false;
    },
    hasKeyField() {
      if (this.searchFields.caseIdStart && this.searchFields.caseIdEnd) {
        return true;
      }
      let hasField = false;
      for (const field of ["patientFieldContains", "ssn", "mrn", "accountNum", "orderNum"]) {
        if (this.searchFields[field]) {
          hasField = true;
          break;
        }
      }
      return hasField;
    },
    providersDataSource() {
      return new DataSource({
        store: ProvidersApi.searchProviders,
        sort: "lastName"
      });
    },
    pathologistsDataSource() {
      return new DataSource({
        store: DropdownApi.searchPathologists,
        sort: "displayName"
      });
    },
    userDataSource() {
      return new DataSource({
        store: UsersApi.labUsersStore,
        sort: "lastName",
        filter: this.showExpired
          ? ["userTypeId", "=", UserTypesEnum.LabUser]
          : [["userTypeId", "=", UserTypesEnum.LabUser], "and", ["isEnabled", "=", true]]
      });
    },
    prefixDataSource() {
      return new DataSource({
        store: PrefixApi.searchStore,
        sort: "code",
        filter: this.showExpired ? null : dateRangeFilter()
      });
    },
    resultsMacrosDataSource() {
      return new DataSource({
        store: MacrosApi.searchStore,
        filter: this.showExpired
          ? ["macroType", MacroTypeEnum.Results]
          : [
              dateRangeFilter("effectiveOn", "expiresOn"),
              "and",
              ["macroType", MacroTypeEnum.Results]
            ],
        sort: "macroName"
      });
    },
    cptCodesDataSource() {
      return new DataSource({
        store: BillingApi.searchStore,
        sort: "code",
        filter: this.showExpired ? null : dateRangeFilter()
      });
    },
    icdDataSource() {
      return new DataSource({
        store: DropdownApi.searchIcdCodes
        // filter: this.showExpired ? null : dateRangeFilter("effectiveOn", "expiryOn")
      });
    },
    caseTagDataSource() {
      const tagFilter = [`settingType`, "=", "T"];
      return new DataSource({
        store: DropdownApi.searchTags,
        filter: this.showExpired
          ? tagFilter
          : [tagFilter, "and", dateRangeFilter("effectiveOn", "expiryOn", true)],
        sort: "displayName"
      });
    },
    holdCodeDataSource() {
      return new DataSource({
        store: DropdownApi.searchHoldCodes,
        sort: this.labSettings.SearchByHoldCode ? "holdCode" : "displayName",
        filter: this.showExpired ? null : dateRangeFilter("effectiveOn", "expiryOn", true)
      });
    },
    interfaceDiagnosesDataSource() {
      return new DataSource({
        store: new ArrayStore({ data: this.interfaceDiagnosisOptions })
      });
    },
    protocolDataSource() {
      const protocolFilter = ["macroType", MacroTypeEnum.Protocol];
      return new DataSource({
        store: MacrosApi.searchStore,
        filter: this.showExpired
          ? protocolFilter
          : [protocolFilter, "and", dateRangeFilter("effectiveOn", "expiresOn")],
        sort: ["macroName"]
      });
    },
    nameInUse() {
      if (
        this.userSearches.map(e => e.name.toLowerCase()).includes(this.searchName.toLowerCase())
      ) {
        return true;
      }
      return false;
    },
    providerNamesList() {
      if (this.searchFields?.providerLocationIds?.length) {
        let providersAtSelectedLocations = [];
        for (const locationId of this.searchFields.providerLocationIds) {
          const locationData = this.providers.locations.find(e => e.id === locationId);
          for (const providerId of locationData.providerIds) {
            if (!providersAtSelectedLocations.find(e => e.id === providerId)) {
              providersAtSelectedLocations.push(
                this.providers.names.find(e => e.id === providerId)
              );
            }
          }
        }
        return sortBy(providersAtSelectedLocations, e => e.displayName.toLowerCase());
      }
      return this.providers.names;
    },
    locationsNamesList() {
      if (this.searchFields?.providerIds?.length) {
        let locationsWithSelectedProviders = [];
        for (const providerId of this.searchFields.providerIds) {
          const locationsWithProvider = this.providers.locations.filter(e =>
            e.providerIds.includes(providerId)
          );
          for (const location of locationsWithProvider) {
            if (!locationsWithSelectedProviders.find(e => e.id === location.id)) {
              locationsWithSelectedProviders.push(location);
            }
          }
        }
        return sortBy(locationsWithSelectedProviders, e => e.displayName.toLowerCase());
      }
      return this.providers.locations;
    },
    exportsDataSource() {
      return new DataSource({
        store: ExportsApi.searchExports,
        filter: ["requestedBy", "=", this.currentUser.userName] && ["exportType", "=", "Case"],
        sort: [{ selector: "requestedOn", desc: true }]
      });
    }
  },
  watch: {
    "searchFields.ssn": function (value) {
      if (value?.length) {
        this.searchFields.ssn = formatSSN(value);
      }
    },
    "searchFields.ageMinYears": {
      immediate: true,
      handler(nv) {
        this.searchFields.ageMinYears = nv ? parseInt(nv) : null;
      }
    },
    "searchFields.ageMaxYears": {
      immediate: true,
      handler(nv) {
        this.searchFields.ageMaxYears = nv ? parseInt(nv) : null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.searchHeader {
  padding: 0.25rem;
}
.field-label {
  text-align: right;
  padding-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
}
.input-field {
  min-height: 40px;
}
::v-deep .dropdown-button {
  .dx-icon::before,
  .dx-dropdowneditor-icon {
    color: white !important;
  }
}
</style>
