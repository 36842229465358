<template>
  <form
    @submit.prevent="addProvider"
    class="d-flex flex-column align-items-start justify-content-center"
  >
    <div class="d-flex justify-content-between w-75 align-items-center">
      <label
        :id="$attrs.id && $attrs.id + 'label'"
        v-if="!noLabel"
        :for="$attrs.name || $attrs.id"
        class="text-left align-self-start"
        v-html="displayLabel(accessKey, label)"
      >
      </label>
    </div>
    <Multiselect
      ref="multiSelect"
      :hide-selected="false"
      :track-by="trackBy"
      v-model="selected"
      :options="items"
      openDirection="below"
      :optionHeight="40"
      v-bind="$attrs"
      @search-change="$emit('search-change', $event)"
      :multiple="multiple"
      v-on="$listeners"
      :maxHeight="250"
      :class="{
        'is-invalid':
          (validator && validator.$model && validator.$model.length && validator.$invalid) ||
          (validator && validator.$error),
        'is-valid': validator && validator.$model && validator.$model.length && !validator.$invalid,
        focused: isFocused,
        'has-error': validator && validator.$model && validator && validator.$error
      }"
    >
      <template v-slot:noOption>
        <span> Type to begin your search. </span>
      </template>
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </Multiselect>
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
    <div v-else-if="required && !selectedAny">
      <span class="error">This field is required.</span>
    </div>
  </form>
</template>

<script>
import { validatorMsgMapBase } from "@/modules/helpers.js";
import Multiselect from "vue-multiselect";
export default {
  inheritAttrs: false,
  name: "MultiSelect",
  props: {
    value: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    label: String,
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    validator: Object,
    noLabel: Boolean,
    trackBy: String,
    multiple: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    accessKey: String
  },
  components: {
    Multiselect
  },
  data() {
    return {
      locations: []
    };
  },
  computed: {
    validatorMsgMap() {
      return validatorMsgMapBase;
    },
    selectedAny() {
      // check if any option is selected
      const length = this.selected?.length;
      if (length) {
        return length > 0;
      }
      return false;
    },
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        //Set the very first option selected as the primary
        this.$emit("input", value);
      }
    },
    isFocused() {
      if (this.$refs?.multiSelect?.$el?.contains(document.activeElement)) {
        return true;
      }
      return false;
    }
  },
  methods: {
    focus() {
      if (!this.isFocused) {
        this.$refs.multiSelect.toggle();
        return;
      }
    },
    displayLabel(key = "", name) {
      if (key) {
        const regex = new RegExp(key, "i");
        if (regex.test(name)) {
          const { index } = name.match(regex);
          return `<b>${name.slice(0, index)}<u>${name[index]}</u>${name.slice(index + 1)}</b>`;
        }
      }
      return `<b>${name || ""}</b>`;
    }
  }
};
</script>
<style lang="scss">
.custom__tag {
  display: inline-flex;
  align-items: center;
  & > * {
    font-size: 1rem;
    font-weight: 500;
  }
  border-radius: 3px;
  justify-content: space-between;
  padding: 0 0.5rem;
  margin: 0.2rem 0.1rem;
  background-color: $neutral;
  color: #35495e;
  border: 1px solid #dee2e6 !important;
}
.multi_select_remove {
  color: $white;
}
.custom__remove {
  display: flex;
  align-items: center;
  button:hover {
    opacity: 0.3;
  }
}
::v-deep multiselect__content-wrapper {
  max-height: 240px !important;
}
.custom__tag__pathologists {
  display: inline-flex;
  justify-content: space-between;
  background-color: $primary !important;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  max-width: 150px;
  font-size: 0.9rem;
}
.multiselect__option--highlight {
  background: $primary !important;
  &::after {
    display: none;
    background: $primary !important;
  }
}
.multiselect__single {
  padding: 0.3rem 0.5rem;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  &:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }
}
.multiselect__tags-wrap {
  padding: 0 0.75rem 0 0;
}
.multiselect {
  &.is-invalid {
    .multiselect__tags {
      border-color: #dc3545;
    }
  }
  &.is-valid {
    .multiselect__tags {
      border-color: #28a745;
    }
  }
}
.multiselect__tags {
  border: 1px solid #ced4da;
  border-top-color: rgb(206, 212, 218);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(206, 212, 218);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(206, 212, 218);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(206, 212, 218);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  padding: 5px;
}
.multiselect__tag {
  font-weight: 600;
  background-color: $primary;
  .multiselect__tag-icon::after {
    font-weight: 700;
    color: $white;
  }
}
</style>
