import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/";

//Pages
import CaseView from "@/views/CaseView.vue";
import Dashboard from "@/views/Dashboard.vue";
import { PermissionsEnum, UserTypesEnum } from "@/modules/enums";
import { CytologyEntities } from "@/services/cytology";

//! Authentication
const Login = () => import("@/components/forms/Auth/Login.vue");
const Pin = () => import("@/components/forms/Auth/Pin.vue");
const Password = () => import("@/components/forms/Auth/Password.vue");
const Authentication = () => import("@/components/forms/Auth/Authentication.vue");
const ForgotPassword = () => import("@/components/forms/Auth/ForgotPassword");

const AuditLog = () => import("@/views/AuditLog");
const MacroConfiguration = () => import("@/views/MacroConfiguration.vue");
const EditDemographics = () => import("@/components/forms/Accession/EditDemographics");
const SpecimenView = () => import("@/components/forms/SpecimenView.vue");
const MolecularTestView = () => import("@/components/forms/MolecularTestView.vue");
const UserAdminView = () => import("@/views/UserAdminView.vue");
const UserAdminEdit = () => import("@/views/UserAdminEdit.vue");
const Reports = () => import("@/views/Reports.vue");
const DistributionHistory = () => import("@/views/DistributionHistory");
const BatchDistribution = () => import("@/views/BatchDistribution");
const AccessionEntry = () => import("@/views/AccessionEntry.vue");
const AccessionTransactions = () => import("@/components/AccessionTransactions");
const CaseHistory = () => import("@/components/CaseHistory");
const AccessionInsurance = () => import("@/components/AccessionInsurance");
const AccessionDistribution = () => import("@/components/AccessionDistribution");
const PhysicianPopups = () => import("@/views/PhysicianPopups.vue");
//Code Maintenance
const LocationForm = () => import("@/components/forms/CodeMaintenance/Providers/Location");
const ProviderForm = () => import("@/components/forms/CodeMaintenance/Providers/Provider");
const ContactMaintenace = () => import("@/components/CodeMaintenance/Contact.vue");
const BillingTransaction = () =>
  import("@/components/CodeMaintenance/Billing/BillingTransaction.vue");
const SpecimenResults = () => import("@/components/forms/SpecimenResultView.vue");
const LabSettingsView = () => import("@/views/LabSettingsView.vue");
const RoleManagement = () => import("@/components/CodeMaintenance/RoleManagement.vue");
const ICDAdendaUpload = () => import("@/components/forms/CodeMaintenance/ICDAdendaUpload.vue");
const ICDRuleMapper = () => import("@/components/CodeMaintenance/ICDRuleMapper.vue");
const PrefixMaintenance = () => import("@/components/CodeMaintenance/PrefixMaintenance");
const InsuranceMaintenance = () =>
  import("@/components/CodeMaintenance/Insurance/InsuranceMaintenance.vue");
const OrderMaintenance = () => import("@/components/CodeMaintenance/Procedures/OrderMaintenance");
const PrinterMaintenance = () => import("@/components/CodeMaintenance/PrinterMaintenance.vue");
const CaseAuditLog = () => import("@/views/CaseAuditLog");
const CopyDictionary = () => import("@/components/CopyDictionary.vue");
const PatientHistoryMaintenance = () => import("@/components/PatientHistoryMaintenance.vue");
const ProxyMaintenance = () => import("@/components/ProxyMaintenance.vue");
const QuickSignout = () => import("@/components/QuickSignout.vue");
const MolecularTestRuns = () => import("@/components/MolecularTestRuns.vue");
const UploadReqs = () => import("@/components/UploadReqs.vue");

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Dashboard,
    props: true
  },
  {
    path: "/distribution",
    name: "Distribution History",
    component: DistributionHistory
  },
  {
    path: "/batch",
    // IP-1548 - rename to Batch Printing
    name: "Batch Printing",
    component: BatchDistribution
  },
  {
    path: "/quicksignout",
    name: "Quick Signout",
    component: QuickSignout,
    beforeEnter: navigationGuard(PermissionsEnum.CaseSignout, PermissionsEnum.CaseSignoutByProxy)
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports
  },
  {
    path: "/accession",
    name: "Accession",
    beforeEnter: navigationGuard(PermissionsEnum.CaseCreateEdit),
    component: AccessionEntry
  },
  {
    path: "/accession/",
    component: CaseView,
    children: [
      {
        path: "/accession/:caseId/",
        name: "CaseView",
        component: EditDemographics,
        beforeEnter: navigationGuard(PermissionsEnum.CaseView)
      },
      {
        path: "/accession/:caseId/specimen",
        name: "Specimen",
        component: SpecimenView,
        beforeEnter: navigationGuard(PermissionsEnum.CaseSpecimenView)
      },
      {
        path: "/accession/:caseId/tests",
        name: "MolecularTest",
        component: MolecularTestView,
        beforeEnter: navigationGuard(PermissionsEnum.CaseSpecimenView)
      },
      {
        path: "/accession/:caseId/results",
        name: "SpecimenResults",
        component: SpecimenResults,
        beforeEnter: navigationGuard(PermissionsEnum.CaseSpecimenView)
      },
      {
        path: "/accession/:caseId/transactions",
        name: "accesionTransactions",
        component: AccessionTransactions,
        beforeEnter: navigationGuard(PermissionsEnum.CaseTransactionView)
      },
      {
        path: "/accession/:caseId/history",
        name: "CaseHistory",
        component: CaseHistory,
        beforeEnter: navigationGuard(PermissionsEnum.CaseHistoryView)
      },
      {
        path: "/accession/:caseId/insurance",
        name: "accessionInsurance",
        component: AccessionInsurance,
        beforeEnter: navigationGuard(PermissionsEnum.CaseInsuranceView)
      },
      {
        path: "/accession/:caseId/distribution",
        name: "Distribution",
        component: AccessionDistribution,
        beforeEnter: navigationGuard(PermissionsEnum.CaseHistoryView)
      },
      {
        path: "/accession/:caseId/caseauditlog",
        name: "CaseActivityLog",
        component: CaseAuditLog
      }
    ]
  },

  //Code Maintenance Routes
  {
    path: "/admin/users",
    name: "UserAdmin",
    beforeEnter: navigationGuard(PermissionsEnum.UserView),
    component: UserAdminView
  },
  {
    path: "/admin/users/new",
    name: "NewUser",
    component: UserAdminEdit
  },
  {
    path: "/admin/users/edit/:userId",
    name: "EditUser",
    component: UserAdminEdit,
    props: true
  },
  {
    path: "/admin/procedures",
    name: "ProcedureMaintenace",
    component: OrderMaintenance
  },
  {
    path: "/admin/icdmaintenance",
    name: "IcdAdenda",
    component: ICDAdendaUpload,
    beforeEnter: isSuperUser()
  },
  {
    path: "/admin/auditlog",
    name: "AuditLog",
    component: AuditLog
  },
  {
    path: "/admin/popups",
    name: "Popups",
    component: PhysicianPopups
  },

  //NEED Permission
  {
    path: "/admin/icdrules",
    name: "IcdRules",
    component: ICDRuleMapper,
    beforeEnter: isSuperUser()
  },
  {
    path: "/admin/roles",
    name: "Role_Management",
    component: RoleManagement,
    beforeEnter: navigationGuard(PermissionsEnum.RoleCreateEdit)
  },
  {
    path: "/admin/macro",
    name: "MacroConfiguration",
    component: MacroConfiguration,
    beforeEnter: navigationGuard(PermissionsEnum.MacroView)
  },
  {
    path: "/admin/lab-settings",
    name: "LabSettings",
    component: LabSettingsView,
    beforeEnter: navigationGuard(PermissionsEnum.SettingView)
  },
  {
    path: "/admin/contacts",
    beforeEnter: navigationGuard(PermissionsEnum.ContactView),
    component: ContactMaintenace,
    name: "ContactMaintenance"
  },

  {
    path: "/admin/contacts/location",
    name: "LocationMaintenance",
    component: LocationForm,
    props: true
  },
  {
    path: "/admin/contacts/provider",
    name: "ProviderMaintenance",
    component: ProviderForm,
    props: true
  },
  {
    path: "/admin/contacts/location",
    name: "Location",
    component: LocationForm,
    props: true
  },
  {
    path: "/admin/contacts/location/:locationId",
    name: "LocationUpdate",
    component: LocationForm,
    props: true
  },
  {
    path: "/admin/contacts/provider/:providerId",
    name: "ProviderUpdate",
    component: ProviderForm,
    props: true
  },
  {
    path: "/admin/billing",
    name: "TransactionCodes",
    component: BillingTransaction,
    beforeEnter: navigationGuard(PermissionsEnum.TransactionCodeView)
  },
  {
    path: "/admin/prefix",
    component: PrefixMaintenance,
    name: "PrefixMaintenance"
  },
  {
    path: "/admin/insurance",
    component: InsuranceMaintenance,
    name: "InsuranceMaintenance"
  },
  {
    path: "/admin/printers",
    component: PrinterMaintenance,
    name: "PrinterMaintenance",
    beforeEnter: navigationGuard(PermissionsEnum.PrinterView)
  },
  {
    path: "/admin/history",
    component: PatientHistoryMaintenance,
    name: "PatientHistoryMaintenance",
    beforeEnter: navigationGuard(PermissionsEnum.CaseHistoryChange)
  },
  {
    path: "/admin/proxy",
    component: ProxyMaintenance,
    name: "ProxyMaintenance",
    beforeEnter: navigationGuard(PermissionsEnum.ProxyView)
  },
  {
    path: "/admin/cytology/codes",
    component: () => import("@/components/CodeMaintenance/Cytology.vue"),
    name: CytologyEntities.CytDiagnosticCodes,
    beforeEnter: navigationGuard(PermissionsEnum.CytDiagnosticCodeView),
    props: {
      type: CytologyEntities.CytDiagnosticCodes
    }
  },
  {
    path: "/admin/cytology/macros",
    component: () => import("@/components/CodeMaintenance/Cytology.vue"),
    name: CytologyEntities.CytDiagnosticMacros,
    beforeEnter: navigationGuard(PermissionsEnum.CytDiagnosticCodeView),
    props: {
      type: CytologyEntities.CytDiagnosticMacros
    }
  },
  {
    path: "/admin/cytology/questions",
    component: () => import("@/components/CodeMaintenance/Cytology.vue"),
    name: CytologyEntities.CytQuestions,
    beforeEnter: navigationGuard(PermissionsEnum.CytDiagnosticCodeView),
    props: {
      type: CytologyEntities.CytQuestions
    }
  },
  {
    path: "/admin/cytology/results",
    component: () => import("@/components/CodeMaintenance/Cytology.vue"),
    name: CytologyEntities.CytMolecResults,
    beforeEnter: navigationGuard(PermissionsEnum.CytDiagnosticCodeView),
    props: {
      type: CytologyEntities.CytMolecResults
    }
  },
  {
    path: "/admin/cytology/panels",
    component: () => import("@/components/CodeMaintenance/Cytology.vue"),
    name: CytologyEntities.CytMolecPanels,
    beforeEnter: navigationGuard(PermissionsEnum.CytDiagnosticCodeView),
    props: {
      type: CytologyEntities.CytMolecPanels
    }
  },
  {
    path: "/admin/cytology/triggers",
    component: () => import("@/components/CodeMaintenance/Cytology.vue"),
    name: CytologyEntities.CytTriggers,
    beforeEnter: navigationGuard(PermissionsEnum.CytDiagnosticCodeView),
    props: {
      type: CytologyEntities.CytTriggers
    }
  },
  {
    path: "/admin/cytology/tests",
    component: () => import("@/components/CodeMaintenance/Cytology.vue"),
    name: CytologyEntities.CytMolecTests,
    beforeEnter: navigationGuard(PermissionsEnum.CytDiagnosticCodeView),
    props: {
      type: CytologyEntities.CytMolecTests
    }
  },
  {
    path: "/copydict",
    component: CopyDictionary,
    name: "CopyDictionary",
    beforeEnter: isSuperUser()
  },
  {
    path: "/testruns",
    component: MolecularTestRuns,
    name: "Molecular Test Runs",
    beforeEnter: navigationGuard(PermissionsEnum.TestRunView)
  },
  {
    path: "/uploadreqs",
    component: UploadReqs,
    name: "Upload Reqs",
    beforeEnter: navigationGuard(PermissionsEnum.CaseImageCreateEdit)
  },
  {
    path: "/login",
    component: Authentication,
    children: [
      {
        path: "/",
        name: "Login",
        component: Login,
        props: route => ({
          redirect: route.query.redirect
        })
      },
      {
        path: "pin",
        name: "SecurityCode",
        component: Pin,
        props: route => ({
          redirect: route.query.redirect
        })
      },
      {
        path: "password",
        name: "ExpiredPassword",
        component: Password,
        props: route => ({
          redirect: route.query.redirect
        })
      }
    ]
  },
  {
    path: "/password",
    component: Authentication,
    children: [
      {
        path: "forgot",
        component: ForgotPassword,
        name: "forgotPassword"
      },
      {
        path: "reset",
        component: Password,
        props: route => ({
          email: route.query.email,
          token: route.query.token
        })
      }
    ]
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];
function navigationGuard(permission, alternatePermission) {
  return function (to, from, next) {
    const { distinctPermissions } = store.state.currentUser;
    if (
      (distinctPermissions.includes(permission) ||
        (alternatePermission && distinctPermissions.includes(alternatePermission))) &&
      next
    ) {
      return next();
    } else {
      next({
        name: "Home"
      });
    }
  };
}

function isSuperUser() {
  return function (to, from, next) {
    if (store.state.currentUser.userTypeId === UserTypesEnum.SuperUser) {
      return next();
    } else {
      next({
        name: "Home"
      });
    }
  };
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

function getToken() {
  const token = sessionStorage.getItem("token");
  if (token) {
    return true;
  }
  return false;
}

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;
  next();
};

function isAuthenticated(to, from, next) {
  if (/login|password|pin|test/i.test(to.fullPath)) {
    next();
  } else {
    const token = getToken();
    if (token) {
      if (store.state.currentUser?.userMustProvidePin) {
        next({ name: "SecurityCode", replace: true, query: { redirect: to.fullPath } });
      } else if (store.state.currentUser?.userMustChangePassword) {
        next({ name: "ExpiredPassword", replace: true, query: { redirect: to.fullPath } });
      } else {
        next();
      }
    } else {
      next({
        name: "Login",
        replace: true,
        query: { redirect: to.fullPath }
      });
    }
  }
}
router.beforeEach(waitForStorageToBeReady);
router.beforeEach(isAuthenticated);
router.afterEach(to => {
  if (window.newrelic) {
    window.newrelic.setCurrentRouteName(to.fullPath);
    window.newrelic.setPageViewName(to.fullPath);
  }
});

export default router;
